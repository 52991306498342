import { Page } from "../../../components/Shared/Page";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { useParams } from "react-router-dom";
import { useAPI } from "../../../hooks/useAPI";
import { useState, useEffect } from "react";
import { TotalsGroupCalculation } from "../../../components/Settings/TotalsGroupCalculation";
import api from "../../../services/api";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { IconDeviceFloppy } from "@tabler/icons-react";

const EditTotalsGroup = () => {
  useDocumentTitle("Totals Groups");

  const { id } = useParams();

  const [calculations, setCalculations] = useState([]);

  const {
    data: totalsGroup,
    setData: setTotalsGroup,
    isLoading,
    refresh,
  } = useAPI(`/api/settings/totals-groups/${id}`);

  const { data: limits } = useAPI("/api/settings/limits");

  const toast = useToast();

  useEffect(() => {
    if (totalsGroup && totalsGroup.calculations) {
      setCalculations(totalsGroup.calculations);
    }
  }, [totalsGroup]);

  const setKeyValue = (key, value) =>
    setTotalsGroup({ ...totalsGroup, [key]: value });

  const addCalculation = () => {
    const newCalculation = {
      action: "add",
      source: "",
      calculation: "current",
      days: 0,
    };

    setCalculations((prev) => [...prev, newCalculation]);
  };

  const removeCalculation = (index) => {
    setCalculations((prev) => prev.filter((_calc, idx) => idx !== index));
  };

  const setCalculation = (index, newCalculation) => {
    setCalculations((prev) =>
      prev.map((calc, idx) => (idx === index ? newCalculation : calc))
    );
  };

  const saveGroup = async () => {
    try {
      const response = await api(`/api/settings/totals-groups/${id}`, "put", {
        body: { ...totalsGroup, calculations },
      });

      if (response.status === "success") {
        refresh();

        toast({
          status: "success",
          title: `${totalsGroup.name} updated successfully`,
          isClosable: true,
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "An error occured",
        status: "error",
        description: error.data?.data?.message || error.message,
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Page
      title="Totals Groups"
      buttons={[
        {
          text: "Save Changes",
          onClick: saveGroup,
          icon: IconDeviceFloppy,
          colorScheme: "blue",
        },
      ]}
      backButton={true}
    >
      <Stack mb={2} direction={{ sm: "column", md: "row" }}>
        <FormControl>
          <FormLabel>Group Name</FormLabel>
          <Input
            value={totalsGroup?.name || ""}
            onChange={(e) => setKeyValue("name", e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Identifier</FormLabel>
          <Input
            value={totalsGroup?.identifier || ""}
            onChange={(e) => setKeyValue("identifier", e.target.value)}
          />
        </FormControl>
      </Stack>
      <Flex direction="column" mt={2}>
        <FormLabel>Calculations</FormLabel>
        {calculations.map((calculation, idx) => (
          <Box key={idx} mb={idx < calculations.length - 1 ? 2 : 0}>
            <TotalsGroupCalculation
              key={idx}
              calculation={calculation}
              setCalculation={(newCalculation) =>
                setCalculation(idx, newCalculation)
              }
              removeCalculation={() => removeCalculation(idx)}
              limits={limits}
            />
          </Box>
        ))}
        <Button colorScheme="blue" size={"sm"} onClick={addCalculation} mt={2}>
          Add Calculation
        </Button>
      </Flex>
    </Page>
  );
};

export default EditTotalsGroup;
