import { Page } from "../../components/Shared/Page";

const Overview = () => {
  return (
    <Page title="Overview">
      <div>Coming Soon</div>
    </Page>
  );
};

export { Overview };
