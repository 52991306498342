const ProgressBar = ({ isLoading }) => {
  return (
    <div
      className={`progress progress-sm ${!isLoading ? "d-none" : ""}`}
      style={{ borderRadius: 0 }}
    >
      <div className="progress-bar progress-bar-indeterminate"></div>
    </div>
  );
};

export default ProgressBar;
