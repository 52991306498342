import { useEffect, useState } from "react";
import {
  Box,
  Flex,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  Badge,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useBreakpointValue
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import {
  IconActivityHeartbeat,
  IconBell,
  IconCalendar,
  IconChevronDown,
  IconDashboard,
  IconFileExport,
  IconHttpConnect,
  IconList,
  IconScript,
  IconSettings,
  IconShieldLock,
  IconTable,
  IconTools
} from "@tabler/icons-react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { useContext } from "react";
import { NotificationDrawer } from "./NotificationDrawer";
import { useAPI } from "../../hooks/useAPI";
import { useWebSocket } from "../../contexts/WebsocketContext";
import allmoxyIcon from "../../assets/allmoxy.ico";

const NavLink = ({ children, path, Icon, onClose, m }) => (
  <Link
    as={RouterNavLink}
    to={path}
    px={2}
    py={1}
    rounded={"md"}
    display="flex"
    alignItems="center"
    _hover={{
      textDecoration: "none",
      bg: useColorModeValue("gray.200", "gray.700")
    }}
    _activeLink={{
      bg: useColorModeValue("gray.300", "gray.600")
    }}
    m={m || 0}
    onClick={onClose}
  >
    {Icon && <Icon style={{ marginRight: 6 }} size={20} />}
    {children}
  </Link>
);

const Layout = ({ children }) => {
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const handleSubMenuToggle = (name) => {
    setOpenSubMenu(openSubMenu === name ? null : name);
  };

  const isSubItemActive = (subItems) => {
    return subItems.some((subItem) =>
      location.pathname.startsWith(subItem.path)
    );
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useContext(AuthContext);
  const {
    isOpen: isNotificationOpen,
    onOpen: onNotificationOpen,
    onClose: onNotificationClose
  } = useDisclosure();
  const { data: notifications, refresh: refreshNotifications } = useAPI(
    "/api/notifications",
    "get"
  );

  const { data: integrations } = useAPI(
    "/api/administration/integrations",
    "get",
    undefined,
    undefined,
    undefined,
    true
  );

  const drawerVisible = useBreakpointValue({ base: true, "2xl": false });

  const socket = useWebSocket();

  useEffect(() => {
    if (socket) {
      socket.on("notification", (notification) => {
        refreshNotifications();
        // Add your toast notification here if needed
      });
    }
  }, [socket]);

  const LinkItems = [
    { path: "/", name: "Dashboard", icon: IconDashboard },
    {
      path: "/orders",
      name: "Orders",
      icon: IconTable,
      permission: ["viewMasterList"]
    },
    {
      path: "/scheduling",
      name: "Scheduling",
      icon: IconList,
      permission: ["viewScheduling"]
    },
    {
      path: "/daily-starts",
      name: "Daily Starts",
      icon: IconCalendar,
      permission: ["viewDailyStarts"]
    },
    {
      path: "/exporters",
      name: "Exporters",
      icon: IconFileExport,
      permission: ["viewExporters"]
    },
    {
      path: "/scripts",
      name: "Scripts",
      icon: IconScript,
      permission: ["viewScripts"]
    },
    {
      path: "/event-log",
      name: "Event Log",
      icon: IconActivityHeartbeat,
      permission: ["viewEventLog"]
    },
    {
      path: "/utilities",
      name: "Utilities",
      icon: IconTools,
      subItems: [
        { path: "/utilities/ocr", name: "OCR" },
        { path: "/utilities/exporters", name: "Exporters" }
      ]
    },
    {
      path: "/allmoxy",
      name: "Allmoxy",
      icon: allmoxyIcon,
      subItems: [
        { path: "/allmoxy/products", name: "Products" },
        { path: "/allmoxy/validations", name: "Validations" },
        { path: "/allmoxy/settings", name: "Settings" }
      ],
      visible:
        integrations &&
        integrations.filter((x) => x.type === "Allmoxy").length === 1,
      permission: ["manageAllmoxyIntegration"]
    },
    {
      path: "/settings",
      name: "Settings",
      icon: IconSettings,
      subItems: [
        { path: "/settings/profile", name: "Profile" },
        { path: "/settings/limits", name: "Limits" },
        { path: "/settings/totals-groups", name: "Totals Groups" },
        { path: "/settings/order-flags", name: "Order Flags" },
        { path: "/settings/holidays", name: "Holidays" }
      ],
      permission: ["viewSettings"]
    },
    {
      path: "/administration",
      name: "Administration",
      icon: IconShieldLock,
      subItems: [
        { path: "/administration/overview", name: "Overview" },
        { path: "/administration/users", name: "Users" },
        { path: "/administration/groups", name: "Groups" },
        { path: "/administration/integrations", name: "Integrations" },
        { path: "/administration/api-tokens", name: "API Tokens" },
        {
          path: "/administration/regenerate-totals",
          name: "Regenerate Totals"
        },
        { path: "/administration/delete-order", name: "Delete Order" },
        { path: "/administration/export-data", name: "Export Data" }
      ],
      permission: ["viewUsers"]
    }
  ];

  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <NotificationDrawer
        notifications={notifications}
        onNotificationClose={onNotificationClose}
        isNotificationOpen={isNotificationOpen}
        refreshNotifications={refreshNotifications}
      />
      <Box bg={useColorModeValue("white", "gray.900")} px={4}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ "2xl": "none" }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={2} alignItems={"center"}>
            <Box fontSize="2xl" fontWeight="bold">
              TruFlow
            </Box>
            <HStack as={"nav"} display={{ base: "none", "2xl": "flex" }}>
              {LinkItems.filter(
                (item) =>
                  (item.visible !== undefined ? item.visible : true) &&
                  (item.permission
                    ? item.permission.some((permission) =>
                        user.permissions.includes(permission)
                      )
                    : true)
              ).map((link) =>
                link.subItems ? (
                  <Menu key={link.name}>
                    <MenuButton
                      px={2}
                      py={1}
                      rounded={"md"}
                      display="flex"
                      alignItems="center"
                      _hover={{
                        textDecoration: "none",
                        bg: useColorModeValue("gray.200", "gray.700")
                      }}
                      bg={
                        isSubItemActive(link.subItems)
                          ? useColorModeValue("gray.300", "gray.600")
                          : "transparent"
                      }
                      fontWeight="normal"
                    >
                      <Flex alignItems="center">
                        {typeof link.icon === "string" ? (
                          <img
                            src={link.icon}
                            alt=""
                            style={{ marginRight: 6, width: 20, height: 20 }}
                          />
                        ) : (
                          <link.icon style={{ marginRight: 6 }} size={20} />
                        )}
                        {link.name}
                        <IconChevronDown style={{ marginLeft: 6 }} size={20} />
                      </Flex>
                    </MenuButton>
                    <MenuList zIndex={200}>
                      {link.subItems.map((subItem) => (
                        <MenuItem
                          key={subItem.name}
                          as={RouterNavLink}
                          to={subItem.path}
                          display="flex"
                          alignItems="center"
                        >
                          <Box as={subItem.icon} mr={2} fontSize="18px" />
                          {subItem.name}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                ) : (
                  <NavLink
                    onClose={onClose}
                    key={link.name}
                    path={link.path}
                    Icon={link.icon}
                  >
                    {link.name}
                  </NavLink>
                )
              )}
            </HStack>
          </HStack>
          <Flex alignItems={"center"}>
            <Box
              position="relative"
              display="inline-block"
              onClick={onNotificationOpen}
              mr={4}
            >
              <IconButton icon={<IconBell />} variant="ghost" />
              <Badge
                cursor={"pointer"}
                position="absolute"
                top="1"
                right="1"
                borderRadius="full"
                variant="solid"
                colorScheme="red"
                fontSize="0.6em"
                zIndex="1"
              >
                {notifications?.length}
              </Badge>
            </Box>
            <Menu>
              <MenuButton
                as={Button}
                rounded={"full"}
                cursor={"pointer"}
                minW={0}
              >
                {user.firstName} {user.lastName}
              </MenuButton>
              <MenuList zIndex={200}>
                <MenuItem>Profile</MenuItem>
                <MenuItem>Settings</MenuItem>
                <MenuDivider />
                <MenuItem>Sign out</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>

        <Drawer
          isOpen={drawerVisible ? isOpen : drawerVisible}
          placement="left"
          onClose={onClose}
          size={{ base: "full", sm: "sm" }}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>TruFlow</DrawerHeader>
            <DrawerBody>
              <Stack as={"nav"} spacing={4}>
                {LinkItems.filter((item) =>
                  item.permission
                    ? item.permission.some((permission) =>
                        user.permissions.includes(permission)
                      )
                    : true
                ).map((link) =>
                  link.subItems ? (
                    <Box key={link.name}>
                      <Flex
                        px={2}
                        py={1}
                        rounded={"md"}
                        alignItems="center"
                        _hover={{
                          textDecoration: "none",
                          bg: useColorModeValue("gray.200", "gray.700")
                        }}
                        bg={
                          isSubItemActive(link.subItems)
                            ? useColorModeValue("gray.300", "gray.600")
                            : "transparent"
                        }
                        onClick={() => handleSubMenuToggle(link.name)}
                        fontWeight="normal"
                      >
                        {" "}
                        {typeof link.icon === "string" ? (
                          <img
                            src={link.icon}
                            alt=""
                            style={{ marginRight: 6, width: 20, height: 20 }}
                          />
                        ) : (
                          <link.icon style={{ marginRight: 6 }} size={20} />
                        )}
                        {link.name}
                        <IconChevronDown style={{ marginLeft: 6 }} size={20} />
                      </Flex>
                      {openSubMenu === link.name && (
                        <Box pl={4} mt={2}>
                          {link.subItems.map((subItem) => (
                            <NavLink
                              m={2}
                              onClose={onClose}
                              key={subItem.name}
                              path={subItem.path}
                            >
                              {subItem.name}
                            </NavLink>
                          ))}
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <NavLink
                      onClose={onClose}
                      key={link.name}
                      path={link.path}
                      Icon={link.icon}
                    >
                      {link.name}
                    </NavLink>
                  )
                )}
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>

      <Box p={4}>{children}</Box>
    </Box>
  );
};

export { Layout };
