import { useState, useEffect } from "react";
import api from "../../services/api";

const useFetchRows = (startDate, setIsLoading) => {
  const [rows, setRows] = useState([]);
  const [activeRow, setActiveRow] = useState({ id: null });
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    const fetchRows = async () => {
      try {
        setIsLoading(true);

        if (!startDate) return;

        const { data } = await api(
          `/api/orders/daily-starts/${startDate}`,
          "get"
        );

        setRows(data);
      } catch (error) {
        // TODO: Handle error
      } finally {
        setIsLoading(false);
      }
    };

    fetchRows();
  }, [startDate, refresh]);

  return { rows, activeRow, setActiveRow, refresh, setRefresh };
};

export default useFetchRows;
