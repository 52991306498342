import { useAPI } from "../../../hooks/useAPI";
import { SettingsList } from "../../../components/Settings/SettingsList";
import { IconPlus, IconRefresh, IconTrash } from "@tabler/icons-react";
import swal from "sweetalert";
import { CreateOrderFlagsGroup } from "../../../components/Settings/Modals/CreateOrderFlagsGroup";
import api from "../../../services/api";
import { Grid, useDisclosure, useToast } from "@chakra-ui/react";
import { Page } from "../../../components/Shared/Page";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";

const OrderFlagsList = () => {
  useDocumentTitle("Order Flags");

  const {
    data: orderFlags,
    isLoading,
    refresh,
  } = useAPI("/api/settings/order-flags");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const deleteGroup = async ({ _id }) => {
    try {
      const confirmDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this group",
        icon: "error",
        buttons: true,
      });

      if (!confirmDelete) return swal({ title: "Cancelled", icon: "success" });

      const response = await api(`/api/settings/order-flags/${_id}`, "delete");

      if (response.status == "success") {
        refresh();

        toast({
          status: "success",
          title: `Order Flags group deleted successfully`,
          isClosable: true,
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "An error occured",
        description: error.data?.data?.message || error.message,
        status: "error",
        isClosable: true,
        duration: 5000,
      });
    }
  };

  return (
    <Page
      title="Order Flags"
      buttons={[
        { text: "Refresh", icon: IconRefresh, onClick: refresh, isLoading },
        {
          text: "Create Group",
          icon: IconPlus,
          onClick: onOpen,
          colorScheme: "blue",
        },
      ]}
    >
      <CreateOrderFlagsGroup
        isOpen={isOpen}
        onClose={onClose}
        refresh={refresh}
      />
      <Grid
        templateColumns={{
          sm: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(4, 1fr)",
        }}
        gap={4}
      >
        {" "}
        {orderFlags && orderFlags.length > 0 ? (
          <SettingsList
            data={orderFlags}
            actions={[
              { Icon: IconTrash, onClick: deleteGroup, colorScheme: "red" },
            ]}
            footer={[
              {
                position: "left",
                text: "Edit Group",
                url: (group) => `/settings/order-flags/${group._id}`,
              },
            ]}
          />
        ) : (
          <span>No order flags created</span>
        )}
      </Grid>
    </Page>
  );
};

export { OrderFlagsList };
