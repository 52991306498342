import { useState } from "react";
import api from "../../services/api";
import { Editor } from "@monaco-editor/react";
import {
  Alert,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  ModalBody,
  Stack,
  useBreakpointValue,
  ModalFooter,
  Button,
  AlertIcon,
  useToast,
} from "@chakra-ui/react";

// FIXME: create script endpoint doesn't exist?

export default ({ isOpen, onClose, refresh }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [script, setScript] = useState({
    name: "",
    usage: "exporter",
    language: "javascript",
    parser: "none",
    script: "",
    code: "",
  });

  const toast = useToast();

  const direction = useBreakpointValue({ base: "column", md: "row" });

  const createScript = async () => {
    try {
      setIsLoading(true);

      const response = await api("/api/scripts", "POST", {
        body: { ...script },
      });

      if (response.status == "success") {
        refresh();

        onClose();
      }
    } catch (error) {
      toast({
        title: "An error occured",
        status: "error",
        duration: 2000,
        isClosable: true,
        description: error.data?.data?.message || error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal size="6xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottomWidth={"1px"}>Create Script</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack mb={2} direction={direction}>
            <FormControl>
              <FormLabel>Script Name</FormLabel>
              <Input
                value={script.name}
                onChange={(e) => setScript({ ...script, name: e.target.value })}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Script Usage</FormLabel>
              <Select
                value={script.usage}
                onChange={(e) =>
                  setScript({ ...script, usage: e.target.value })
                }
              >
                <option value="exporter">Exporter</option>
              </Select>
            </FormControl>
          </Stack>
          <Stack direction={direction}>
            <FormControl>
              <FormLabel>Script Language</FormLabel>
              <Select
                value={script.language}
                onChange={(e) =>
                  setScript({ ...script, language: e.target.value })
                }
              >
                <option value="js">Javascript</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Script Parser</FormLabel>
              <Select
                value={script.parser}
                onChange={(e) =>
                  setScript({ ...script, parser: e.target.value })
                }
              >
                <option value="none">None</option>
                <option value="csv2json">CSV -&gt; JSON</option>
                <option value="xml2json">XML -&gt; JSON</option>
              </Select>
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalBody mb={2}>
          <Alert mb={4} borderRadius={4} status="warning" dismissible={true}>
            <AlertIcon />
            Your script must export a function that accepts one argument (the
            contents of the file), and it must return the contents to be saved
            to the new file
          </Alert>
          <Editor
            language="javascript"
            value={script.code}
            onChange={(e) => setScript({ ...script, code: e })}
            height="60vh"
            theme="vs-dark"
          />
        </ModalBody>
        <ModalFooter borderTopWidth={"1px"}>
          <Button
            colorScheme="blue"
            onClick={createScript}
            isLoading={isLoading}
          >
            Create Script
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
