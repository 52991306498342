import {
  IconCalendar,
  IconLock,
  IconSum,
  IconRefresh,
  IconLockOpen,
  IconSettings,
} from "@tabler/icons-react";
import { Page } from "../components/Shared/Page";
import useFetchRows from "../hooks/daily-starts/useFetchRows";
import { useEffect, useState } from "react";
import ChangeStartDate from "../components/Modals/ChangeStartDate";
import useToggleLock from "../hooks/daily-starts/useToggleLock";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { ResponsiveTable } from "../components/Shared/Table";
import { useDisclosure } from "@chakra-ui/react";

export default () => {
  useDocumentTitle("Daily Starts");

  const [ordersStartDate, setOrdersStartDate] = useState("");
  const [orderEntryStartDate, setOrderEntryStartDate] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  const [columns, setColumns] = useState([
    { id: "orderID", text: "Order #", status: true, type: "text" },
    { id: "name", text: "Job Name", status: true, type: "text" },
    { id: "companyName", text: "Company", status: true, type: "text" },
    {
      id: "type",
      text: "Type",
      status: true,
      type: "text",
      displayAttrs: true,
    },
    { id: "quantity", text: "Quantity", status: true, type: "text" },
    { id: "startDate", text: "Start Date", status: true, type: "text" },
    { id: "shipDate", text: "Ship Date", status: true, type: "text" },
    {
      id: "hasStarted",
      text: "Has Started",
      status: true,
      type: "checkbox",
      noFilter: true,
    },
  ]);

  const { rows, activeRow, setActiveRow, refresh, setRefresh } = useFetchRows(
    ordersStartDate,
    setIsLoading
  );

  const { lock, setLock, toggleLockStatus } = useToggleLock(ordersStartDate);

  const handlelockToggle = () => {
    setLock(!lock);
    toggleLockStatus();
  };

  const toggleColumnVisibility = (columnId) => {
    setColumns((prevState) =>
      prevState.map((col) =>
        col.id === columnId ? { ...col, status: !col.status } : col
      )
    );
  };

  useEffect(() => {
    const startDate = localStorage.getItem("startDate");

    if (startDate) setOrdersStartDate(startDate);
  }, []);

  useEffect(
    () => localStorage.setItem("startDate", ordersStartDate),
    [ordersStartDate]
  );

  const columnSettings = columns.map((column) => ({
    label: column.text,
    isChecked: column.status,
    onChange: () => toggleColumnVisibility(column.id),
  }));

  return (
    <Page
      title="Daily Starts"
      inputs={[
        {
          type: "date",
          value: ordersStartDate,
          onChange: (e) => setOrdersStartDate(e.target.value),
        },
      ]}
      buttons={[
        {
          text: lock ? "Unlock Day" : "Lock Day",
          icon: lock ? IconLock : IconLockOpen,
          onClick: handlelockToggle,
        },
        {
          text: "Change Start Date",
          icon: IconCalendar,
          isLoading: activeRow.id ? false : true,
          onClick: onOpen,
        },
        {
          text: "Refresh",
          icon: IconRefresh,
          isLoading: isLoading,
          onClick: () => setRefresh(refresh + 1),
        },
        {
          text: "Totals",
          icon: IconSum,
          onClick: () =>
            window.open(
              "/daily-starts/totals",
              "popUpWindow",
              "height=500,width=800,left=100,top=100,resizable=no,location=no"
            ),
          colorScheme: "blue",
        },
      ]}
      dropdowns={[
        {
          label: "Show/Hide Columns",
          icon: <IconSettings />,
          items: columnSettings,
        },
      ]}
      cardBodyPadding={0}
      sticky={true}
    >
      <ResponsiveTable
        rows={rows}
        activeRow={activeRow}
        setActiveRow={setActiveRow}
        columns={columns}
      />
      <ChangeStartDate
        isOpen={isOpen}
        onClose={onClose}
        row={activeRow}
        startDate={orderEntryStartDate}
        setStartDate={setOrderEntryStartDate}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </Page>
  );
};
