import api from "../../../services/api";
import { DateTime } from "luxon";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";

const CreateHoliday = ({
  isOpen,
  onClose,
  refresh,
  holidayData,
  setHolidayData,
}) => {
  const toast = useToast();

  const createHoliday = async () => {
    try {
      const response = await api("/api/settings/holidays", "post", {
        body: holidayData,
      });

      if (response.status === "success") {
        refresh();

        onClose();

        setHolidayData({ title: "", date: "" });

        toast({
          status: "success",
          title: `${holidayData.title} created successfully`,
          isClosable: true,
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "An error occured",
        status: "error",
        description: error.data?.data?.message || error.message,
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Holiday</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={4}>
            <FormLabel>Title</FormLabel>
            <Input
              value={holidayData.title}
              onChange={(e) =>
                setHolidayData({ ...holidayData, title: e.target.value })
              }
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Date</FormLabel>
            <Input
              type="date"
              value={holidayData.date}
              onChange={(e) =>
                setHolidayData({
                  ...holidayData,
                  date: DateTime.fromISO(e.target.value).toISODate(),
                })
              }
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button onClick={createHoliday} colorScheme="blue">
            Create Group
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { CreateHoliday };
