import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useBreakpointValue,
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  IconButton,
  HStack,
  Spacer,
} from "@chakra-ui/react";
import { useState, useEffect, useRef } from "react";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import { ResponsiveEntry } from "./ResponsiveEntry";

const ToggleableText = ({ status, text }) => {
  return <>{status && <Text>{text}</Text>}</>;
};

const ToggleableHeader = ({ text, id, filters, setFilters, noFilter }) => {
  const inputRef = useRef(null);

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const clearFilter = (name) => {
    setFilters({ ...filters, [name]: "" });
  };

  const renderFilterPopover = (name) => (
    <Popover initialFocusRef={inputRef}>
      <PopoverTrigger>
        <IconButton
          zIndex={50}
          aria-label={`Filter ${name}`}
          icon={<SearchIcon />}
          size="sm"
          variant="outline"
        />
      </PopoverTrigger>
      <PopoverContent width="200px">
        <PopoverArrow />
        <PopoverBody display="flex" alignItems="center">
          <Input
            ref={inputRef}
            name={name}
            value={filters[name]}
            onChange={handleFilterChange}
            size="sm"
            placeholder="Filter"
          />
          <IconButton
            aria-label="Clear filter"
            icon={<CloseIcon />}
            size="xs"
            variant="ghost"
            ml={2}
            onClick={() => clearFilter(name)}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );

  return (
    <Th>
      <HStack>
        <Text>{text}</Text>
        <Spacer />
        {!noFilter && id && renderFilterPopover(id)}
      </HStack>
    </Th>
  );
};

const ResponsiveTable = ({
  rows = [],
  activeRow,
  setActiveRow,
  columns,
  idSelector = "id",
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [filters, setFilters] = useState(() => {
    const initialFilters = {};
    columns.forEach((col) => {
      if (col.noFilter) return;
      else initialFilters[col.id] = "";
    });
    return initialFilters;
  });
  const [filteredRows, setFilteredRows] = useState([]);

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const clearFilter = (name) => {
    setFilters({ ...filters, [name]: "" });
  };

  useEffect(() => {
    rows &&
      setFilteredRows(
        rows.filter((row) =>
          columns.every((col) => {
            if (col.noFilter) return true;
            if (!col.status) return true;
            const rowValue = row[col.id]?.toString().toLowerCase() || "";
            const filterValue = filters[col.id]?.toLowerCase() || "";
            return rowValue.includes(filterValue);
          })
        )
      );
  }, [filters, columns, rows]);

  return (
    <>
      {isMobile ? (
        <Box p={4}>
          {filteredRows.map((row) => (
            <Box
              key={row[idSelector]}
              bg={row[idSelector] === activeRow[idSelector] ? "gray.300" : ""}
              color={row.highlightColor}
              borderWidth="1px"
              borderRadius="md"
              p={4}
              mb={4}
              onClick={() => setActiveRow(row)}
            >
              <VStack align="start" spacing={4}>
                {columns.map((col) =>
                  col.status && col.displayAttrs ? (
                    <>
                      <Box key={`${row[idSelector]}-${col.id}`}>
                        <Text>
                          <strong>{col.text}:</strong> {row[col.id]}
                        </Text>
                        {row.allmoxyData && row.allmoxyData.length > 0 && (
                          <HStack align="start" spacing={1} mt={2}>
                            {row.allmoxyData?.map(
                              (attr) =>
                                attr.displayOn?.includes("scheduling") && (
                                  <Box key={attr._id} fontSize="sm">
                                    <strong>{attr.displayName}:</strong>{" "}
                                    {attr.value}
                                  </Box>
                                )
                            )}
                          </HStack>
                        )}
                      </Box>
                    </>
                  ) : (
                    <Text key={`${row[idSelector]}-${col.id}`}>
                      <strong>{col.text}:</strong> {row[col.id]}
                    </Text>
                  )
                )}
              </VStack>
            </Box>
          ))}
        </Box>
      ) : (
        <TableContainer>
          <Table size="sm" colorScheme="gray">
            <Thead>
              <Tr>
                {columns.map(
                  (col) =>
                    col.status && (
                      <ToggleableHeader
                        key={col.id}
                        text={col.text}
                        id={col.id}
                        noFilter={col.noFilter}
                        filters={filters}
                        setFilters={setFilters}
                      />
                    )
                )}
              </Tr>
            </Thead>
            <Tbody>
              {filteredRows.map((row) => (
                <ResponsiveEntry
                  key={row[idSelector]}
                  row={row}
                  activeRow={activeRow}
                  setActiveRow={setActiveRow}
                  columns={columns}
                  idSelector={idSelector}
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export { ToggleableText, ToggleableHeader, ResponsiveTable };
