import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  SimpleGrid,
  useBreakpointValue,
} from "@chakra-ui/react";

const ResponsiveTable = ({ headers, totals }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return isMobile ? (
    <Box p={4}>
      {totals &&
        totals.map(({ totals: totalValues, _id }, boxIndex) => (
          <Box key={_id} borderWidth="1px" borderRadius="md" p={2} mb={4}>
            <Text mb={2} fontSize="sm">
              <strong>Date:</strong> {_id}
            </Text>
            <SimpleGrid columns={3} spacing={2}>
              {totalValues.map((total, index) => (
                <Text key={`${boxIndex}-${index}`} fontSize="sm">
                  <strong>{headers[index][0]}:</strong> {total}
                </Text>
              ))}
            </SimpleGrid>
          </Box>
        ))}
    </Box>
  ) : (
    <TableContainer>
      <Table size="sm" variant="striped" colorScheme="gray">
        <Thead>
          <Tr>
            <Th>Date</Th>
            {headers &&
              headers.map((header) => <Th key={header[1]}>{header[0]}</Th>)}
          </Tr>
        </Thead>
        <Tbody>
          {totals &&
            totals.map(({ totals, _id }) => (
              <Tr>
                <Td>{_id}</Td>
                {totals.map((total) => (
                  <Td>{total}</Td>
                ))}
              </Tr>
            ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export { ResponsiveTable };
