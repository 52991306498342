import { Page } from "../../../components/Shared/Page";
import { SettingsList } from "../../../components/Settings/SettingsList";
import { IconRefresh, IconTrash } from "@tabler/icons-react";
import { useAPI } from "../../../hooks/useAPI";
import api from "../../../services/api";
import swal from "sweetalert";
import { Grid, useToast } from "@chakra-ui/react";

const UsersList = () => {
  const { data: users, isLoading, refresh } = useAPI(`/api/users`, "get");
  const toast = useToast();

  const deleteUser = async ({ _id }) => {
    try {
      const confirmDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this user",
        icon: "error",
        buttons: true,
      });

      if (!confirmDelete) return swal({ title: "Cancelled", icon: "success" });

      const { status } = await api(`/api/administration/users/${_id}`);

      if (status === "success") {
        toast({
          status: "success",
          title: `User deleted successfully`,
          isClosable: true,
          duration: 5000,
        });

        refresh();
      }
    } catch (error) {
      toast({
        title: "An error occured",
        status: "error",
        description: error.data?.data?.message || error.message,
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Page
      title="Users"
      buttons={[
        {
          text: "Refresh",
          isLoading,
          icon: IconRefresh,
          onClick: refresh,
          colorScheme: "blue",
        },
      ]}
    >
      <Grid
        templateColumns={{
          sm: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(4, 1fr)",
        }}
        gap={4}
      >
        {users && users.length > 0 && (
          <SettingsList
            data={users}
            actions={[
              { Icon: IconTrash, onClick: deleteUser, colorScheme: "red" },
            ]}
            titleKey={["firstName", "lastName"]}
            footer={[
              {
                position: "left",
                text: "Edit User",
                url: (user) => `/administration/users/${user._id}`,
              },
            ]}
          />
        )}
      </Grid>
    </Page>
  );
};

export { UsersList };
