import { IconPlus, IconRefresh, IconTrash } from "@tabler/icons-react";
import { Page } from "../../components/Shared/Page";
import {
  Alert,
  AlertDescription,
  Grid,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { CreateIntegration } from "../../components/Administration/Modals/CreateIntegration";
import { useAPI } from "../../hooks/useAPI";
import { useEffect } from "react";
import { SettingsList } from "../../components/Settings/SettingsList";
import api from "../../services/api";

const Integrations = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const { data: integrationTypes, isLoadingIntegrationTypes } = useAPI(
    "/api/administration/integrations/list"
  );

  const { data: integrations, isLoadingIntegrations } = useAPI(
    "/api/administration/integrations"
  );

  const reloadAllmoxyCache = async () => {
    try {
      const response = await api(
        "/api/administration/integrations/reload-allmoxy-cache"
      );

      toast({
        title: response.data.message,
        status: "success",
        duration: 2000,
        isClosable: true
      });
    } catch (error) {
      toast({
        title: "An error occured",
        status: "error",
        duration: 2000,
        isClosable: true,
        description: error.data?.data?.message || error.message
      });
    }
  };

  return (
    <Page
      title="Integrations"
      buttons={[
        {
          text: "Add Integration",
          icon: IconPlus,
          colorScheme: "blue",
          onClick: onOpen,
          disabled: isLoadingIntegrationTypes
        }
      ]}
    >
      <Alert borderRadius={8} marginBottom={4}>
        <AlertDescription>
          After adding a new integration you will need to restart the backend
          for the changes to take effect
        </AlertDescription>
      </Alert>
      <CreateIntegration
        onClose={onClose}
        isOpen={isOpen}
        integrations={integrationTypes}
      />
      <Grid
        templateColumns={{
          sm: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(4, 1fr)"
        }}
        gap={4}
      >
        {integrations && integrations.length > 0 && (
          <SettingsList
            data={integrations}
            actions={[
              { Icon: IconTrash, onClick: () => null, colorScheme: "red" },
              { Icon: IconRefresh, onClick: reloadAllmoxyCache }
            ]}
            footer={[
              {
                position: "left",
                text: "Edit Integration",
                onClick: (integration) => console.log(integration)
              }
            ]}
            titleKey="type"
          />
        )}
      </Grid>
    </Page>
  );
};

export { Integrations };
