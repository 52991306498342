import {
  Link as ChakraLink,
  HStack,
  Spacer,
  Card,
  CardBody,
  CardHeader,
  Heading,
  IconButton,
  Box,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";

const SettingsList = ({ data, actions, footer, titleKey = "name" }) => {
  const leftFooter = footer?.filter(({ position }) => position === "left");
  const rightFooter = footer?.filter(({ position }) => position === "right");

  return data.map((e) => {
    let title;

    if (Array.isArray(titleKey)) {
      title = titleKey.map((key) => e[key]).join(" ");
    } else {
      title = e[titleKey];
    }

    return (
      <Card
        borderWidth={"1px"}
        borderColor={"gray.200"}
        aria-colspan={1}
        rounded="md"
        key={e._id}
        shadow={"0"}
      >
        <CardBody p={3} borderBottomWidth={"1px"}>
          <HStack
            alignItems={"center"}
            justifyContent={"center"}
            flexWrap={{ base: "wrap", md: "nowrap" }}
          >
            <CardHeader p={1}>
              <Heading size={"sm"}>{title}</Heading>
            </CardHeader>
            <Spacer />
            {actions &&
              actions.map(({ Icon, onClick, colorScheme }, index) => (
                <IconButton
                  icon={<Icon />}
                  colorScheme={colorScheme || "gray"}
                  key={`${e._id}-${index}`}
                  onClick={() => onClick(e)}
                />
              ))}
          </HStack>
        </CardBody>
        <CardBody p={3}>
          <HStack
            alignItems={"center"}
            justifyContent={"center"}
            flexWrap={{ base: "wrap", md: "nowrap" }}
          >
            {leftFooter?.map(({ text, url, onClick, contents }, index) => (
              <div key={index} style={{ display: "inline-flex" }}>
                {text ? (
                  <ChakraLink
                    color={"blue.500"}
                    as={Link}
                    to={url && url(e)}
                    onClick={onClick && onClick(e)}
                  >
                    {text}
                  </ChakraLink>
                ) : (
                  contents
                )}
              </div>
            ))}
            <Spacer />
            {rightFooter?.map(({ contents }, index) => (
              <div key={index} style={{ display: "inline-flex" }}>
                {contents(e)}
              </div>
            ))}
          </HStack>
        </CardBody>
      </Card>
    );
  });
};

export { SettingsList };
