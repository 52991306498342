import { Page } from "../../../components/Shared/Page";
import { IconDeviceFloppy, IconPlus, IconX } from "@tabler/icons-react";
import { useParams } from "react-router-dom";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { useAPI } from "../../../hooks/useAPI";
import api from "../../../services/api";
import swal from "sweetalert";
import {
  Card,
  CardHeader,
  FormControl,
  FormLabel,
  Grid,
  HStack,
  IconButton,
  Input,
  Spacer,
  Stack,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";

const EditLimit = () => {
  useDocumentTitle("Limits");

  const { id } = useParams();

  const {
    data: limit,
    setData: setLimit,
    refresh,
  } = useAPI(`/api/settings/limits/${id}`);

  const toast = useToast();

  const setKeyValue = (key, value) => setLimit({ ...limit, [key]: value });

  const handleRemove = (productToRemove) => {
    setKeyValue(
      "products",
      limit.products.filter((product) => product !== productToRemove)
    );
  };

  const addProduct = async () => {
    const product = await swal({
      text: "Enter the name of the product",
      content: "input",
    });

    if (product) setKeyValue("products", [...limit.products, product]);
  };

  const saveGroup = async () => {
    try {
      const response = await api(`/api/settings/limits/${id}`, "put", {
        body: limit,
      });

      if (response.status === "success") {
        refresh();

        toast({
          status: "success",
          title: `${limit.name} updated successfully`,
          isClosable: true,
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "An error occured",
        status: "error",
        description: error.data?.data?.message || error.message,
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const rpd = useBreakpointValue({
    base: "Required Production Days",
    md: "RPD",
    lg: "Required Production Days",
  });

  return (
    <Page
      title={limit ? limit.name : "Loading..."}
      buttons={[
        {
          text: "Add Product",
          onClick: addProduct,
          icon: IconPlus,
        },
        {
          text: "Save Changes",
          onClick: saveGroup,
          icon: IconDeviceFloppy,
          colorScheme: "blue",
        },
      ]}
      backButton={true}
    >
      <Stack mb={2} direction={{ base: "column", md: "row" }}>
        <FormControl>
          <FormLabel>Category Name</FormLabel>
          <Input
            value={limit?.name || ""}
            onChange={(e) => setKeyValue("name", e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Daily Limit</FormLabel>
          <Input
            type="number"
            value={limit?.limit || ""}
            onChange={(e) => setKeyValue("limit", e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>{rpd}</FormLabel>
          <Input
            type="number"
            value={limit?.rpd || ""}
            onChange={(e) => setKeyValue("rpd", e.target.value)}
          />
        </FormControl>
      </Stack>
      <FormLabel>Products</FormLabel>
      <Grid
        templateColumns={{
          sm: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(4, 1fr)",
        }}
        gap={4}
      >
        {limit?.products.map((product) => (
          <Card
            key={product}
            borderWidth={"1px"}
            borderColor={"gray.200"}
            shadow={0}
          >
            <HStack alignItems={"center"} justifyContent={"center"}>
              <CardHeader>{product}</CardHeader>
              <Spacer />
              <IconButton
                variant={"unstyled"}
                icon={<IconX />}
                onClick={() => handleRemove(product)}
              />
            </HStack>
          </Card>
        ))}
      </Grid>
    </Page>
  );
};

export { EditLimit };
