export default async (
  endpoint,
  method,
  parameters = {},
  headers = {},
  file = false
) => {
  const { query = "", body = null, formData = null } = parameters;

  let options = {
    method,
    headers: {
      ...headers
    },
    credentials: "include"
  };

  let url = `${process.env.REACT_APP_API_URL}${endpoint}`;

  if (query) {
    const queryString = new URLSearchParams(query).toString();
    url = `${url}?${queryString}`;
  }

  if (body && !formData) {
    options.body = JSON.stringify(body);
    options.headers["Content-Type"] = "application/json";
  }

  if (formData) options.body = formData;

  let response;

  try {
    response = await fetch(url, options);
  } catch (error) {
    throw new Error(`Network request failed: ${error.message}`);
  }

  let data;
  const contentType = response.headers.get("Content-Type") || "";

  if (contentType.includes("application/json")) {
    data = await response.json();
  } else {
    data = await response.text();
  }

  if (!response.ok) {
    const error = new Error(
      data && data.message
        ? data.message
        : `Request failed: ${response.statusText}`
    );
    error.data = data;
    error.statusCode = response.status;

    throw error;
  }

  if (data && data.status !== "success") {
    const error = new Error(
      data && data.message
        ? data.message
        : `Request failed: ${response.statusText}`
    );
    error.data = data;
    error.statusCode = response.status;

    throw error;
  }

  if (file === true) return await response.blob();

  return data;
};
