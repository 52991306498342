import { useState, useEffect, useCallback } from "react";
import { DateTime, Interval } from "luxon";
import api from "../services/api";

const useChangeStartDate = (
  row,
  shipSelection,
  startDate,
  applyToOrder,
  refresh,
  setRefresh,
  onClose,
  setIsLoading,
  order
) => {
  const [shipDate, setShipDate] = useState("");
  const [alert, setAlert] = useState(null);
  const [isLockedDay, setIsLockedDay] = useState(false);
  const [isLockedDayModalOpen, setIsLockedDayModalOpen] = useState(false);
  const bc = new BroadcastChannel("daily_totals");

  const days = useCallback(function* (interval) {
    let cursor = interval.start.startOf("day");
    while (cursor < interval.end) {
      yield cursor;
      cursor = cursor.plus({ days: 1 });
    }
  }, []);

  const calculateRPD = useCallback(
    (requiredProductionDays, requestedStartDate) => {
      const initialShipDate = DateTime.fromISO(requestedStartDate).plus({
        days: requiredProductionDays
      });
      const numWeekDays = Array.from(
        days(Interval.fromDateTimes(requestedStartDate, initialShipDate))
      ).reduce((acc, d) => acc + (d.weekday === 6 || d.weekday === 7), 0);

      return requiredProductionDays + numWeekDays;
    },
    [days]
  );

  const saveChanges = async (skipLockedDay = false) => {
    setIsLoading(true);
    const luxonShipDate = shipDate ? DateTime.fromISO(shipDate) : null;
    let endpoint = "/api/orders/update/start-date";
    let body = {
      ...row,
      updatedStartDate: startDate,
      applyToOrder,
      skipLockedDay
    };

    if (shipSelection !== "dontAdjust") {
      body.shipDate = luxonShipDate;
    } else {
      body.shipDate = null;
    }

    if (applyToOrder) {
      endpoint = "/api/orders/update/multiple-start-date";
      body = { ...order, updatedStartDate: startDate, skipLockedDay };
    }

    try {
      const response = await api(endpoint, "put", { body });

      if (response.status === "success") {
        setRefresh(refresh + 1);
        bc.postMessage(true);
        onClose();
      }
    } catch (error) {
      if (error?.data?.message === "That date is currently locked") {
        setIsLockedDay(true);
        setIsLockedDayModalOpen(true);
      } else
        setAlert({
          variant: "error",
          message: error?.data?.message || "An error occurred"
        });
    } finally {
      setIsLoading(false);
    }
  };

  const handleBypassLockedDay = () => {
    setIsLockedDayModalOpen(false);
    saveChanges(true);
  };

  const onModalClose = () => {
    setIsLockedDayModalOpen(false);
  };

  useEffect(() => {
    if (shipSelection === "autoAdjust" && startDate) {
      const luxonStartDate = DateTime.fromISO(startDate);
      const rpd = parseInt(row.rpd, 10);
      const updatedRPD = calculateRPD(rpd, luxonStartDate);

      let adjustedShipDate = luxonStartDate.plus({ days: updatedRPD });
      if ([6, 7].includes(adjustedShipDate.weekday)) {
        adjustedShipDate = adjustedShipDate.plus({
          days: adjustedShipDate.weekday === 6 ? 2 : 1
        });
      }

      setShipDate(adjustedShipDate.toISODate());
    }
  }, [startDate, shipSelection, calculateRPD]);

  useEffect(() => setAlert(null), [row]);

  return {
    shipDate,
    setShipDate,
    saveChanges,
    alert,
    isLockedDay,
    handleBypassLockedDay,
    onModalClose,
    isLockedDayModalOpen
  };
};

export default useChangeStartDate;
