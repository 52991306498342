import { AllmoxySettings } from "../pages/Allmoxy/Settings";
import { AllmoxyProducts } from "../pages/Allmoxy/Products";
import { Route, Routes } from "react-router-dom";

const Allmoxy = () => {
  return (
    <Routes>
      <Route path="/products" element={<AllmoxyProducts />} />
      <Route path="/settings" element={<AllmoxySettings />} />
    </Routes>
  );
};

export { Allmoxy };
