import { Page } from "../../../components/Shared/Page";
import { useParams } from "react-router-dom";
import { useAPI } from "../../../hooks/useAPI";
import { TagInput } from "../../../components/Settings/TagInput";
import { IconDeviceFloppy } from "@tabler/icons-react";
import api from "../../../services/api";
import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";

const EditOrderFlag = () => {
  useDocumentTitle("Order Flags");

  const { id } = useParams();

  const {
    data: orderFlag,
    setData: setOrderFlag,
    isLoading,
    refresh,
  } = useAPI(`/api/settings/order-flags/${id}`);

  const toast = useToast();

  const setKeyValue = (key, value) =>
    setOrderFlag({ ...orderFlag, [key]: value });

  const saveFlag = async () => {
    try {
      const response = await api(`/api/settings/order-flags/${id}`, "put", {
        body: orderFlag,
      });

      if (response.status === "success") {
        refresh();

        toast({
          status: "success",
          title: `${orderFlag.name} updated successfully`,
          isClosable: true,
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "An error occured",
        status: "error",
        description: error.data?.data?.message || error.message,
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Page
      title={orderFlag ? orderFlag.name : "Loading..."}
      buttons={[
        {
          text: "Save Changes",
          onClick: saveFlag,
          icon: IconDeviceFloppy,
          colorScheme: "blue",
        },
      ]}
      backButton={true}
    >
      <Stack mb={2} direction={{ sm: "column", md: "row" }}>
        <FormControl>
          <FormLabel>Name</FormLabel>
          <Input
            value={orderFlag?.name || ""}
            onChange={(e) => setKeyValue("name", e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Highlight Color</FormLabel>
          <Input
            type="color"
            value={orderFlag?.highlightColor || "#FFFFFF"}
            w={"100%"}
            onChange={(e) => setKeyValue("highlightColor", e.target.value)}
          />
        </FormControl>
      </Stack>
      {!isLoading && (
        <TagInput
          mb={2}
          name="Products"
          initialTags={orderFlag.products || ""}
          setter={(e) => setKeyValue("products", e)}
        />
      )}
      {!isLoading && (
        <TagInput
          name="Tags"
          initialTags={orderFlag?.tags || ""}
          setter={(e) => setKeyValue("tags", e)}
        />
      )}
    </Page>
  );
};

export { EditOrderFlag };
