import { useContext, useEffect, useState } from "react";
import { Page } from "../../components/Shared/Page";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useAPI } from "../../hooks/useAPI";
import {
  Checkbox,
  VStack,
  Box,
  Text,
  Flex,
  useToast,
  Divider,
  Progress
} from "@chakra-ui/react";
import { IconRefresh, IconReload } from "@tabler/icons-react";
import api from "../../services/api";
import { useWebSocket } from "../../contexts/WebsocketContext";
import { AuthContext } from "../../contexts/AuthContext";

const AllmoxySettings = () => {
  useDocumentTitle("Settings | Allmoxy");

  const { data, setData, refresh, isLoading } = useAPI(
    "/api/allmoxy/settings/"
  );
  const toast = useToast();
  const [updates, setUpdates] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [processedOrders, setProcessedOrders] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [elapsedTime, setElapsedTime] = useState("");
  const [estimatedTimeRemaining, setEstimatedTimeRemaining] = useState("");

  const socket = useWebSocket();

  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (!socket) return;

    const handleUpdate = (data) => {
      setUpdates((prevUpdates) => {
        const newUpdates = [...prevUpdates, data];

        if (data.totalOrders !== undefined) {
          setTotalOrders(data.totalOrders);
        }

        if (data.processedOrders !== undefined) {
          setProcessedOrders(data.processedOrders);
        }

        if (data.elapsedTime !== undefined) {
          setElapsedTime(data.elapsedTime);
        }

        if (data.estimatedTimeRemaining !== undefined) {
          setEstimatedTimeRemaining(data.estimatedTimeRemaining);
        }

        return newUpdates.length > 10 ? newUpdates.slice(-10) : newUpdates;
      });

      if (data.message.includes("processed")) {
        setProcessedOrders((prevProcessed) => prevProcessed + 1);
      }
    };

    socket.on("loadAllmoxyDataUpdate", handleUpdate);

    return () => {
      socket.off("loadAllmoxyDataUpdate", handleUpdate);
    };
  }, [socket]);

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const response = await api("/api/allmoxy/settings/history", "get");

        setUpdates(response.data);
      } catch (error) {
        console.error("Error fetching history:", error);
      }
    };

    fetchHistory();
  }, []);

  useEffect(() => {
    setPercentage(totalOrders ? (processedOrders / totalOrders) * 100 : 0);
  }, [totalOrders, processedOrders]);

  const handleCheckboxChange = async (e) => {
    const { name, checked } = e.target;
    const updatedData = {
      ...data,
      settings: {
        ...data.settings,
        [name]: checked
      }
    };

    setData(updatedData);

    try {
      await api("/api/allmoxy/settings/", "put", {
        body: updatedData
      });

      refresh();

      toast({
        title: "Settings updated",
        status: "success",
        isClosable: true,
        duration: 2000
      });
    } catch (error) {
      toast({
        title: "An error occurred",
        status: "error",
        description: error.data?.data?.message || error.message,
        isClosable: true,
        duration: 2000
      });
    }
  };

  const triggerReload = async () => {
    try {
      setUpdates([]);

      await api("/api/allmoxy/settings/reload-all-data", "get");

      toast({
        title: "Reload started",
        status: "info",
        isClosable: true,
        duration: 2000
      });
    } catch (error) {
      toast({
        title: "An error occurred",
        status: "error",
        description: error.data?.data?.message || error.message,
        isClosable: true,
        duration: 2000
      });
    }
  };

  return (
    <Page
      title="Allmoxy Settings"
      buttons={[
        {
          text: "Reload Data",
          onClick: triggerReload,
          icon: IconReload
        },
        {
          text: "Refresh",
          onClick: refresh,
          icon: IconRefresh,
          colorScheme: "blue",
          isLoading
        }
      ]}
    >
      <VStack spacing={4} align="stretch">
        <Box borderWidth="1px" borderRadius="lg" p={4}>
          <Text fontWeight="bold" fontSize="xl" mb={4}>
            Settings
          </Text>
          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent={{ base: "flex-start", md: "flex-start" }}
            alignItems={{ base: "flex-start", md: "center" }}
          >
            <Checkbox
              name="loadOrdersFromAPI"
              isChecked={data?.settings.loadOrdersFromAPI || false}
              onChange={handleCheckboxChange}
              mr={4}
              mb={{ base: 4, md: 0 }}
            >
              Load Orders from the API
            </Checkbox>
            <Checkbox
              name="saveSelectedAttributesInOrder"
              isChecked={data?.settings.saveSelectedAttributesInOrder || false}
              onChange={handleCheckboxChange}
            >
              Save Selected Attributes in Orders
            </Checkbox>
          </Flex>
        </Box>

        <Box borderWidth="1px" borderRadius="lg" p={4}>
          <Text fontWeight="bold" fontSize="xl" mb={4}>
            Webhook Info
          </Text>
          <VStack align="start" spacing={2}>
            <Box>
              <Text>
                <strong>Trigger ID: </strong>
                {data?.settings.webhook?.id}
              </Text>
            </Box>
            <Box>
              <Text>
                <strong>Trigger URL: </strong>
                {data?.settings.webhook?.url || ""}
              </Text>
            </Box>
            <Box>
              <Text>
                <strong>Trigger Event: </strong>
                {data?.settings.webhook?.event || ""}
              </Text>
            </Box>
          </VStack>
        </Box>

        {user && user.permissions.includes("allmoxyReloadAllData") && (
          <Box borderWidth="1px" borderRadius="lg" p={4}>
            <Text fontWeight="bold" fontSize="xl" mb={4}>
              Updates
            </Text>
            <Box mb={4}>
              <Progress value={percentage} mb={2} />
              <Flex justify="space-between">
                <Text fontWeight="bold">Elapsed Time: {elapsedTime}</Text>
                <Text fontWeight="bold">
                  Estimated Time Remaining: {estimatedTimeRemaining}
                </Text>
              </Flex>
            </Box>
            <VStack align="stretch" spacing={3} divider={<Divider />}>
              {updates.map((update, index) => (
                <Box
                  key={index}
                  px={4}
                  py={2}
                  borderLeftWidth="4px"
                  borderLeftColor={
                    update.type === "info"
                      ? "blue.500"
                      : update.type === "warning"
                      ? "yellow.500"
                      : "red.500"
                  }
                  backgroundColor={
                    update.type === "info"
                      ? "blue.50"
                      : update.type === "warning"
                      ? "yellow.50"
                      : "red.50"
                  }
                >
                  <Text>{update.message}</Text>
                </Box>
              ))}
            </VStack>
          </Box>
        )}
      </VStack>
    </Page>
  );
};

export { AllmoxySettings };
