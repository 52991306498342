import { Route, Routes } from "react-router-dom";
import { Dashboard } from "../pages/Dashboard";
import Orders from "../pages/Orders";
import Scheduling from "../pages/Scheduling";
import DailyStarts from "../pages/DailyStarts";
import DailyTotals from "../pages/DailyTotals";
import ExportersPage from "../pages/Exporters";
import Scripts from "../pages/Scripts";
import OCR from "../pages/Utilities/OCR";
import Exporters from "../pages/Utilities/Exporters";
import { Login } from "../pages/Login";
import { Register } from "../pages/Register";
import { Settings } from "./settings";
import { Administration } from "./administration";
import { Allmoxy } from "./allmoxy";
import { EventLog } from "../pages/EventLog";

const IndexRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/scheduling" element={<Scheduling />} />
      <Route path="/daily-starts" element={<DailyStarts />} />
      <Route path="/daily-starts/totals" element={<DailyTotals />} />
      <Route path="/exporters" element={<ExportersPage />} />
      <Route path="/scripts" element={<Scripts />} />
      <Route path="/event-log" element={<EventLog />} />
      <Route path="/utilities/ocr" element={<OCR />} />
      <Route path="/utilities/exporters" element={<Exporters />} />
      <Route path="/allmoxy/*" element={<Allmoxy />} />
      <Route path="/settings/*" element={<Settings />} />
      <Route path="/administration/*" element={<Administration />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
    </Routes>
  );
};

export { IndexRoutes };
