const { Page } = require("../../components/Shared/Page");
import { Box, Checkbox, Button, VStack, useToast } from "@chakra-ui/react";
import { useState } from "react";
import api from "../../services/api";

const ExportData = () => {
  const [selectedCollections, setSelectedCollections] = useState([]);
  const toast = useToast();

  const handleCollectionToggle = (collection) => {
    if (selectedCollections.includes(collection)) {
      setSelectedCollections(
        selectedCollections.filter((col) => col !== collection)
      );
    } else {
      setSelectedCollections([...selectedCollections, collection]);
    }
  };

  const handleExport = async () => {
    try {
      const response = await api(
        "/api/administration/helpers/export-data",
        "GET",
        {
          query: { collections: selectedCollections.join(",") },
        },
        "",
        true
      );

      const url = window.URL.createObjectURL(response);

      const link = document.createElement("a");
      link.href = url;
      link.download = "TruFlowExport.zip";

      link.click();

      window.URL.revokeObjectURL(url);

      link.remove();
    } catch (error) {
      toast({
        title: "An error occured",
        status: "error",
        duration: 2000,
        isClosable: true,
        description: error.data?.data?.message || error.message,
      });
    }
  };

  return (
    <Page title="Export Data">
      <Box>
        <VStack spacing={2} mb={2} align="start">
          {/* Checkboxes for selecting collections */}
          <Checkbox
            isChecked={selectedCollections.includes("daily-totals")}
            onChange={() => handleCollectionToggle("daily-totals")}
          >
            Daily Totals
          </Checkbox>
          <Checkbox
            isChecked={selectedCollections.includes("settings")}
            onChange={() => handleCollectionToggle("settings")}
          >
            Settings
          </Checkbox>
          <Checkbox
            isChecked={selectedCollections.includes("users")}
            onChange={() => handleCollectionToggle("users")}
          >
            Users
          </Checkbox>
          <Checkbox
            isChecked={selectedCollections.includes("orders")}
            onChange={() => handleCollectionToggle("orders")}
          >
            Orders
          </Checkbox>
          <Checkbox
            isChecked={selectedCollections.includes("scripts")}
            onChange={() => handleCollectionToggle("scripts")}
          >
            Scripts
          </Checkbox>
          <Checkbox
            isChecked={selectedCollections.includes("event-log")}
            onChange={() => handleCollectionToggle("event-log")}
          >
            Event Log
          </Checkbox>
          <Checkbox
            isChecked={selectedCollections.includes("exporters")}
            onChange={() => handleCollectionToggle("exporters")}
          >
            Exporters
          </Checkbox>
        </VStack>
        <Button
          onClick={handleExport}
          disabled={selectedCollections.length === 0}
          colorScheme="blue"
        >
          Export
        </Button>
      </Box>
    </Page>
  );
};

export { ExportData };
