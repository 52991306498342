import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { App } from "./App";
import { createRoot } from "react-dom/client";
import { AuthProvider } from "./contexts/AuthContext";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { WebSocketProvider } from "./contexts/WebsocketContext";

const container = document.getElementById("root");
const root = createRoot(container);

const config = {
  initialColorMode: "system",
  useSystemColorMode: true,
};

const components = {
  Drawer: {
    variants: {
      clickThrough: {
        overlay: {
          pointerEvents: "none",
          background: "transparent",
        },
        dialogContainer: {
          pointerEvents: "none",
          background: "transparent",
        },
        dialog: {
          pointerEvents: "auto",
        },
      },
    },
  },
};

const theme = extendTheme({ config, components });

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <WebSocketProvider>
          <Router>
            <App />
          </Router>
        </WebSocketProvider>
      </AuthProvider>
    </ChakraProvider>
  </React.StrictMode>
);
