interface User {
  firstName: string;
  lastName: string;
  email: string;
  permissions: string[];
  groups: string[];
}

interface AuthState {
  isAuthenticated: boolean;
  user: User;
}

interface LoginAction {
  type: "LOGIN";
  payload: {
    user: User;
  };
}

interface LogoutAction {
  type: "LOGOUT";
}

type AuthAction = LoginAction | LogoutAction;

const initialState: AuthState = {
  isAuthenticated: false,
  user: {
    firstName: "",
    lastName: "",
    email: "",
    permissions: [],
    groups: []
  }
};

const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: initialState.user
      };
    default:
      return state;
  }
};

export { authReducer, initialState };

export type { AuthAction, AuthState, User };
