import { useState, useEffect } from "react";
import api from "../services/api";

const useFetchCompanyOrders = ({
  companyName,
  filterByStarted = true,
  hasStarted = false,
  orderIsNot
}) => {
  const [companyOrders, setCompanyOrders] = useState([]);
  const [isLoadingCompanyOrders, setIsLoadingCompanyOrders] = useState(false);

  useEffect(() => {
    const fetchCompanyOrders = async () => {
      setIsLoadingCompanyOrders(true);

      const filters = [
        { key: "companyName", operator: "is", value: companyName }
      ];

      if (filterByStarted) {
        filters.push({
          key: "hasStarted",
          operator: "is",
          value: hasStarted,
          filterByDateEntries: true
        });
      }

      if (orderIsNot) {
        filters.push({ key: "_id", operator: "is not", value: orderIsNot });
      }

      try {
        const response = await api("/api/orders/filter", "post", {
          body: filters,
          query: { includeAll: true }
        });

        if (response.status === "success") {
          setCompanyOrders(response.data.orders);
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        console.error("Failed to fetch company orders:", error);
      } finally {
        setIsLoadingCompanyOrders(false);
      }
    };

    if (companyName) {
      fetchCompanyOrders();
    }
  }, [companyName, filterByStarted, hasStarted]);

  return { companyOrders, isLoadingCompanyOrders };
};

export { useFetchCompanyOrders };
