import { Page } from "../../../components/Shared/Page";
import { useAPI } from "../../../hooks/useAPI";
import { useParams } from "react-router-dom";
import { PermissionsAccordion } from "../../../components/Administration/PermissionsAccordion";
import { IconDeviceFloppy } from "@tabler/icons-react";
import api from "../../../services/api";
import { FormControl, FormLabel, Input, useToast } from "@chakra-ui/react";

const EditGroup = () => {
  const { id } = useParams();

  const {
    data: group,
    setData: setGroup,
    isLoading,
    refresh,
  } = useAPI(`/api/administration/groups/${id}`);

  const toast = useToast();

  const setValue = (e) =>
    setGroup({ ...group, [e.target.name]: e.target.value });

  const setPermissions = (permissions) => setGroup({ ...group, permissions });

  const saveGroup = async () => {
    try {
      const { status } = await api(`/api/administration/groups/${id}`, "put", {
        body: group,
      });

      if (status === "success") {
        refresh();

        toast({
          status: "success",
          title: `${group.name} updated successfully`,
          isClosable: true,
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "An error occured",
        status: "error",
        description: error.data?.data?.message || error.message,
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Page
      title="Groups"
      buttons={[
        {
          text: "Save Changes",
          icon: IconDeviceFloppy,
          colorScheme: "blue",
          onClick: saveGroup,
        },
      ]}
      backButton={true}
    >
      <FormControl mb={2}>
        <FormLabel>Group Name</FormLabel>
        <Input value={group?.name || ""} onChange={setValue} name="name" />
      </FormControl>
      <FormLabel>Permissions</FormLabel>
      <PermissionsAccordion
        permissions={group?.permissions}
        setPermissions={setPermissions}
        isLoading={isLoading}
      />
    </Page>
  );
};

export { EditGroup };
