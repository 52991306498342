import { useState } from "react";
import api from "../../../services/api";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";

const CreateGroup = ({ isOpen, onClose, refresh }) => {
  const toast = useToast();
  const [groupData, setGroupData] = useState({ name: "", permissions: [] });

  const createGroup = async () => {
    try {
      const { status } = await api(`/api/administration/groups`, "post", {
        body: groupData,
      });

      if (status === "success") {
        refresh();

        onClose();

        setGroupData({ name: "", permissions: [] });

        toast({
          status: "success",
          title: `${groupData.name} created successfully`,
          isClosable: true,
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "An error occured",
        status: "error",
        description: error.data?.data?.message || error.message,
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottomWidth={"1px"}>Create Group</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={2}>
            <FormLabel>Name</FormLabel>
            <Input
              value={groupData.name}
              onChange={(e) =>
                setGroupData({ ...groupData, name: e.target.value })
              }
            />
          </FormControl>
        </ModalBody>
        <ModalFooter borderTopWidth={"1px"}>
          <Button colorScheme="blue" onClick={createGroup}>
            Create Group
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { CreateGroup };
