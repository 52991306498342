import { Editor } from "@monaco-editor/react";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Select,
  VStack,
  HStack,
  Input,
  Stack,
  useBreakpointValue,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { useAPI } from "../../hooks/useAPI";

const ScriptBody = ({ script, setScript }) => {
  if (!script) return null;

  const { data: fullScript } = useAPI(
    `/api/scripts/${script._id}`,
    "get",
    "",
    !script ? false : true
  );

  const direction = useBreakpointValue({ base: "column", md: "row" });

  return (
    <VStack spacing={4} p={4} w="100%">
      <Stack direction={direction} spacing={4} w="100%">
        <FormControl id="name" isRequired>
          <FormLabel>Script Name</FormLabel>
          <Input
            value={script.name}
            onChange={(e) => setScript({ ...script, name: e.target.value })}
          />
        </FormControl>
        <FormControl id="usage" isRequired>
          <FormLabel>Script Usage</FormLabel>
          <Select
            value={script.usage}
            onChange={(e) => setScript({ ...script, usage: e.target.value })}
          >
            <option value="exporter">Exporter</option>
          </Select>
        </FormControl>
        <FormControl id="language" isRequired>
          <FormLabel>Script Language</FormLabel>
          <Select
            value={script.language}
            onChange={(e) => setScript({ ...script, language: e.target.value })}
          >
            <option value="js">Javascript</option>
          </Select>
        </FormControl>
        <FormControl id="parser" isRequired>
          <FormLabel>Script Parser</FormLabel>
          <Select
            value={script.parser}
            onChange={(e) => setScript({ ...script, parser: e.target.value })}
          >
            <option value="none">None</option>
            <option value="csv2json">CSV -&gt; JSON</option>
            <option value="xml2json">XML -&gt; JSON</option>
          </Select>
        </FormControl>
      </Stack>
      <Alert borderRadius={4} status="warning" dismissible={true}>
        <AlertIcon />
        Your script must export a function that accepts one argument (the
        contents of the file), and it must return the contents to be saved to
        the new file
      </Alert>
      <Box w="100%" h="800px">
        <Editor
          language="javascript"
          value={fullScript?.code}
          onChange={(e) => setScript({ ...script, code: e })}
          height="100%"
          theme="vs-dark"
        />
      </Box>
    </VStack>
  );
};

export { ScriptBody };
