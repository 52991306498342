import { useEffect, useState } from "react";
import { useAPI } from "../../hooks/useAPI";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  Grid,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";

const PermissionsAccordion = ({ permissions, setPermissions, isLoading }) => {
  const { data: permissionCategories } = useAPI(
    `/api/administration/helpers/permissions`
  );

  const [selectedPermissions, setSelectedPermissions] = useState(
    permissions ?? []
  );
  const [warningPermission, setWarningPermission] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (!isLoading && permissions) {
      setSelectedPermissions(permissions);
    }
  }, [isLoading]);

  useEffect(() => {
    setPermissions(selectedPermissions);
  }, [selectedPermissions]);

  const handlePermissionChange = (permissionKey, isChecked, warning) => {
    if (warning && isChecked && !selectedPermissions.includes(permissionKey)) {
      setWarningPermission({ permissionKey, isChecked });
      onOpen();
    } else {
      updatePermissions(permissionKey, isChecked);
    }
  };

  const updatePermissions = (permissionKey, isChecked) => {
    if (isChecked && !selectedPermissions.includes(permissionKey)) {
      setSelectedPermissions((prev) => [...prev, permissionKey]);
    } else if (!isChecked && selectedPermissions.includes(permissionKey)) {
      setSelectedPermissions((prev) => prev.filter((p) => p !== permissionKey));
    }
  };

  const hasPermission = (permissionKey) => {
    return selectedPermissions.includes(permissionKey);
  };

  const confirmWarning = () => {
    if (warningPermission) {
      updatePermissions(
        warningPermission.permissionKey,
        warningPermission.isChecked
      );
      setWarningPermission(null);
    }
    onClose();
  };

  return (
    <>
      <Accordion>
        {permissionCategories?.map((category, index) => (
          <AccordionItem key={index}>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  {category.name}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Grid
                templateColumns={{
                  base: "repeat(2,1fr)",
                  sm: "repeat(3,1fr)",
                  md: "repeat(4,1fr)",
                  lg: "repeat(6,1fr)",
                }}
                gap={3}
              >
                {category.permissions.map((permission) => (
                  <Checkbox
                    key={permission.key}
                    isChecked={hasPermission(permission.key)}
                    onChange={(e) =>
                      handlePermissionChange(
                        permission.key,
                        e.target.checked,
                        permission.warning
                      )
                    }
                  >
                    {permission.name}
                  </Checkbox>
                ))}
              </Grid>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>

      {warningPermission && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Warning</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {
                permissionCategories
                  .flatMap((category) => category.permissions)
                  .find((p) => p.key === warningPermission.permissionKey)
                  ?.warning
              }
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="red" onClick={confirmWarning} mr={2}>
                Accept
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export { PermissionsAccordion };
