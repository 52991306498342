const { Button, IconButton, useBreakpointValue } = require("@chakra-ui/react");

const ResponsiveButton = ({
  text,
  Icon,
  isLoading = false,
  colorScheme,
  onClick,
  disabled = false,
}) => {
  const showText = useBreakpointValue({ base: false, md: true });

  return showText ? (
    <Button
      leftIcon={<Icon />}
      isLoading={isLoading}
      colorScheme={colorScheme}
      onClick={onClick}
      variant="outline"
      isDisabled={disabled}
    >
      {text}
    </Button>
  ) : (
    <IconButton
      w={"100%"}
      icon={<Icon />}
      isLoading={isLoading}
      colorScheme={colorScheme}
      aria-label={text}
      onClick={onClick}
      variant="outline"
      isDisabled={disabled}
    />
  );
};

export { ResponsiveButton };
