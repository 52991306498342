import { Flex, Text, Box } from "@chakra-ui/react";
import { IconCodeDots, IconFileSpreadsheet } from "@tabler/icons-react";

const icons = [
  {
    extension: ["xlsx", "csv"],
    icon: IconFileSpreadsheet,
  },
  {
    extension: ["prg"],
    icon: IconCodeDots,
  },
];

const AttachmentItem = ({ attachment, downloadAttachment }) => {
  const parts = attachment.fileName.split(".");
  const fileType = parts[parts.length - 1].toLowerCase();

  const IconComponent = icons.find((iconItem) =>
    iconItem.extension.includes(fileType)
  )?.icon;

  if (!IconComponent) {
    console.log(`No icon found for fileType: ${fileType}`);
  }

  return (
    <Box
      as="button"
      onClick={() => downloadAttachment(attachment)}
      p={2}
      border="1px solid"
      borderColor="gray.200"
      borderRadius="md"
      textAlign="center"
      width="100%"
      bg="white"
      _hover={{ bg: "gray.100" }}
    >
      <Flex justifyContent="center" alignItems="center">
        {IconComponent && (
          <IconComponent size={80} strokeWidth={2} color="currentColor" />
        )}
      </Flex>
      <Text mt={2} fontSize="sm">
        {attachment.fileName}
      </Text>
    </Box>
  );
};

export default AttachmentItem;
