import { Box, Heading, Text, Flex, Spacer } from "@chakra-ui/react";
import { DateTime } from "luxon";
import { IconClock, IconX, IconCheck } from "@tabler/icons-react";

const EmailList = ({ mailCache, setSelectedEmail }) => {
  // Function to select the icon based on state
  const getStatusIcon = (state) => {
    switch (state) {
      case "pending":
        return <IconClock />;
      case "failed":
        return <IconX color="red" />;
      case "success":
        return <IconCheck color="green" />;
      default:
        return null;
    }
  };

  return mailCache.map((item) => (
    <Box
      key={item._id} // Add a key for each item in the list
      cursor={"pointer"}
      borderRadius={8}
      padding={3}
      backgroundColor={"gray.100"}
      _hover={{ backgroundColor: "gray.200" }}
      onClick={(e) =>
        e.target.tagName !== "svg" &&
        e.target.tagName !== "BUTTON" &&
        setSelectedEmail(item._id)
      }
    >
      <Flex alignItems="center">
        <Box>
          <Heading size={"sm"}>{item.subject}</Heading>
          <Text fontSize={"sm"}>
            Received:{" "}
            {DateTime.fromISO(item.time).toLocaleString(
              DateTime.DATETIME_SHORT
            )}
          </Text>
        </Box>
        <Spacer />
        {getStatusIcon(item.state)}
      </Flex>
    </Box>
  ));
};

export { EmailList };
