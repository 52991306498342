import { useContext, useEffect, useState } from "react";
import api from "../services/api";
import { AuthContext } from "../contexts/AuthContext";
import { useToast } from "@chakra-ui/react";

interface UseAPIResult<T> {
  data: T | null;
  setData: React.Dispatch<React.SetStateAction<T | null>>;
  isLoading: boolean;
  refresh: () => void;
}

const useAPI = <T>(
  endpoint: string,
  method: "get" | "post" | "put" | "delete" | "patch" = "get",
  parameters: Record<string, any> = {},
  execute: boolean = true,
  headers: Record<string, string> = {},
  noError: boolean = false
): UseAPIResult<T> => {
  const { isAuthenticated } = useContext(AuthContext);
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [trigger, setTrigger] = useState(0);

  const toast = useToast();

  useEffect(() => {
    if (!execute || !isAuthenticated) return;

    const fetchData = async () => {
      try {
        setIsLoading(true);

        const response = await api(endpoint, method, parameters, headers);

        setData(response.data);
      } catch (error: any) {
        if (!noError)
          toast({
            title: "An error occurred",
            status: "error",
            description: error.data?.data?.message || error.message,
            isClosable: true,
            duration: 2000
          });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [
    endpoint,
    method,
    JSON.stringify(parameters),
    JSON.stringify(headers),
    trigger,
    execute,
    isAuthenticated
  ]);

  return {
    data,
    setData,
    isLoading,
    refresh: () => setTrigger((value) => value + 1)
  };
};

export { useAPI };
