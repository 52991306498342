import { createContext, useReducer, ReactNode, Dispatch, FC } from "react";
import {
  authReducer,
  AuthAction,
  initialState,
  AuthState
} from "../reducers/authReducer";

export interface AuthContextType extends AuthState {
  dispatch: Dispatch<AuthAction>;
}

export const AuthContext = createContext<AuthContextType>({
  ...initialState,
  dispatch: () => null
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: state.isAuthenticated,
        user: state.user,
        dispatch
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
