import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from "@chakra-ui/react";

const LockedDayModal = ({ isOpen, onClose, onBypass }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Warning</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Box>
          <Text mb={4}>
            The selected date is currently locked. Do you want to bypass it?
          </Text>
        </Box>
      </ModalBody>
      <ModalFooter>
        <Button colorScheme="yellow" onClick={onBypass} mr={2}>
          Accept
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export { LockedDayModal };
