import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Input,
  Tag,
  TagCloseButton,
  TagLabel,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";

const TagInput = ({ name, initialTags = [], setter, mb }) => {
  const [tags, setTags] = useState(initialTags);

  useEffect(() => {
    setter(tags);
  }, [tags]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const value = event.target.value.trim();
      if (value) {
        setTags((prev) => [...prev, value]);
        event.target.value = "";
      }
    }
  };

  const removeTag = (tagToRemove) => {
    setTags((prev) => prev.filter((tag) => tag !== tagToRemove));
  };

  return (
    <FormControl mb={mb}>
      <FormLabel>{name}</FormLabel>
      <Box
        borderWidth="1px"
        borderRadius="md"
        paddingLeft={"16px"}
        paddingRight={"16px"}
        d="flex"
        alignItems="center"
        flexWrap="wrap"
        h={"40px"}
      >
        {tags.map((tag, index) => (
          <Tag key={`${index}-${tag}`} mt={1.5} mr={1} size="md">
            <TagLabel>{tag}</TagLabel>
            <TagCloseButton onClick={() => removeTag(tag)} />
          </Tag>
        ))}
        <Input
          style={{ lineHeight: "35px" }}
          placeholder="Press enter to add..."
          onKeyDown={(event) => handleKeyDown(event)}
          variant="unstyled"
          width="auto"
          flexGrow={1}
          minW="20%"
        />
      </Box>
    </FormControl>
  );
};

export { TagInput };
