import { IconPlus, IconRefresh } from "@tabler/icons-react";
import { useEffect, useMemo, useState } from "react";
import CreateExporter from "../components/Exporters/CreateExporter";
import EmailBody from "../components/Exporters/EmailBody";
import "../styles/exporters.css";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { useAPI } from "../hooks/useAPI";
import {
  Box,
  Button,
  List,
  ListItem,
  Stack,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Page } from "../components/Shared/Page";
import { ExporterList } from "../components/Exporters/ExporterList";
import { EmailList } from "../components/Exporters/EmailList";

const ExportersPage = () => {
  useDocumentTitle("Exporters");

  const [exporter, setExporter] = useState(null);
  const [email, setEmail] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const direction = useBreakpointValue({ base: "column", md: "row" });
  const isMobile = useBreakpointValue({ base: true, md: false });

  const mailCacheText = useBreakpointValue({
    base: "No selection",
    xl: "No exporter is selected",
  });

  const {
    data: mailCache,
    isLoading: isLoadingMailCache,
    refresh: refreshMailCache,
  } = useAPI(
    `/api/exporters/${exporter?._id}/mail-cache`,
    "get",
    "",
    exporter ? true : false
  );

  const sortedMailCache = useMemo(() => {
    return (mailCache || []).sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
  }, [mailCache]);

  const {
    data: exporters,
    isLoading: isLoadingExporters,
    refresh,
  } = useAPI("/api/exporters");

  useEffect(() => {
    const email = mailCache && mailCache.find((x) => x._id === selectedEmail);

    setEmail(email);
  }, [selectedEmail]);

  return (
    <Page
      title="Exporters"
      buttons={[
        {
          text: "Refresh Exporters",
          icon: IconRefresh,
          onClick: refresh,
          isLoading: isLoadingExporters,
        },
        {
          text: "Refresh Mail Cache",
          icon: IconRefresh,
          onClick: refreshMailCache,
          isLoading: isLoadingMailCache,
        },
        {
          text: "New Exporter",
          icon: IconPlus,
          onClick: onOpen,
          colorScheme: "blue",
        },
      ]}
      cardBodyPadding={0}
    >
      <CreateExporter isOpen={isOpen} onClose={onClose} refresh={refresh} />
      <Stack
        direction={direction}
        spacing={0}
        overflow={"hidden"}
        h={isMobile ? "100%" : "calc(100vh - 217px)"}
      >
        <Box
          width={isMobile ? "100%" : "20%"}
          borderRight={!isMobile && "1px"}
          borderBottom={isMobile && "1px"}
          borderColor={"gray.200"}
          h={"100%"}
          overflow={"scroll"}
        >
          <List spacing={2} padding={2}>
            {exporters &&
              (exporters.length === 0 ? (
                <ListItem key={0}>
                  <Button width="100%">No exporters are available</Button>
                </ListItem>
              ) : (
                <ExporterList
                  exporters={exporters}
                  selectedExporter={exporter}
                  setSelectedExporter={setExporter}
                  refresh={refresh}
                />
              ))}
          </List>
        </Box>
        <Box
          width={isMobile ? "100%" : "20%"}
          borderRight={!isMobile && "1px"}
          borderBottom={isMobile && "1px"}
          borderColor={"gray.200"}
          h={"100%"}
          overflow={"scroll"}
        >
          <List spacing={2} p={2}>
            {!mailCache ? (
              <ListItem key={0}>
                <Button width="100%">{!exporter && mailCacheText}</Button>
              </ListItem>
            ) : (
              <EmailList
                mailCache={sortedMailCache}
                setSelectedEmail={setSelectedEmail}
              />
            )}
          </List>
        </Box>
        <Box width={isMobile ? "100%" : "60%"} h={"100%"} overflow={"scroll"}>
          <EmailBody data={email} />
        </Box>
      </Stack>
    </Page>
  );
};

export default ExportersPage;
