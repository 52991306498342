import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Alert,
  AlertIcon,
  AlertTitle,
  Textarea,
} from "@chakra-ui/react";

export const DateEntryMoved = ({ isOpen, onClose, event }) => {
  return (
    <Modal size="5xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottomWidth={"1px"}>
          <Flex alignItems="center" justifyContent="space-between">
            Date Entry Moved
            <ModalCloseButton onClick={onClose} />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Grid templateColumns="repeat(2, 1fr)" gap={2} mb={4}>
            <GridItem>
              <FormControl>
                <FormLabel>Order ID</FormLabel>
                <Input readOnly value={event.orderID} />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>Status</FormLabel>
                <Input readOnly value={event.status} />
              </FormControl>
            </GridItem>
          </Grid>
          <Box>
            <FormControl mb={4}>
              <FormLabel>Type</FormLabel>
              <Input readOnly value={event.moveData.type} />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Quantity</FormLabel>
              <Input readOnly value={event.moveData.quantity} />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Original Start Date</FormLabel>
              <Input readOnly value={event.moveData.originalStartDate} />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Updated Start Date</FormLabel>
              <Input readOnly value={event.moveData.updatedStartDate} />
            </FormControl>
          </Box>
          {event.error && (
            <Box mt={4}>
              <Alert status="error">
                <AlertIcon />
                <Box flex="1">
                  <AlertTitle>Error Details</AlertTitle>
                  <Box>
                    <FormControl>
                      <FormLabel>Error Message</FormLabel>
                      <Input readOnly value={event.error} />
                    </FormControl>
                    {event.errorStack && (
                      <FormControl mt={2}>
                        <FormLabel>Error Stack</FormLabel>
                        <Input readOnly value={event.errorStack} />
                      </FormControl>
                    )}
                    {event.errorDetails && (
                      <FormControl mt={2}>
                        <FormLabel>Error Details</FormLabel>
                        <Textarea readOnly value={event.errorDetails} />
                      </FormControl>
                    )}
                  </Box>
                </Box>
              </Alert>
            </Box>
          )}
        </ModalBody>
        <ModalFooter borderTopWidth={"1px"}>
          <Button colorScheme="blue" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
