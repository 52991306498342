import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  useToast,
} from "@chakra-ui/react";
import api from "../../../services/api";

const CreateIntegration = ({ isOpen, onClose, integrations }) => {
  const toast = useToast();
  const [integrationType, setIntegrationType] = useState("");
  const [integrationFields, setIntegrationFields] = useState({});

  const handleIntegrationTypeChange = (event) => {
    setIntegrationType(event.target.value);
  };

  const handleFieldChange = (fieldName, value) => {
    setIntegrationFields((prevFields) => ({
      ...prevFields,
      [fieldName]: value,
    }));
  };

  const handleCreateIntegration = async () => {
    try {
      const { status } = await api("/api/administration/integrations", "post", {
        body: { name: integrationType, connectionInfo: integrationFields },
      });

      if (status === "success") {
        onClose();

        toast({
          status: "success",
          title: `${integrationType} integration created successfully`,
          isClosable: true,
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "An error occured",
        status: "error",
        description: error.data?.data?.message || error.message,
        duration: 2000,
        isClosable: true,
      });
    }
    onClose();
  };

  const selectedIntegrationType = integrations?.find(
    (type) => type.name === integrationType
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Integration</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={2}>
            <FormLabel>Integration Type</FormLabel>
            <Select
              placeholder="Select integration type"
              value={integrationType}
              onChange={handleIntegrationTypeChange}
            >
              {integrations?.map((type) => (
                <option key={type.name} value={type.name}>
                  {type.name}
                </option>
              ))}
            </Select>
          </FormControl>
          {selectedIntegrationType && (
            <>
              {selectedIntegrationType.requiredFields.map((field) => (
                <FormControl mb={2} key={field.id}>
                  <FormLabel>{field.name}</FormLabel>
                  <Input
                    type="text"
                    id={field.id}
                    value={integrationFields[field.id] || ""}
                    onChange={(event) =>
                      handleFieldChange(field.id, event.target.value)
                    }
                  />
                </FormControl>
              ))}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleCreateIntegration}>
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { CreateIntegration };
