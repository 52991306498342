import { useState, useEffect } from "react";
import api from "../../services/api";
import { useToast } from "@chakra-ui/react";
import { DateTime } from "luxon";
import {
  IOrder,
  ILeanSavedAttribute,
  ILimit,
  IOrderFlag,
  IDateEntry,
  IProductAttribute
} from "truflow";

const useFetchRows = (setIsLoading: (isLoading: boolean) => void) => {
  const toast = useToast();
  const [rows, setRows] = useState<any[]>([]);
  const [activeRow, setActiveRow] = useState<{ id: string | null }>({
    id: null
  });
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    const fetchRows = async () => {
      try {
        setIsLoading(true);

        const {
          data: { orders }
        }: { data: { orders: IOrder[] } } = await api(
          "/api/orders/filter",
          "post",
          {
            body: [
              {
                key: "hasStarted",
                operator: "is",
                value: false,
                filterByDateEntries: true
              }
            ],
            query: { includeAll: true }
          }
        );

        let savedAttributes: ILeanSavedAttribute[] | null;

        try {
          const response = await api(
            "/api/allmoxy/products/attributes/saved/",
            "get"
          );

          savedAttributes = response.data;
        } catch (error: any) {
          if (error.statusCode === 401) {
            savedAttributes = null;
          } else {
            throw error;
          }
        }

        const { data: limits }: { data: ILimit[] } = await api(
          "/api/settings/limits",
          "get"
        );

        const { data: orderFlags }: { data: IOrderFlag[] } = await api(
          "/api/settings/order-flags",
          "get"
        );

        const rows = orders.reduce((acc: any[], order) => {
          const orderRows = order.dateEntries.map(
            ({
              _id,
              quantity,
              type,
              startDate,
              highlight,
              limitsGroup,
              allmoxyData
            }: IDateEntry) => {
              const { rpd } = limits.find((x) => x.name === limitsGroup) || {
                rpd: 0
              };

              const transformedAttributes =
                (savedAttributes &&
                  allmoxyData
                    ?.productAttributes!.map((attribute: IProductAttribute) => {
                      const savedAttribute =
                        savedAttributes.find(
                          (attr) =>
                            attr.attributeId.toString() ===
                            attribute.attributeId.toString()
                        ) || null;

                      if (!savedAttribute) return;

                      const { displayName, displayOn } = savedAttribute;

                      return {
                        ...attribute,
                        displayName,
                        displayOn
                      };
                    })
                    .filter((attr) => attr !== undefined)) ||
                null;

              return {
                id: _id,
                orderID: order._id,
                name: order.name,
                companyName: order.companyName,
                quantity,
                type,
                startDate,
                shipDate: DateTime.fromISO(order.shipDate).toISODate(),
                highlight,
                rpd,
                highlightColor:
                  orderFlags.find(
                    (flag) =>
                      flag.products?.includes(type) ||
                      flag.tags?.some(
                        (tag) =>
                          order.orderTags?.includes(tag) ||
                          order.companyTags?.includes(tag)
                      )
                  )?.highlightColor ?? null,
                allmoxyData:
                  transformedAttributes?.length! > 0
                    ? transformedAttributes
                    : null
              };
            }
          );
          return [...acc, ...orderRows];
        }, []);

        rows.sort((a, b) => parseInt(a.orderID) - parseInt(b.orderID));

        setRows(rows);
      } catch (error: any) {
        toast({
          title: "An error occurred",
          description: error.response?.data?.message || error.message,
          status: "error",
          isClosable: true,
          duration: 5000
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchRows();
  }, [refresh, setIsLoading, toast]);

  return { rows, activeRow, setActiveRow, refresh, setRefresh };
};

export default useFetchRows;
