import { Page } from "../../components/Shared/Page";
import { useRef, useState } from "react";
import api from "../../services/api";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  FormControl,
  Heading,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";

const OCR = () => {
  useDocumentTitle("OCR");

  const fileInputRef = useRef(null);
  const toast = useToast();
  const [validated, setValidated] = useState(false);
  const [output, setOutput] = useState(null);
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => setFile(e.target.files[0]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      setValidated(false);

      const formData = new FormData();
      formData.append("image", file);

      try {
        const response = await api("/api/utilities/ocr/extract-table", "post", {
          formData,
        });

        setOutput(JSON.stringify(response.data, null, 2));
      } catch (error) {
        toast({
          title: "An error occured",
          status: "error",
          description: error.data?.data?.message || error.message,
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Page title="OCR">
      <Stack direction={{ base: "column", md: "row" }}>
        <Box width={"20%"}>
          <Card>
            <CardHeader borderBottomWidth={"1px"}>
              <Heading size={"sm"}>Input</Heading>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <FormControl isInvalid={validated}>
                  <Button
                    mb={2}
                    width={"100%"}
                    variant={"outline"}
                    onClick={() => fileInputRef.current.click()}
                  >
                    {!file ? "Upload File" : file.name}
                  </Button>
                  <Input
                    ref={fileInputRef}
                    type="file"
                    display={"none"}
                    required
                    onChange={handleFileChange}
                  />
                </FormControl>
                <Button type="submit" width={"100%"} colorScheme="blue">
                  Submit
                </Button>
              </form>
            </CardBody>
          </Card>
        </Box>
        <Box width={"80%"}>
          <Card>
            <CardHeader borderBottomWidth={"1px"}>
              <Heading size={"sm"}>Output</Heading>
            </CardHeader>
            <CardBody>{output}</CardBody>
          </Card>
        </Box>
      </Stack>
    </Page>
  );
};

export default OCR;
