import {
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useBreakpointValue,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Flex,
  useToast,
  ModalFooter,
  Switch
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import { useState, useEffect, useCallback } from "react";
import api from "../../services/api";
import { LockedDayModal } from "../Modals/LockedDayModal";

const OrderDetailModal = ({ order, isOpen, onClose, refresh }) => {
  const direction = useBreakpointValue({ base: "column", md: "row" });
  const [editableOrder, setEditableOrder] = useState(order);
  const [isLoading, setIsLoading] = useState(false);
  const [isLockedDayModalOpen, setIsLockedDayModalOpen] = useState(false);
  const [skipLockedDay, setSkipLockedDay] = useState(false);
  const toast = useToast();

  useEffect(() => {
    setEditableOrder(order);
  }, [order]);

  const handleSave = useCallback(async () => {
    setIsLoading(true);
    try {
      const updatedOrder = {
        ...editableOrder,
        dateEntries: editableOrder.dateEntries.map((entry) => ({
          ...entry,
          startDate: entry.startDate
        })),
        skipLockedDay
      };

      const response = await api(
        "/api/orders/update/multiple-start-date",
        "PUT",
        { body: updatedOrder }
      );

      if (response.status === "success") {
        toast({
          title: "Order updated.",
          description: "The order details have been updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true
        });

        refresh();

        setSkipLockedDay(false);

        onClose();
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      if (error?.data?.message === "That date is currently locked") {
        setIsLockedDayModalOpen(true);
      } else {
        toast({
          title: "An error occurred.",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, [editableOrder, skipLockedDay, toast, refresh, onClose]);

  const handleCancel = () => {
    setEditableOrder(order);
    onClose();
  };

  const handleInputChange = (field, value) => {
    setEditableOrder((prev) => ({ ...prev, [field]: value }));
  };

  const handleDateEntryChange = (index, field, value) => {
    const updatedDateEntries = editableOrder.dateEntries.map((entry, idx) => {
      if (idx === index) {
        return { ...entry, [field]: value };
      }
      return entry;
    });
    setEditableOrder((prev) => ({ ...prev, dateEntries: updatedDateEntries }));
  };

  const handleToggleChange = (index) => {
    const updatedDateEntries = editableOrder.dateEntries.map((entry, idx) => {
      if (idx === index) {
        return { ...entry, hasStarted: !entry.hasStarted };
      }
      return entry;
    });
    setEditableOrder((prev) => ({ ...prev, dateEntries: updatedDateEntries }));
  };

  const handleBypassLockedDay = () => {
    setSkipLockedDay(true);
    setIsLockedDayModalOpen(false);
  };

  useEffect(() => {
    if (skipLockedDay) {
      handleSave();
    }
  }, [skipLockedDay, handleSave]);

  return (
    <>
      <Modal size="6xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Order Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack mb={4} direction={direction}>
              <FormControl>
                <FormLabel>Order ID</FormLabel>
                <Input type="text" readOnly value={editableOrder?._id} />
              </FormControl>
              <FormControl>
                <FormLabel>Company Name</FormLabel>
                <Input
                  type="text"
                  readOnly
                  value={editableOrder?.companyName}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Job Name</FormLabel>
                <Input type="text" readOnly value={editableOrder?.name} />
              </FormControl>
            </Stack>
            <Stack mb={4} direction={direction}>
              <FormControl>
                <FormLabel>Ship Date</FormLabel>
                <Input
                  type="date"
                  value={DateTime.fromISO(editableOrder?.shipDate).toISODate()}
                  onChange={(e) =>
                    handleInputChange("shipDate", e.target.value)
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Total Pieces</FormLabel>
                <Input
                  type="text"
                  readOnly
                  value={editableOrder?.dateEntries?.reduce(
                    (total, e) => total + e.quantity,
                    0
                  )}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Pieces Started</FormLabel>
                <Input
                  type="text"
                  readOnly
                  value={editableOrder?.dateEntries?.reduce(
                    (total, e) => (e.hasStarted ? total + e.quantity : total),
                    0
                  )}
                />
              </FormControl>
            </Stack>
            <Stack mb={4} direction={direction}>
              <FormControl>
                <FormLabel>Order Tags</FormLabel>
                <Input type="text" readOnly value={editableOrder?.orderTags} />
              </FormControl>
              <FormControl>
                <FormLabel>Company Tags</FormLabel>
                <Input
                  type="text"
                  readOnly
                  value={editableOrder?.companyTags}
                />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalBody>
            <Box mb={4} borderWidth="1px" borderRadius="md" overflow="hidden">
              <Table variant="striped" colorScheme="gray" size="sm">
                <Thead>
                  <Tr>
                    <Th>Type</Th>
                    <Th>Quantity</Th>
                    <Th>Start Date</Th>
                    <Th>Category</Th>
                    <Th>Started</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {editableOrder?.dateEntries &&
                    editableOrder.dateEntries.map((dateEntry, index) => (
                      <Tr key={index}>
                        <Td>{dateEntry.type}</Td>
                        <Td>{dateEntry.quantity}</Td>
                        <Td>
                          <Input
                            type="date"
                            value={dateEntry.startDate}
                            onChange={(e) =>
                              handleDateEntryChange(
                                index,
                                "startDate",
                                e.target.value
                              )
                            }
                          />
                        </Td>
                        <Td>{dateEntry.limitsGroup}</Td>
                        <Td>
                          <Switch
                            isChecked={dateEntry.hasStarted}
                            onChange={() => handleToggleChange(index)}
                          />
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                colorScheme="blue"
                mr={3}
                onClick={handleSave}
                isLoading={isLoading}
              >
                Save
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <LockedDayModal
        isOpen={isLockedDayModalOpen}
        onClose={() => setIsLockedDayModalOpen(false)}
        onBypass={handleBypassLockedDay}
      />
    </>
  );
};

export { OrderDetailModal };
