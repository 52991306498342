import { Button, FormControl, FormLabel, Textarea } from "@chakra-ui/react";

const CSVEditor = ({ csvOutput, handleCSVInputChange }) => {
  return (
    <>
      <FormControl mb={2}>
        <FormLabel>CSV Editor</FormLabel>
        <Textarea rows={3} value={csvOutput} onChange={handleCSVInputChange} />
      </FormControl>
      <Button
        w="100%"
        colorScheme="blue"
        onClick={() => navigator.clipboard.writeText(csvOutput)}
      >
        Copy to Clipboard
      </Button>
    </>
  );
};

export default CSVEditor;
