import { useEffect, useRef, useState } from "react";
import api from "../services/api";
import Pagination from "../components/Shared/Pagination";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { OrderDetailModal } from "../components/Orders/OrderDetailModal";
import {
  Box,
  Button,
  CardBody,
  Flex,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuList,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spacer,
  Text,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { ChevronDownIcon, AddIcon, CloseIcon } from "@chakra-ui/icons";
import { ResponsiveTable } from "../components/Orders/ResponsiveTable";
import { Page } from "../components/Shared/Page";
import {
  IconFileSpreadsheet,
  IconFilter,
  IconRefresh,
  IconSearch,
  IconX
} from "@tabler/icons-react";

const Orders = () => {
  useDocumentTitle("Orders");

  const toast = useToast();
  const searchInputRef = useRef(null);
  const searchContainerRef = useRef(null);

  const keys = [
    [
      { name: "Order ID", id: "_id" },
      { name: "Job Name", id: "name" },
      { name: "Company Name", id: "companyName" },
      { name: "Ship Date", id: "shipDate" },
      { name: "Requested Ship Date", id: "requestedShipDate" },
      { name: "Order Tags", id: "orderTags" },
      { name: "Company Tags", id: "companyTags" }
    ],
    [
      { name: "Entry ID", id: "_id" },
      { name: "Quantity", id: "quantity" },
      { name: "Type", id: "type" },
      { name: "Limits Group", id: "limitsGroup" },
      { name: "Start Date", id: "startDate" },
      { name: "Has Started", id: "hasStarted" }
    ]
  ];

  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState([
    { key: "_id", operator: "is", value: "", filterByDateEntries: false }
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const downloadWipReport = async () => {
    try {
      const response = await api(
        "/api/orders/export/start-date",
        "GET",
        "",
        "",
        true
      );
      const url = window.URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = url;
      link.download = "WIP Report.csv";
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
    } catch (error) {
      toast({
        title: "An error occurred",
        status: "error",
        duration: 2000,
        isClosable: true,
        description: error.data?.data?.message || error.message
      });
    }
  };

  const fetchOrders = async () => {
    setIsLoading(true);

    const hasFilters = filters.some((filter) => filter.value !== "");

    try {
      const endpoint = hasFilters ? `/api/orders/filter` : `/api/orders`;
      const method = hasFilters ? "post" : "get";
      const options = hasFilters
        ? { body: filters, query: { page, limit } }
        : { query: { page, limit } };

      const { data } = await api(endpoint, method, options);
      setOrders(data.orders);
      setTotalPages(data.pagination.totalPages);
      setTotalOrders(data.pagination.total);
    } catch (error) {
      toast({
        title: "An error occurred",
        status: "error",
        duration: 2000,
        isClosable: true,
        description: error.data?.data?.message || error.message
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [filters, limit, page]);

  useEffect(() => {
    if (isSearchVisible && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isSearchVisible]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setIsSearchVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const addFilter = () => {
    setFilters([
      ...filters,
      { key: "_id", operator: "is", value: "", filterByDateEntries: false }
    ]);
  };

  const updateFilter = (index, key, value) => {
    const updatedFilters = [...filters];
    updatedFilters[index][key] = value;
    setFilters(updatedFilters);
  };

  const removeFilter = (index) => {
    const updatedFilters = filters.filter((_, i) => i !== index);
    setFilters(updatedFilters);
  };

  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
    setSearchValue("");
    setFilters([{ level: 0, key: "_id", operator: "is", value: "" }]);
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    updateFilter(0, "value", e.target.value);
  };

  return (
    <Page
      title="Orders"
      buttons={[
        {
          text: "WIP Report",
          icon: IconFileSpreadsheet,
          onClick: downloadWipReport
        },
        {
          text: "Refresh",
          icon: IconRefresh,
          onClick: () => fetchOrders(),
          colorScheme: "blue",
          isLoading: isLoading
        }
      ]}
      cardBodyPadding={0}
      extraContent={
        <Flex flexDirection={{ base: "column", md: "row" }} alignItems="center">
          {isSearchVisible ? (
            <InputGroup width="250px" ref={searchContainerRef} mr={2}>
              <InputLeftElement pointerEvents="none">
                <IconSearch width="20px" />
              </InputLeftElement>
              <Input
                type="number"
                ref={searchInputRef}
                placeholder="Search by Order #"
                value={searchValue}
                onChange={handleSearchChange}
              />
              <InputRightElement cursor={"pointer"} onClick={toggleSearch}>
                <IconX />
              </InputRightElement>
            </InputGroup>
          ) : (
            <Button
              leftIcon={<IconSearch width="20px" />}
              onClick={toggleSearch}
              aria-label="Toggle Search"
              mr={2}
              minWidth={"100px"}
            >
              Search
            </Button>
          )}
          <Menu>
            <MenuButton
              as={Button}
              leftIcon={<IconFilter width="20px" />}
              rightIcon={<ChevronDownIcon />}
              borderRadius="md"
              boxShadow="sm"
              mr={2}
              minWidth={"125px"}
            >
              Filters
            </MenuButton>
            <MenuList borderRadius="md" boxShadow="md">
              <Box pl={4} pr={4}>
                <HStack alignItems="center" mb={4}>
                  <Text fontSize="md" fontWeight="bold">
                    Advanced Filters
                  </Text>
                  <Spacer />
                  <Button
                    size="sm"
                    onClick={() =>
                      setFilters([
                        {
                          key: "_id",
                          operator: "is",
                          value: "",
                          filterByDateEntries: false
                        }
                      ])
                    }
                    aria-label="Clear Filters"
                    variant="outline"
                  >
                    Clear Filters
                  </Button>
                  <IconButton
                    icon={<AddIcon />}
                    size="sm"
                    onClick={addFilter}
                    aria-label="Add Filter"
                    variant="outline"
                  />
                </HStack>
                {filters.map((filter, index) => (
                  <Flex key={index} alignItems="center" mb={2}>
                    <Select
                      size="sm"
                      width="140px"
                      value={filter.filterByDateEntries ? "1" : "0"}
                      onChange={(e) =>
                        updateFilter(
                          index,
                          "filterByDateEntries",
                          e.target.value === "1"
                        )
                      }
                      mr={2}
                      borderRadius="md"
                    >
                      <option value="0">Order Details</option>
                      <option value="1">Date Entries</option>
                    </Select>
                    <Select
                      size="sm"
                      width="140px"
                      value={filter.key}
                      onChange={(e) =>
                        updateFilter(index, "key", e.target.value)
                      }
                      mr={2}
                      borderRadius="md"
                    >
                      {keys[filter.filterByDateEntries ? 1 : 0].map((key) => (
                        <option key={key.id} value={key.id}>
                          {key.name}
                        </option>
                      ))}
                    </Select>
                    <Select
                      size="sm"
                      width="140px"
                      value={filter.operator}
                      onChange={(e) =>
                        updateFilter(index, "operator", e.target.value)
                      }
                      mr={2}
                      borderRadius="md"
                    >
                      <option value="is">is</option>
                      <option value="is not">is not</option>
                      <option value="includes">includes</option>
                    </Select>
                    {filter.key === "hasStarted" ? (
                      <Select
                        size="sm"
                        width="140px"
                        value={filter.value}
                        onChange={(e) =>
                          updateFilter(index, "value", e.target.value)
                        }
                        mr={2}
                        borderRadius="md"
                      >
                        <option value="true">true</option>
                        <option value="false">false</option>
                      </Select>
                    ) : (
                      <Input
                        type="text"
                        size="sm"
                        width="140px"
                        value={filter.value}
                        onChange={(e) =>
                          updateFilter(index, "value", e.target.value)
                        }
                        mr={2}
                        borderRadius="md"
                      />
                    )}
                    <IconButton
                      icon={<CloseIcon />}
                      size="sm"
                      onClick={() => removeFilter(index)}
                      aria-label="Remove Filter"
                      variant="ghost"
                    />
                  </Flex>
                ))}
              </Box>
            </MenuList>
          </Menu>
          <InputGroup>
            <InputLeftAddon>Limit</InputLeftAddon>
            <NumberInput
              value={limit}
              onChange={(valueString) => setLimit(parseInt(valueString))}
              width="110px"
            >
              <NumberInputField borderLeftRadius={0} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </InputGroup>
        </Flex>
      }
    >
      <OrderDetailModal
        order={order}
        isOpen={isOpen}
        onClose={onClose}
        refresh={() => fetchOrders()}
      />
      <ResponsiveTable orders={orders} onOpen={onOpen} setOrder={setOrder} />
      <CardBody>
        <Flex flexDirection={{ base: "column", md: "row" }}>
          <HStack
            marginBottom={{ base: 4, md: 0 }}
            alignItems="center"
            justifyContent="center"
          >
            <Text>
              Showing {page * limit - limit + 1} to{" "}
              {Math.min(page * limit, totalOrders)} of {totalOrders} entries
            </Text>
          </HStack>
          <Spacer />
          <HStack
            alignItems="center"
            justifyContent="center"
            flexWrap={{ base: "wrap", md: "nowrap" }}
          >
            <Pagination
              totalPages={totalPages}
              currentPage={page}
              onPageChange={setPage}
            />
          </HStack>
        </Flex>
      </CardBody>
    </Page>
  );
};

export default Orders;
