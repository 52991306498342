import { useContext, useEffect, useState } from "react";
import logo from "../logo.png";
import { AuthContext } from "../contexts/AuthContext";
import api from "../services/api";
import { NavLink, useNavigate } from "react-router-dom";
import { useDocumentTitle } from "../hooks/useDocumentTitle";

("use client");

import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Link,
  Alert,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const Register = () => {
  useDocumentTitle("Register");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState({ first: "", last: "" });
  const [error, setError] = useState({ class: "", message: "" });
  const [showPassword, setShowPassword] = useState(false);
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name.first || !name.last || !password || !confirmPassword || !email)
      return setError({
        class: "danger",
        message: "Not all necessary details were provided",
      });

    try {
      const response = await api("/api/auth/register", "POST", {
        body: {
          firstName: name.first,
          lastName: name.last,
          password,
          confirmPassword,
          email,
        },
      });

      // If the response contains an error
      if (response.status === "error") {
        setError({
          class: "danger",
          message: response.data.message,
        });
      }
      // If the response is successful and the user is created
      else if (response.status === "success") {
        navigate("/login");
      }
    } catch (error) {
      // Handle any errors that occurred while making the request
      setError({
        class: "danger",
        message: error.data?.data?.message || error.message,
      });
    }
  };

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} width={"xl"} maxW={"xl"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"} textAlign={"center"}>
            Sign up
          </Heading>
        </Stack>{" "}
        {error.message && (
          <Stack align={"center"}>
            <Alert status="error" rounded={"lg"}>
              <AlertIcon />
              <AlertTitle>{error.message}</AlertTitle>
            </Alert>
          </Stack>
        )}
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <form onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <HStack>
                <Box w={"100%"}>
                  <FormControl id="firstName" isRequired>
                    <FormLabel>First Name</FormLabel>
                    <Input
                      type="text"
                      placeholder="John"
                      onChange={(e) =>
                        setName({ ...name, first: e.target.value })
                      }
                    />
                  </FormControl>
                </Box>
                <Box w={"100%"}>
                  <FormControl id="lastName" isRequired>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                      type="text"
                      placeholder="Doe"
                      onChange={(e) =>
                        setName({ ...name, last: e.target.value })
                      }
                    />
                  </FormControl>
                </Box>
              </HStack>
              <FormControl id="email" isRequired>
                <FormLabel>Email address</FormLabel>
                <Input
                  type="email"
                  placeholder="Enter email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl id="password" isRequired>
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputRightElement h={"full"}>
                    <Button
                      variant={"ghost"}
                      onClick={() =>
                        setShowPassword((showPassword) => !showPassword)
                      }
                    >
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl id="password" isRequired>
                <FormLabel>Confirm Password</FormLabel>
                <Input
                  type="password"
                  placeholder="Enter password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </FormControl>
              <Stack spacing={10} pt={2}>
                <Button
                  loadingText="Submitting"
                  size="lg"
                  bg={"blue.400"}
                  color={"white"}
                  type="submit"
                  _hover={{
                    bg: "blue.500",
                  }}
                >
                  Sign up
                </Button>
              </Stack>
              <Stack>
                <Text align={"center"}>
                  Already a user?{" "}
                  <Link as={NavLink} color={"blue.400"} to="/login">
                    Login
                  </Link>
                </Text>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};

export { Register };
