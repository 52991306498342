import { useEffect, useState } from "react";
import { Page } from "../components/Shared/Page";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { useAPI } from "../hooks/useAPI";
import { ResponsiveTable } from "../components/Shared/Table";
import { IconRefresh, IconSettings } from "@tabler/icons-react";
import { OrderMoved } from "../components/EventLog/Modals/OrderMoved";
import { OrderProcessed } from "../components/EventLog/Modals/OrderProcessed";
import { DateEntryMoved } from "../components/EventLog/Modals/DateEntryMoved";
import { RequestError } from "../components/EventLog/Modals/RequestError";

const EventLog = () => {
  useDocumentTitle("Event Log");
  const [event, setEvent] = useState({ _id: null });

  const {
    data: eventData,
    refresh: refreshEvent,
    isLoading: isLoadingEvent,
  } = useAPI(`/api/event-log/${event._id}`, "get", {}, event._id !== null);

  const { data: eventLog, refresh, isLoading } = useAPI("/api/event-log");

  const loadEventDetails = (event) => {
    setEvent({ _id: event._id });
  };

  const [columns, setColumns] = useState([
    { id: "time", text: "Time", status: true, type: "text" },
    { id: "orderID", text: "Order #", status: true, type: "text" },
    { id: "_type", text: "Type", status: true, type: "text" },
    { id: "status", text: "Status", status: true, type: "text" },
    {
      id: "button",
      text: "",
      status: true,
      type: "button",
      button: {
        text: "View Event Details",
        size: "xs",
        colorScheme: "blue",
        onClick: loadEventDetails,
        isLoading: isLoadingEvent,
      },
      noFilter: true,
    },
  ]);

  const toggleColumnVisibility = (columnId) => {
    setColumns((prevState) =>
      prevState.map((col) =>
        col.id === columnId ? { ...col, status: !col.status } : col
      )
    );
  };

  const columnSettings = columns.map((column) => ({
    label: column.text,
    isChecked: column.status,
    onChange: () => toggleColumnVisibility(column.id),
  }));

  return (
    <Page
      title="Event Log"
      buttons={[
        {
          text: "Refresh",
          icon: IconRefresh,
          isLoading: isLoading,
          onClick: refresh,
          colorScheme: "blue",
        },
      ]}
      dropdowns={[
        {
          label: "Show/Hide Columns",
          icon: <IconSettings />,
          items: columnSettings,
        },
      ]}
      cardBodyPadding="0"
    >
      <ResponsiveTable
        rows={eventLog}
        columns={columns}
        setActiveRow={() => null}
        activeRow=""
        idSelector="_id"
      />

      {eventData && eventData._type === "orderMoved" && (
        <OrderMoved
          isOpen={!!event._id}
          onClose={() => setEvent({ _id: null })}
          event={eventData}
        />
      )}
      {eventData && eventData._type === "orderProcessed" && (
        <OrderProcessed
          isOpen={!!event._id}
          onClose={() => setEvent({ _id: null })}
          event={eventData}
        />
      )}
      {eventData && eventData._type === "dateEntryMoved" && (
        <DateEntryMoved
          isOpen={!!event._id}
          onClose={() => setEvent({ _id: null })}
          event={eventData}
        />
      )}
      {eventData && eventData._type === "requestError" && (
        <RequestError
          isOpen={!!event._id}
          onClose={() => setEvent({ _id: null })}
          event={eventData}
        />
      )}
    </Page>
  );
};

export { EventLog };
