import { IconCalendar, IconRefresh, IconSettings } from "@tabler/icons-react";
import useFetchRows from "../hooks/scheduling/useFetchRows";
import ChangeStartDate from "../components/Modals/ChangeStartDate";
import { useEffect, useState } from "react";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { useDisclosure } from "@chakra-ui/react";
import { ResponsiveTable } from "../components/Shared/Table";
import { Page } from "../components/Shared/Page";
import { useWebSocket } from "../contexts/WebsocketContext";

export default () => {
  useDocumentTitle("Scheduling");

  const [isLoading, setIsLoading] = useState(false);
  const { rows, activeRow, setActiveRow, refresh, setRefresh } =
    useFetchRows(setIsLoading);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [startDate, setStartDate] = useState(null);

  const [columns, setColumns] = useState([
    { id: "orderID", text: "Order #", status: true },
    { id: "name", text: "Job Name", status: true },
    { id: "companyName", text: "Company", status: true },
    { id: "type", text: "Type", status: true, displayAttrs: true },
    { id: "quantity", text: "Quantity", status: true },
    { id: "startDate", text: "Start Date", status: true },
    { id: "shipDate", text: "Ship Date", status: true },
  ]);

  const handleChangeStartDateClick = () => {
    setStartDate(activeRow.startDate);
    onOpen();
  };

  const socket = useWebSocket();

  useEffect(() => {
    if (!socket) return;

    socket.on("orderAdded", () =>
      setRefresh((currentRefresh) => currentRefresh + 1)
    );
  }, [socket]);

  const toggleColumnVisibility = (columnId) => {
    setColumns((prevState) =>
      prevState.map((col) =>
        col.id === columnId ? { ...col, status: !col.status } : col
      )
    );
  };

  const columnSettings = columns.map((column) => ({
    label: column.text,
    isChecked: column.status,
    onChange: () => toggleColumnVisibility(column.id),
  }));

  return (
    <Page
      title="Scheduling"
      buttons={[
        {
          text: "Change Start Date",
          icon: IconCalendar,
          onClick: handleChangeStartDateClick,
          isLoading: activeRow.id ? false : true,
        },
        {
          text: "Refresh",
          icon: IconRefresh,
          onClick: () => setRefresh(refresh + 1),
          isLoading,
          colorScheme: "blue",
        },
      ]}
      sticky={true}
      cardBodyPadding={0}
      dropdowns={[
        {
          label: "Show/Hide Columns",
          icon: <IconSettings />,
          items: columnSettings,
        },
      ]}
    >
      <ResponsiveTable
        rows={rows}
        activeRow={activeRow}
        setActiveRow={setActiveRow}
        columns={columns}
      />
      <ChangeStartDate
        isOpen={isOpen}
        onClose={onClose}
        row={activeRow}
        startDate={startDate}
        setStartDate={setStartDate}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </Page>
  );
};
