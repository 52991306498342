import { IconX } from "@tabler/icons-react";
import {
  Select,
  Input,
  HStack,
  Box,
  Text,
  useBreakpointValue,
  VStack,
  IconButton,
} from "@chakra-ui/react";

const TotalsGroupCalculation = ({
  calculation,
  setCalculation,
  removeCalculation,
  limits,
}) => {
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCalculation({ ...calculation, [name]: value });
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <>
      {isMobile ? (
        <Box borderRadius={6} borderWidth={1} padding={2}>
          <VStack spacing={2} alignItems="stretch">
            <HStack alignItems="center">
              <Box borderRadius={6} padding={2} borderWidth={1}>
                <Text>Action</Text>
              </Box>
              <Box flex={1}>
                <Select
                  value={calculation.action}
                  name="action"
                  onChange={handleInputChange}
                >
                  <option value="add">Add</option>
                  <option value="subtract">Subtract</option>
                </Select>
              </Box>
            </HStack>
            <HStack alignItems="center">
              <Box borderRadius={6} padding={2} borderWidth={1}>
                <Text>Source</Text>
              </Box>
              <Box flex={1}>
                <Select
                  value={calculation.source}
                  name="source"
                  onChange={handleInputChange}
                >
                  {limits &&
                    limits.map((limit, idx) => (
                      <option key={idx} value={limit.name}>
                        {limit.name}
                      </option>
                    ))}
                </Select>
              </Box>
            </HStack>
            <HStack alignItems="center">
              <Box borderRadius={6} padding={2} borderWidth={1}>
                <Text>Calculation</Text>
              </Box>
              <Box flex={1}>
                <Select
                  value={calculation.calculation}
                  name="calculation"
                  onChange={handleInputChange}
                >
                  <option value="add">Add Days</option>
                  <option value="subtract">Subtract Days</option>
                  <option value="current">Current Date</option>
                </Select>
              </Box>
            </HStack>
            <HStack alignItems="center">
              <Box borderRadius={6} padding={2} borderWidth={1}>
                <Text>Days</Text>
              </Box>
              <Box flex={1}>
                <Input
                  type="number"
                  value={calculation.days}
                  name="days"
                  onChange={handleInputChange}
                />
              </Box>
            </HStack>
            <IconButton onClick={removeCalculation} icon={<IconX />} />
          </VStack>
        </Box>
      ) : (
        <HStack spacing={2} alignItems="center">
          <Box borderRadius={6} padding={2} borderWidth={1}>
            <Text>Action</Text>
          </Box>
          <Box flex={1}>
            <Select
              value={calculation.action}
              name="action"
              onChange={handleInputChange}
            >
              <option value="add">Add</option>
              <option value="subtract">Subtract</option>
            </Select>
          </Box>
          <Box borderRadius={6} padding={2} borderWidth={1}>
            <Text>Source</Text>
          </Box>
          <Box flex={1}>
            <Select
              value={calculation.source}
              name="source"
              onChange={handleInputChange}
            >
              {limits &&
                limits.map((limit, idx) => (
                  <option key={idx} value={limit.name}>
                    {limit.name}
                  </option>
                ))}
            </Select>
          </Box>
          <Box borderRadius={6} padding={2} borderWidth={1}>
            <Text>Calculation</Text>
          </Box>
          <Box flex={1}>
            <Select
              value={calculation.calculation}
              name="calculation"
              onChange={handleInputChange}
            >
              <option value="add">Add Days</option>
              <option value="subtract">Subtract Days</option>
              <option value="current">Current Date</option>
            </Select>
          </Box>
          <Box borderRadius={6} padding={2} borderWidth={1}>
            <Text>Days</Text>
          </Box>
          <Box flex={1}>
            <Input
              type="number"
              value={calculation.days}
              name="days"
              onChange={handleInputChange}
            />
          </Box>
          <IconButton onClick={removeCalculation} icon={<IconX />} />
        </HStack>
      )}
    </>
  );
};

export { TotalsGroupCalculation };
