import { Input, InputGroup, InputLeftAddon } from "@chakra-ui/react";

const InputRow = ({ header, index, handleInputChange }) => {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  return (
    <InputGroup>
      <InputLeftAddon>
        {header.name} ({chars.at(index)})
      </InputLeftAddon>
      <Input
        value={header.defaultValue}
        onChange={(e) => handleInputChange(index, e)}
      />
    </InputGroup>
  );
};

export default InputRow;
