import React, { useState } from "react";
import api from "../../services/api";
import { useAPI } from "../../hooks/useAPI";
import { Page } from "../../components/Shared/Page";
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import { IconRefresh } from "@tabler/icons-react";

const DeleteOrders = () => {
  const [searchId, setSearchId] = useState("");

  const {
    data: order,
    setData: setOrder,
    refresh,
    isLoading,
  } = useAPI(`/api/orders/${searchId}`);

  const handleDelete = async () => {
    const userConfirm = confirm(
      "Are you sure you want to delete this order? This action is irreversible"
    );

    if (!userConfirm) return alert("Cancelled!");

    try {
      const { status } = await api(
        `/api/administration/helpers/delete-order/${order._id}`,
        "delete"
      );

      if (status === "success") setOrder({});
    } catch (error) {
      console.error(`Fetch error: ${error.message}`);
    }
  };

  return (
    <Page
      title="Delete Order"
      buttons={[
        {
          text: "Refresh",
          icon: IconRefresh,
          isLoading,
          colorScheme: "blue",
          onClick: refresh,
        },
      ]}
    >
      <FormControl>
        <FormLabel>Order ID</FormLabel>
        <Input
          value={searchId}
          onChange={(e) => setSearchId(e.target.value)}
          mb={order?.dateEntries && 4}
        />
      </FormControl>
      {order?.dateEntries && (
        <Flex direction={{ base: "column", lg: "row" }} gap={4}>
          <Card
            flex={{ base: "none", lg: "1" }}
            borderWidth={1}
            borderColor={"gray.200"}
            boxShadow={"none"}
          >
            <CardBody>
              <Stack divider={<StackDivider />} spacing={4}>
                <Box>
                  <Heading size={"xs"}>Order ID</Heading>
                  <Text pt={2}>{order._id}</Text>
                </Box>
                <Box>
                  <Heading size={"xs"}>Name</Heading>
                  <Text pt={2}>{order.name}</Text>
                </Box>
                <Box>
                  <Heading size={"xs"}>Company</Heading>
                  <Text pt={2}>{order.companyName}</Text>
                </Box>
                <Box>
                  <Heading size={"xs"}>Ship Date</Heading>
                  <Text pt={2}>{order.shipDate}</Text>
                </Box>
                <Box>
                  <Heading size={"xs"}>Summary</Heading>
                  <Text flexWrap={"wrap"} pt={2}>
                    {order.summary}
                  </Text>
                </Box>
                <Button colorScheme="red" onClick={handleDelete}>
                  Delete Order
                </Button>
              </Stack>
            </CardBody>
          </Card>
          <Card
            flex={{ base: "none", lg: "1" }}
            borderWidth={1}
            borderColor={"gray.200"}
            boxShadow={"none"}
          >
            <CardBody>
              <Box>
                <Stack divider={<StackDivider />} spacing={4}>
                  <Box>
                    <Heading size={"xs"}>Date Entries</Heading>
                  </Box>
                  {order.dateEntries.map((entry) => (
                    <Box key={entry._id}>
                      {entry.quantity} x {entry.type} ({entry.limitsGroup}) -
                      Start Date: {entry.startDate} - Has Started:{" "}
                      {entry.hasStarted ? "Yes" : "No"}
                    </Box>
                  ))}
                </Stack>
              </Box>
            </CardBody>
          </Card>
        </Flex>
      )}
    </Page>
  );
};

export { DeleteOrders };
