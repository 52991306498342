import React from "react";
import {
  Button,
  CloseButton,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  Text,
  VStack,
  useBreakpointValue,
  useTheme,
  useToast,
} from "@chakra-ui/react";
import {
  IconAlertTriangle,
  IconCheck,
  IconExclamationCircle,
  IconInfoCircle,
} from "@tabler/icons-react";
import api from "../../services/api";

const NotificationDrawer = ({
  notifications,
  isNotificationOpen,
  onNotificationClose,
  refreshNotifications,
}) => {
  const theme = useTheme();

  const toast = useToast();

  const icons = {
    error: IconExclamationCircle,
    warning: IconAlertTriangle,
    info: IconInfoCircle,
    success: IconCheck,
  };

  const colorSchemes = {
    info: theme.colors.blue[500],
    warning: theme.colors.orange[500],
    success: theme.colors.green[500],
    error: theme.colors.red[500],
  };

  const drawerWidth = useBreakpointValue({ base: "full", sm: "md", lg: "lg" });

  const readNotification = async (id) => {
    try {
      const { status } = await api(`/api/notifications/${id}`);

      if (status === "success") {
        refreshNotifications();
      }
    } catch (error) {
      toast({
        title: "An error occured",
        status: "error",
        description: error.data?.data?.message || error.message,
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Drawer
      isOpen={isNotificationOpen}
      placement="right"
      onClose={onNotificationClose}
      size={drawerWidth}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottomWidth="1px">
          <Flex alignItems="center" justifyContent="space-between">
            Notifications
            <CloseButton onClick={onNotificationClose} />
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          {notifications && notifications.length > 0 ? (
            [...notifications].reverse().map((notification) => (
              <Flex
                key={notification._id}
                direction="column"
                p="4"
                boxShadow="md"
                mb="4"
                mt="4"
                borderWidth="1px"
                borderRadius="lg"
                position="relative"
                borderLeftColor={colorSchemes[notification.type]}
                borderLeftWidth={"4px"}
                borderLeftRadius={"4px"}
              >
                <Flex align="center" mb={2}>
                  <Icon
                    as={icons[notification.type]}
                    color={colorSchemes[notification.type]}
                    w={6}
                    h={6}
                  />
                  <Text fontWeight="bold" ml={2}>
                    {notification.title}
                  </Text>
                </Flex>
                <Text fontSize="sm" mb={{ base: 2, md: 0 }}>
                  {notification.message}
                </Text>
                <Button
                  size="sm"
                  position={{ base: "", md: "absolute" }}
                  right="3"
                  top="3"
                  colorScheme="blue"
                  leftIcon={<IconCheck />}
                  onClick={() => readNotification(notification._id)}
                >
                  Mark as Read
                </Button>
              </Flex>
            ))
          ) : (
            <VStack spacing={4} textAlign="center">
              <Text fontSize="lg" marginTop={4} fontWeight="medium">
                You're all caught up!
              </Text>
              <Text color="gray.500">
                Looks like there are no new notifications.
              </Text>
            </VStack>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export { NotificationDrawer };
