import { Box, Spinner } from "@chakra-ui/react";

const Loader = () => {
  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      h="100vh"
    >
      <Spinner size="xl" color="blue.500" />
      <Box mt={4} fontSize="xl" fontWeight="bold">
        TruFlow
      </Box>
    </Box>
  );
};

export { Loader };
