import { useAPI } from "../../../hooks/useAPI";
import { SettingsList } from "../../../components/Settings/SettingsList";
import {
  IconArrowsSort,
  IconPlus,
  IconRefresh,
  IconTrash,
} from "@tabler/icons-react";
import { useState } from "react";
import api from "../../../services/api";
import { Sorting } from "../../../components/Settings/Modals/Sorting";
import { CreateLimitsGroup } from "../../../components/Settings/Modals/CreateLimitsGroup";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import swal from "sweetalert";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Checkbox,
  Flex,
  Grid,
  HStack,
  Heading,
  Spacer,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Page } from "../../../components/Shared/Page";

const Limits = () => {
  useDocumentTitle("Limits");

  const [sortData, setSortData] = useState([]);
  const [limitsGroup, setLimitsGroup] = useState(null);
  const {
    data: limits,
    isLoading,
    refresh,
  } = useAPI("/api/settings/limits/sorted");

  const {
    isOpen: isCreateGroupOpen,
    onOpen: onCreateGroupOpen,
    onClose: onCreateGroupClose,
  } = useDisclosure();

  const {
    isOpen: isSortingOpen,
    onOpen: onSortingOpen,
    onClose: onSortingClose,
  } = useDisclosure();

  const toast = useToast();

  const toggleLimit = async (id, checked) => {
    try {
      const response = await api(`/api/settings/limits/${id}/${checked}`);

      if (response.status === "success") {
        refresh();

        toast({
          title: `The ${response.data.name} category is now ${
            checked === true ? "enabled" : "disabled"
          }`,
          description:
            checked === true ? (
              "Any products in this category will be saved in TruFlow"
            ) : (
              <span>
                Any products in this category will <strong>not</strong> be saved
                in TruFlow
              </span>
            ),
          status: "success",
          isClosable: true,
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "An error occured",
        description: error.data?.data?.message || error.message,
        status: "error",
        isClosable: true,
        duration: 5000,
      });
    }
  };

  const loadProductsSortingModal = ({ products, _id }) => {
    setSortData(products);

    setLimitsGroup(_id);

    onSortingOpen();
  };

  const loadLimitsSortingModal = (data) => {
    setSortData(() => data.map((e) => ({ id: e._id, name: e.name })));

    onSortingOpen();
  };

  const deleteGroup = async ({ _id }) => {
    try {
      const confirmDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this group",
        icon: "error",
        buttons: true,
      });

      if (!confirmDelete) return swal({ title: "Cancelled", icon: "success" });

      const response = await api(`/api/settings/limits/${_id}`, "delete");

      if (response.status == "success") {
        refresh();

        toast({
          status: "success",
          title: `Limits group deleted successfully`,
          isClosable: true,
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "An error occured",
        description: error.data?.data?.message || error.message,
        status: "error",
        isClosable: true,
        duration: 5000,
      });
    }
  };

  const saveSorting = async () => {
    try {
      const list = sortData.map((e) => (e.id ? e.id : e.toString()));

      if (list.every((x) => /^[a-f\d]{24}$/i.test(x))) {
        const response = await api(
          `/api/settings/limits/sorting/headers`,
          "put",
          {
            body: list,
          }
        );

        if (response.status == "success") {
          refresh();

          onSortingClose();

          toast({
            status: "success",
            title: `Sorting updated successfully`,
            isClosable: true,
            duration: 5000,
          });
        }
      } else {
        const response = await api(
          `/api/settings/limits/sorting/products/${limitsGroup}`,
          "put",
          {
            body: list,
          }
        );

        if (response.status == "success") {
          refresh();

          onSortingClose();

          toast({
            status: "success",
            title: `Sorting updated successfully`,
            isClosable: true,
            duration: 5000,
          });
        }
      }
    } catch (error) {
      toast({
        title: "An error occured",
        status: "error",
        description: error.data?.data?.message || error.message,
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Page
      title="Limits"
      buttons={[
        {
          text: "Sorting",
          icon: IconArrowsSort,
          onClick: () => loadLimitsSortingModal(limits),
        },
        {
          text: "Refresh",
          icon: IconRefresh,
          onClick: refresh,
          isLoading: isLoading,
        },
        {
          text: "Create Group",
          icon: IconPlus,
          onClick: onCreateGroupOpen,
          colorScheme: "blue",
        },
      ]}
    >
      <CreateLimitsGroup
        isOpen={isCreateGroupOpen}
        onClose={onCreateGroupClose}
        refresh={refresh}
      />
      <Sorting
        isOpen={isSortingOpen}
        onClose={onSortingClose}
        data={sortData}
        setData={setSortData}
        saveSorting={saveSorting}
      />
      <Grid
        templateColumns={{
          sm: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(4, 1fr)",
        }}
        gap={4}
      >
        {limits && limits.length > 0 ? (
          <SettingsList
            data={limits}
            actions={[
              {
                Icon: IconArrowsSort,
                onClick: loadProductsSortingModal,
              },
              {
                Icon: IconTrash,
                onClick: deleteGroup,
                colorScheme: "red",
              },
            ]}
            footer={[
              {
                position: "left",
                text: "Edit Group",
                url: (limit) => `/settings/limits/${limit._id}`,
              },
              {
                position: "right",
                contents: (limit) => (
                  <Checkbox
                    defaultChecked={limit.enabled ? true : false}
                    onChange={(e) => toggleLimit(limit._id, e.target.checked)}
                  />
                ),
              },
            ]}
          />
        ) : (
          <span>No limits created</span>
        )}
      </Grid>
    </Page>
  );
};

export { Limits };
