import { useState } from "react";
import api from "../../../services/api";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";

const CreateAPIToken = ({ isOpen, onClose, refresh }) => {
  const toast = useToast();
  const [tokenData, setTokenData] = useState({
    name: "",
    expiry: "",
    permissions: [],
  });

  const createAPIToken = async () => {
    try {
      const { status } = await api(`/api/administration/api-tokens`, "post", {
        body: tokenData,
      });

      if (status === "success") {
        refresh();

        onClose();

        setTokenData({ name: "", expiry: "", permissions: [] });

        toast({
          status: "success",
          title: `${tokenData.name} created successfully`,
          isClosable: true,
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "An error occured",
        status: "error",
        description: error.data?.data?.message || error.message,
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottomWidth={"1px"}>Create API Token</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={2}>
            <FormLabel>Name</FormLabel>
            <Input
              value={tokenData.name}
              onChange={(e) =>
                setTokenData({ ...tokenData, name: e.target.value })
              }
            />
          </FormControl>
          <FormControl mb={2}>
            <FormLabel>Expiry Date</FormLabel>
            <Input
              type="date"
              value={tokenData.expiry}
              onChange={(e) =>
                setTokenData({ ...tokenData, expiry: e.target.value })
              }
            />
          </FormControl>
        </ModalBody>
        <ModalFooter borderTopWidth={"1px"}>
          <Button colorScheme="blue" onClick={createAPIToken}>
            Create API Token
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { CreateAPIToken };
