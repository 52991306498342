import { Button, HStack } from "@chakra-ui/react";

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const getVisiblePages = () => {
    const visiblePageCount = 5;
    const pageOffset = Math.floor(visiblePageCount / 2);

    let startPage = currentPage - pageOffset;
    let endPage = currentPage + pageOffset;

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(visiblePageCount, totalPages);
    }

    if (endPage > totalPages) {
      startPage = Math.max(totalPages - visiblePageCount + 1, 1);
      endPage = totalPages;
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  };

  return (
    <HStack>
      <Button
        size="sm"
        onClick={() => onPageChange(currentPage - 1)}
        isDisabled={currentPage === 1}
      >
        Previous
      </Button>{" "}
      {getVisiblePages().map((pageNumber) => (
        <Button
          key={pageNumber}
          size="sm"
          colorScheme={pageNumber === currentPage ? "blue" : "gray"}
          onClick={() => onPageChange(pageNumber)}
        >
          {pageNumber}
        </Button>
      ))}
      <Button
        size="sm"
        onClick={() => onPageChange(currentPage + 1)}
        isDisabled={currentPage === totalPages}
      >
        Next
      </Button>
    </HStack>
  );
};

export default Pagination;
