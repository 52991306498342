// TODO: change app name to TruFlow
// Slogan: Optimize Your Manufacturing Journey with TruFlow

import { useContext, useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "./contexts/AuthContext";
import validateToken from "./services/auth";
import { Loader } from "./components/Loader";
import { Layout } from "./components/Layout";
import { IndexRoutes } from "./routes";
import ProgressBar from "./components/Shared/ProgressBar";

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, dispatch } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [routeChanging, setRouteChanging] = useState(false);

  const { pathname } = location;
  const showNav =
    pathname !== "/login" &&
    pathname !== "/register" &&
    pathname !== "/daily-starts/totals";
  const isNotAuthRoute = !["/register", "/login", "/forgot-password"].includes(
    pathname
  );

  useEffect(() => {
    setRouteChanging(true);

    setTimeout(() => {
      setRouteChanging(false);
    }, 1000);
  }, [pathname]);

  useEffect(() => {
    const validateAuth = async () => {
      try {
        const { authenticated, user } = await validateToken();

        if (authenticated) dispatch({ type: "LOGIN", payload: { user } });
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    validateAuth();
  }, [dispatch]);

  useEffect(() => {
    if (isNotAuthRoute) if (!isLoading && !isAuthenticated) navigate("/login");
  }, [isAuthenticated, isLoading]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <ProgressBar isLoading={routeChanging} />
      {showNav ? (
        <Layout>
          <Routes>
            <Route path="*" element={<IndexRoutes />} />
          </Routes>
        </Layout>
      ) : (
        <Routes>
          <Route path="*" element={<IndexRoutes />} />
        </Routes>
      )}
    </>
  );
};

export { App };
