import { useParams } from "react-router-dom";
import { useAPI } from "../../../hooks/useAPI";
import { Page } from "../../../components/Shared/Page";
import { IconDeviceFloppy } from "@tabler/icons-react";
import { PermissionsAccordion } from "../../../components/Administration/PermissionsAccordion";
import api from "../../../services/api";
import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";

const EditAPIToken = () => {
  const { id } = useParams();

  const {
    data: token,
    setData: setToken,
    isLoading,
    refresh,
  } = useAPI(`/api/administration/api-tokens/${id}`);

  const toast = useToast();

  const setValue = (e) =>
    setToken({ ...token, [e.target.name]: e.target.value });

  const setPermissions = (permissions) => setToken({ ...token, permissions });

  const saveToken = async () => {
    try {
      const { status } = await api(
        `/api/administration/api-tokens/${id}`,
        "put",
        {
          body: token,
        }
      );

      if (status === "success") {
        refresh();

        toast({
          status: "success",
          title: `${token.name} updated successfully`,
          isClosable: true,
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "An error occured",
        status: "error",
        description: error.data?.data?.message || error.message,
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Page
      title="API Tokens"
      buttons={[
        {
          text: "Save Changes",
          icon: IconDeviceFloppy,
          onClick: saveToken,
          colorScheme: "blue",
        },
      ]}
      backButton={true}
    >
      <Stack mb={2} direction={{ base: "column", md: "row" }}>
        <FormControl>
          <FormLabel>Token Name</FormLabel>
          <Input value={token?.name || ""} onChange={setValue} name="name" />
        </FormControl>
        <FormControl>
          <FormLabel>Expiry</FormLabel>
          <Input
            type="date"
            value={token?.expiry || ""}
            onChange={setValue}
            name="expiry"
          />
        </FormControl>
      </Stack>
      <FormLabel>Permissions</FormLabel>
      <PermissionsAccordion
        permissions={token?.permissions}
        setPermissions={setPermissions}
        isLoading={isLoading}
      />
    </Page>
  );
};

export { EditAPIToken };
