import { useEffect, useState } from "react";
import InputRow from "./InputGroup";
import CSVEditor from "./CSVEditor";
import api from "../../../services/api";
import CreateCSVHeaders from "./CreateCSVHeaders";
import {
  Button,
  Card,
  CardBody,
  Grid,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { IconChevronDown } from "@tabler/icons-react";
import { useAPI } from "../../../hooks/useAPI";

const CSVGenerator = () => {
  // TODO: fetch headers again after updating CSV headers

  const [csvHeaders, setCSVHeaders] = useState([]);
  const [csvOutput, setCSVOutput] = useState("");
  const [selectedExporter, setSelectedExporter] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    data: exporters,
    setData: setExporters,
    isLoading,
    refresh,
  } = useAPI("/api/exporters");

  // Fetch CSV generator when selected exporter changes
  useEffect(() => {
    if (selectedExporter) {
      setCSVHeaders(selectedExporter.csvGenerator);

      generateCSVOutput(selectedExporter?.csvGenerator);
    }
  }, [selectedExporter]);

  const handleInputChange = (index, event) => {
    const values = [...csvHeaders];

    values[index].defaultValue = event.target.value;

    setCSVHeaders(values);
    generateCSVOutput(values);
  };

  const handleCSVInputChange = (event) => {
    const csvInput = event.target.value;

    setCSVOutput(csvInput);

    const values = csvInput.split(",");

    const updatedHeaders = csvHeaders.map((header, index) => ({
      ...header,
      defaultValue: values[index] || header.defaultValue,
    }));

    setCSVHeaders(updatedHeaders);
  };

  const generateCSVOutput = (values) => {
    const csv = values?.map((item) => item.defaultValue || "").join(",");

    setCSVOutput(csv);
  };

  const clearHeaders = () => {
    const values = csvHeaders.map((obj) => ({ ...obj, defaultValue: "" }));

    setCSVHeaders(values);

    generateCSVOutput(values);
  };

  const handleModalSubmit = async (headers) => {
    await api(
      `/api/exporters/${selectedExporter._id}/csv-generator`,
      selectedExporter?.csvGenerator ? "put" : "post",
      {
        body: headers,
      }
    );

    refresh();

    generateCSVOutput(headers);
  };

  return (
    <>
      <CreateCSVHeaders
        isOpen={isOpen}
        onClose={onClose}
        exporter={selectedExporter}
        csvHeaders={csvHeaders}
        onSubmit={handleModalSubmit}
        refresh={refresh}
      />
      <CardBody borderBottomWidth={"1px"}>
        <Stack direction={{ base: "column", md: "row" }}>
          <Menu>
            <MenuButton
              as={Button}
              colorScheme="blue"
              rightIcon={<IconChevronDown />}
            >
              {selectedExporter?.exporterName || "Select an exporter"}
            </MenuButton>
            <MenuList>
              {exporters?.map((exporter) => (
                <MenuItem
                  key={exporter._id}
                  onClick={() => setSelectedExporter(exporter)}
                >
                  {exporter.exporterName}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
          <Button onClick={onOpen} disabled={selectedExporter ? false : true}>
            {`${
              selectedExporter?.csvGenerator ? "Update" : "Create"
            } CSV Headers`}
          </Button>
          <Button onClick={clearHeaders}>Clear Values</Button>
        </Stack>
      </CardBody>
      <CardBody borderBottomWidth={"1px"}>
        <Grid
          templateColumns={{
            sm: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(5, 1fr)",
          }}
          gap={2}
        >
          {csvHeaders?.map((header, index) => (
            <InputRow
              header={header}
              index={index}
              handleInputChange={handleInputChange}
            />
          ))}
        </Grid>
      </CardBody>
      <CardBody>
        <CSVEditor
          csvOutput={csvOutput}
          handleCSVInputChange={handleCSVInputChange}
        />
      </CardBody>
    </>
  );
};

export default CSVGenerator;
