import { useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Switch,
  useToast,
} from "@chakra-ui/react";
import api from "../../../services/api";

const CreateLimitsGroup = ({ isOpen, onClose, refresh }) => {
  const [groupData, setGroupData] = useState({
    name: "",
    limit: "",
    rpd: "",
    enabled: true,
    products: [],
  });

  const toast = useToast();

  const setKeyValue = (key, value) => {
    setGroupData({ ...groupData, [key]: value });
  };

  const createGroup = async () => {
    try {
      const response = await api("/api/settings/limits", "post", {
        body: groupData,
      });

      if (response.status === "success") {
        refresh();
        onClose();

        setGroupData({
          name: "",
          limit: "",
          rpd: "",
          enabled: true,
          products: [],
        });

        toast({
          status: "success",
          title: `${groupData.name} created successfully`,
          isClosable: true,
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "An error occured",
        status: "error",
        description: error.data?.data?.message || error.message,
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Group</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="groupName" mb="4">
            <FormLabel>Group Name</FormLabel>
            <Input onChange={(e) => setKeyValue("name", e.target.value)} />
          </FormControl>
          <FormControl id="dailyLimit" mb="4">
            <FormLabel>Daily Limit</FormLabel>
            <Input onChange={(e) => setKeyValue("limit", e.target.value)} />
          </FormControl>
          <FormControl id="requiredProductionDays" mb="4">
            <FormLabel>Required Production Days</FormLabel>
            <Input onChange={(e) => setKeyValue("rpd", e.target.value)} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <HStack alignItems={"center"} justifyContent={"center"}>
            <Switch
              isChecked={groupData.enabled}
              onChange={(e) => setKeyValue("enabled", e.target.checked)}
            />
            <Spacer />
            <Button onClick={createGroup} colorScheme="blue">
              Create Group
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { CreateLimitsGroup };
