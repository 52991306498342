import { SettingsList } from "../../../components/Settings/SettingsList";
import { useAPI } from "../../../hooks/useAPI";
import ProgressBar from "../../../components/Shared/ProgressBar";
import {
  IconArrowsSort,
  IconPlus,
  IconRefresh,
  IconTrash,
} from "@tabler/icons-react";
import { useState } from "react";
import { Sorting } from "../../../components/Settings/Modals/Sorting";
import api from "../../../services/api";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import swal from "sweetalert";
import { CreateTotalsGroup } from "../../../components/Settings/Modals/CreateTotalsGroup";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Grid,
  HStack,
  Heading,
  Spacer,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Page } from "../../../components/Shared/Page";

const TotalsGroups = () => {
  useDocumentTitle("Totals Groups");

  const [sortData, setSortData] = useState([]);
  const [showSortingModal, setShowSortingModal] = useState(false);
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const {
    data: totalsGroups,
    isLoading,
    refresh,
  } = useAPI("/api/settings/totals-groups");

  const {
    isOpen: isCreateGroupOpen,
    onOpen: onCreateGroupOpen,
    onClose: onCreateGroupClose,
  } = useDisclosure();

  const {
    isOpen: isSortingOpen,
    onOpen: onSortingOpen,
    onClose: onSortingClose,
  } = useDisclosure();

  const toast = useToast();

  const loadSortingModal = () => {
    setSortData(
      totalsGroups.map((group) => ({
        id: group._id,
        name: `${group.name} (${group.identifier})`,
      }))
    );

    onSortingOpen();
  };

  const deleteGroup = async ({ _id }) => {
    try {
      const confirmDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this group",
        icon: "error",
        buttons: true,
      });

      if (!confirmDelete) return swal({ title: "Cancelled", icon: "success" });

      const response = await api(
        `/api/settings/totals-groups/${_id}`,
        "delete"
      );

      if (response.status == "success") {
        refresh();

        toast({
          status: "success",
          title: `Totals group deleted successfully`,
          isClosable: true,
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "An error occured",
        description: error.data?.data?.message || error.message,
        status: "error",
        isClosable: true,
        duration: 5000,
      });
    }
  };

  const saveSorting = async () => {
    try {
      const list = sortData.map((e) => e.id);

      const response = await api(
        "/api/settings/totals-groups/sorting",
        "post",
        { body: list }
      );

      if (response.status == "success") {
        refresh();

        onSortingClose();

        toast({
          status: "success",
          title: `Sorting updated successfully`,
          isClosable: true,
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "An error occured",
        description: error.data?.data?.message || error.message,
        status: "error",
        isClosable: true,
        duration: 5000,
      });
    }
  };

  return (
    <Page
      title="Totals Groups"
      buttons={[
        {
          text: "Sorting",
          icon: IconArrowsSort,
          onClick: () => loadSortingModal(totalsGroups),
        },
        {
          text: "Refresh",
          icon: IconRefresh,
          onClick: refresh,
          isLoading,
        },
        {
          text: "Create Group",
          icon: IconPlus,
          onClick: onCreateGroupOpen,
          colorScheme: "blue",
        },
      ]}
    >
      <CreateTotalsGroup
        isOpen={isCreateGroupOpen}
        onClose={onCreateGroupClose}
        refresh={refresh}
      />
      <Sorting
        isOpen={isSortingOpen}
        onClose={onSortingClose}
        data={sortData}
        setData={setSortData}
        saveSorting={saveSorting}
      />
      <Grid
        templateColumns={{
          sm: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(4, 1fr)",
        }}
        gap={4}
      >
        {totalsGroups && totalsGroups.length > 0 ? (
          <SettingsList
            data={totalsGroups}
            actions={[
              { Icon: IconTrash, onClick: deleteGroup, colorScheme: "red" },
            ]}
            footer={[
              {
                position: "left",
                text: "Edit Group",
                url: (group) => `/settings/totals-groups/${group._id}`,
              },
            ]}
          />
        ) : (
          <span>No totals groups created</span>
        )}
      </Grid>
    </Page>
  );
};

export { TotalsGroups };
