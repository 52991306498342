import { useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useToast,
} from "@chakra-ui/react";
import api from "../../services/api";
import { useAPI } from "../../hooks/useAPI";

export default ({ isOpen, onClose, refresh }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [exporter, setExporter] = useState({
    exporterName: "",
    programDirectory: "",
    preProcessorScript: "none",
  });

  const { data: scripts } = useAPI("/api/scripts");

  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      const response = await api("/api/exporters", "POST", {
        body: { ...exporter },
      });

      if (response.status === "success") {
        toast({
          title: "Exported created successfully",
          status: "success",
          duration: 2000,
          isClosable: true,
        });

        refresh();

        onClose();
      }
    } catch (error) {
      toast({
        title: "An error occured",
        status: "error",
        description: error.data?.data?.message || error.message,
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit}>
        <ModalHeader>Create an Exporter</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="exporterName" mb="4">
            <FormLabel>Exporter Name</FormLabel>
            <Input
              type="text"
              value={exporter.exporterName}
              onChange={(e) =>
                setExporter({ ...exporter, exporterName: e.target.value })
              }
            />
          </FormControl>
          <FormControl id="programDirectory" mb="4">
            <FormLabel>Program Directory</FormLabel>
            <Input
              type="text"
              value={exporter.programDirectory}
              onChange={(e) =>
                setExporter({ ...exporter, programDirectory: e.target.value })
              }
            />
          </FormControl>
          {/* TODO: Fetch a list of scripts for exporters and list them here */}
          <FormControl id="preProcessorScript" mb="4">
            <FormLabel>Pre-Processor Script</FormLabel>
            <Select
              value={exporter.preProcessorScript}
              onChange={(e) =>
                setExporter({
                  ...exporter,
                  preProcessorScript: e.target.value,
                })
              }
            >
              <option value="none">None</option>
              {scripts &&
                scripts.map((script) => (
                  <option value={script._id}>{script.name}</option>
                ))}
            </Select>
          </FormControl>
          <FormControl id="ignoreEmptyFiles" mb="4">
            <Checkbox
              defaultChecked={exporter?.ignoreEmptyFiles}
              onChange={(e) =>
                setExporter({
                  ...exporter,
                  ignoreEmptyFiles: e.target.checked,
                })
              }
            >
              Ignore Empty Files
            </Checkbox>
          </FormControl>
          <FormControl id="firstLineHeaders" mb="4">
            <Checkbox
              defaultChecked={exporter?.firstLineHeaders}
              onChange={(e) =>
                setExporter({
                  ...exporter,
                  firstLineHeaders: e.target.checked,
                })
              }
            >
              First Line Always Contains Headers
            </Checkbox>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" isLoading={isLoading} colorScheme="blue">
            Create Exporter
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
