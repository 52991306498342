import { useState } from "react";
import { Tr, Checkbox, Td, Text, Box, HStack, Button } from "@chakra-ui/react";
import api from "../../services/api";

const ResponsiveEntry = ({
  row,
  activeRow,
  setActiveRow,
  columns,
  idSelector,
}) => {
  const [highlight, setHighlight] = useState(false);

  const toggleStarted = async (orderID, dateEntryID) => {
    try {
      const newStatus = !row.hasStarted;

      await api(`/api/orders/in-production/${newStatus}`, "put", {
        body: { dateEntryID, orderID },
      });

      row.hasStarted = newStatus;
      setHighlight(true);

      setTimeout(() => setHighlight(false), 750);
    } catch (error) {
      alert(error.data?.data?.message || error.message);
    }
  };

  const handleCheckboxChange = (column, row) => {
    if (column.onChange) {
      column.onChange(row);
    } else if (column.id === "hasStarted") {
      toggleStarted(row.orderID, row.id);
    }
  };

  return (
    <Tr
      onClick={(e) =>
        e.target.tagName !== "INPUT" &&
        e.target.tagName !== "SPAN" &&
        setActiveRow(row)
      }
      transition={"background-color 0.3s ease"}
      color={row.highlightColor}
      backgroundColor={
        highlight
          ? "green.300"
          : activeRow && row[idSelector] === activeRow[idSelector]
          ? "gray.400"
          : ""
      }
    >
      {columns.map(
        (col) =>
          col.status && (
            <Td
              key={col.id}
              style={
                col.type === "button"
                  ? {
                      minWidth: "auto",
                      maxWidth: "1px",
                      whiteSpace: "nowrap",
                    }
                  : {}
              }
            >
              {col.type === "checkbox" ? (
                <Checkbox
                  isChecked={row[col.id]}
                  onChange={() => handleCheckboxChange(col, row)}
                />
              ) : col.type === "button" ? (
                <>
                  <Button
                    size={col.button.size}
                    colorScheme={col.button.colorScheme}
                    onClick={() => col.button.onClick(row)}
                    isLoading={col.button.isLoading}
                    width={"100%"}
                  >
                    {col.button.text}
                  </Button>
                </>
              ) : col.displayAttrs ? (
                <>
                  <Box>
                    <Text>{row[col.id]}</Text>
                    {row.allmoxyData && row.allmoxyData.length > 0 && (
                      <HStack align="start" spacing={1} mt={2}>
                        {row.allmoxyData?.map(
                          (attr) =>
                            attr.displayOn.includes("scheduling") && (
                              <Box
                                key={attr._id}
                                fontSize="sm"
                                color="gray.600"
                              >
                                <strong>{attr.displayName}:</strong>{" "}
                                {attr.value}
                              </Box>
                            )
                        )}
                      </HStack>
                    )}
                  </Box>
                </>
              ) : (
                row[col.id]
              )}
            </Td>
          )
      )}
    </Tr>
  );
};

export { ResponsiveEntry };
