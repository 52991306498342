import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import api from "../services/api";
import { NavLink, useNavigate } from "react-router-dom";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  Link,
} from "@chakra-ui/react";

const Login = () => {
  useDocumentTitle("Login");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({ class: "", message: "" });
  const { dispatch, isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!password || !email)
      return setError({
        class: "danger",
        message: "No email or password provided",
      });

    try {
      const response = await api("/api/auth/login", "POST", {
        body: { email, password },
      });

      // If the response contains an error
      if (response.status === "error") {
        setError({
          class: "danger",
          message: response.data.message,
        });
      }
      // If the response is successful and the user is authenticated
      else if (response.status === "success") {
        const { user } = response.data;

        // Use your dispatch function to set the user info and authentication status
        dispatch({
          type: "LOGIN",
          payload: {
            user,
          },
        });
      }
    } catch (error) {
      // Handle any errors that occurred while making the request
      setError({
        class: "danger",
        message: error.data?.data?.message || error.message,
      });
    }
  };

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"xl"} width={"xl"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>TruFlow</Heading>
        </Stack>
        {error.message && (
          <Stack align={"center"}>
            <Alert status="error" rounded={"lg"}>
              <AlertIcon />
              <AlertTitle>{error.message}</AlertTitle>
            </Alert>
          </Stack>
        )}
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <form onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <FormControl id="email">
                <FormLabel>Email address</FormLabel>
                <Input
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl id="password">
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>
              <Stack spacing={10}>
                <Stack
                  direction={{ base: "column", sm: "row" }}
                  align={"start"}
                  justify={"space-between"}
                >
                  <Checkbox>Remember me</Checkbox>
                  <Text color={"blue.400"}>Forgot password?</Text>
                </Stack>
                <Button
                  bg={"blue.400"}
                  color={"white"}
                  _hover={{
                    bg: "blue.500",
                  }}
                  type="submit"
                >
                  Sign in
                </Button>
              </Stack>
              <Stack align={"center"}>
                <Text>
                  Need an account?{" "}
                  <Link as={NavLink} color={"blue.400"} to="/register">
                    Register
                  </Link>
                </Text>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};

export { Login };
