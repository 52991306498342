import { Page } from "../components/Shared/Page";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { useAPI } from "../hooks/useAPI";
import { IconRefresh } from "@tabler/icons-react";
import { useEffect } from "react";
import { Grid, Stat, StatGroup, StatLabel, StatNumber } from "@chakra-ui/react";

const Dashboard = () => {
  useDocumentTitle("Dashboard");

  const { data, refresh, isLoading } = useAPI("/api/statistics/");
  const { data: ordersInData } = useAPI("/api/statistics/orders-in");

  return (
    <Page
      title="Dashboard"
      buttons={[
        {
          text: "Refresh",
          icon: IconRefresh,
          colorScheme: "blue",
          isLoading,
          refresh,
        },
      ]}
    >
      <StatGroup>
        <Stat>
          <StatLabel>Order Bank</StatLabel>
          <StatNumber>
            {data?.totals.total - data?.totals.started || 0}
          </StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Total Pieces</StatLabel>
          <StatNumber>{data?.totals.total || 0}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Total Orders</StatLabel>
          <StatNumber>{data?.orders || 0}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Pieces in Today</StatLabel>
          <StatNumber>{ordersInData?.totalPieces || 0}</StatNumber>
        </Stat>
      </StatGroup>
    </Page>
  );
};

export { Dashboard };
