import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  Alert,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";

export const OrderProcessed = ({ isOpen, onClose, event }) => {
  const formattedJson = JSON.stringify(event, null, 2);

  return (
    <Modal size="5xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottomWidth={"1px"}>
          <Flex alignItems="center" justifyContent="space-between">
            Order Processed
            <ModalCloseButton onClick={onClose} />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Grid templateColumns="repeat(3, 1fr)" gap={2} mb={4}>
            <GridItem>
              <FormControl>
                <FormLabel>Order ID</FormLabel>
                <Input readOnly value={event.orderID} />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>Status</FormLabel>
                <Input readOnly value={event.status} />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>Source</FormLabel>
                <Input readOnly value={event.source} />
              </FormControl>
            </GridItem>
          </Grid>
          <Box>
            <FormControl mb={4}>
              <FormLabel>Company Name</FormLabel>
              <Input readOnly value={event.input.company_name} />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Contact Name</FormLabel>
              <Input readOnly value={event.input.company_contact_name} />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Contact Email</FormLabel>
              <Input readOnly value={event.input.company_contact_email} />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Shipping Address</FormLabel>
              <Input
                readOnly
                value={`${event.input.shipping_address1}, ${event.input.shipping_city}, ${event.input.shipping_state}, ${event.input.shipping_zip}, ${event.input.shipping_country}`}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Raw JSON</FormLabel>
              <Textarea readOnly value={formattedJson} height="200px" />
            </FormControl>
          </Box>
          {event.error && (
            <Box mt={4}>
              <Alert status="error">
                <AlertIcon />
                <Box flex="1">
                  <AlertTitle>Error Details</AlertTitle>
                  <Box>
                    <FormControl>
                      <FormLabel>Error Message</FormLabel>
                      <Input readOnly value={event.error} />
                    </FormControl>
                    {event.errorStack && (
                      <FormControl mt={2}>
                        <FormLabel>Error Stack</FormLabel>
                        <Input readOnly value={event.errorStack} />
                      </FormControl>
                    )}
                    {event.errorDetails && (
                      <FormControl mt={2}>
                        <FormLabel>Error Details</FormLabel>
                        <Textarea readOnly value={event.errorDetails} />
                      </FormControl>
                    )}
                  </Box>
                </Box>
              </Alert>
            </Box>
          )}
        </ModalBody>
        <ModalFooter borderTopWidth={"1px"}>
          <Button colorScheme="blue" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
