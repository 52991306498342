import { IconRefresh } from "@tabler/icons-react";
import { DateTime, Interval } from "luxon";
import { useEffect, useState } from "react";
import api from "../services/api";
import ProgressBar from "../components/Shared/ProgressBar";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { useAPI } from "../hooks/useAPI";
import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Input,
  Spacer,
} from "@chakra-ui/react";
import { ResponsiveTable } from "../components/DailyTotals/ResponsiveTable";

export default () => {
  useDocumentTitle("Daily Totals");

  const [dateRange, setDateRange] = useState(null);
  const [dates, setDates] = useState({ start: null, end: null });
  const [error, setError] = useState(null);

  const { data: holidays, isLoading: isLoadingHolidays } = useAPI(
    `/api/settings/holidays`
  );
  const { data: headers, refresh: refreshHeaders } = useAPI(
    `/api/daily-totals/headers`
  );
  const {
    data: totals,
    isLoading,
    refresh,
  } = useAPI(
    "/api/daily-totals/range",
    "post",
    {
      body: { range: dateRange },
    },
    !dateRange ? false : true
  );

  const refreshDailyTotals = () => {
    refreshHeaders();
    refresh();
  };

  function* days(interval) {
    let cursor = interval.start.startOf("day");
    while (cursor < interval.end) {
      yield cursor;
      cursor = cursor.plus({ days: 1 });
    }
  }

  const calculateDateRange = async () => {
    const datesInRange = [];

    const startDate = DateTime.fromISO(dates.start);
    const endDate = DateTime.fromISO(dates.end);

    const interval = Interval.fromDateTimes(startDate, endDate);

    for (const d of days(interval)) {
      const dateStr = d.toISODate();

      if (
        d.weekday !== 6 &&
        d.weekday !== 7 &&
        !holidays.some((holiday) => holiday.date === dateStr)
      ) {
        datesInRange.push(dateStr);
      }
    }

    if (
      endDate.weekday !== 6 &&
      endDate.weekday !== 7 &&
      !holidays.some((holiday) => holiday.date === endDate.toISODate())
    ) {
      datesInRange.push(endDate.toISODate());
    }

    setDateRange(datesInRange);
  };

  useEffect(() => {
    if (dates.start && dates.end && !isLoadingHolidays) {
      setError(null);

      const startDate = DateTime.fromISO(dates.start);
      const endDate = DateTime.fromISO(dates.end);

      if (startDate > endDate)
        return setError("The end date must come after the start date");

      if (
        [6, 7].includes(startDate.weekday) ||
        [6, 7].includes(endDate.weekday)
      )
        return setError("Your range can't start or end on a weekend");

      calculateDateRange();
    }
  }, [dates, isLoadingHolidays]);

  useEffect(() => {
    const dates = JSON.parse(localStorage.getItem("totalsDates"));

    if (dates && dates.start && dates.end) setDates(dates);
  }, []);

  useEffect(() => {
    localStorage.setItem("totalsDates", JSON.stringify(dates));
  }, [dates]);

  useEffect(() => {
    const bc = new BroadcastChannel("daily_totals");

    bc.onmessage = () => refreshDailyTotals();

    return () => bc.close();
  }, []);

  return (
    <>
      <Card borderRadius={0}>
        <CardBody borderBottomWidth={"1px"}>
          <Flex alignItems={"center"} flexDir={{ base: "column", md: "row" }}>
            <Heading
              mb={{ base: 2, md: 0 }}
              textAlign={{ base: "center", md: "left" }}
              size="md"
            >
              Daily Totals
            </Heading>
            <Spacer />
            <HStack
              alignItems={"center"}
              justifyContent={"center"}
              flexWrap={{ base: "wrap", md: "nowrap" }}
            >
              <FormControl>
                <Flex alignItems={"center"}>
                  <FormLabel mb={0} mr={2} whiteSpace={"nowrap"}>
                    Start Date:
                  </FormLabel>
                  <Input
                    type="date"
                    value={dates.start}
                    onChange={(e) =>
                      setDates({ ...dates, start: e.target.value })
                    }
                  />
                </Flex>
              </FormControl>
              <FormControl>
                <Flex alignItems={"center"}>
                  <FormLabel mb={0} mr={2} whiteSpace={"nowrap"}>
                    End Date:
                  </FormLabel>
                  <Input
                    type="date"
                    value={dates.end}
                    onChange={(e) =>
                      setDates({ ...dates, end: e.target.value })
                    }
                  />
                </Flex>
              </FormControl>
              <IconButton
                width={{ base: "100%" }}
                colorScheme="blue"
                icon={<IconRefresh />}
                isLoading={isLoading}
                onClick={refresh}
              />
            </HStack>
          </Flex>
        </CardBody>
        <ResponsiveTable headers={headers} totals={totals} />
      </Card>
    </>
  );
};
