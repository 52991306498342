import { useState } from "react";
import api from "../services/api";
import { IconDeviceFloppy, IconPlus, IconRefresh } from "@tabler/icons-react";
import CreateScript from "../components/Scripts/CreateScript";
import { ScriptBody } from "../components/Scripts/ScriptBody";
import { useAPI } from "../hooks/useAPI";
import {
  Box,
  List,
  ListItem,
  Button,
  Flex,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Spacer,
  HStack,
  useDisclosure,
  ButtonGroup,
  useBreakpointValue,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { Page } from "../components/Shared/Page";

const Scripts = () => {
  useDocumentTitle("Scripts");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [script, setScript] = useState(null);

  const toast = useToast();

  const direction = useBreakpointValue({ base: "column", md: "row" });
  const isMobile = useBreakpointValue({ base: true, md: false });

  const {
    data: scripts,
    isLoading: isLoadingScripts,
    refresh,
  } = useAPI("/api/scripts");

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const response = await api(`/api/scripts/${script._id}`, "PUT", {
        body: { ...script },
      });

      if (response.status == "success") {
        toast({
          title: "Script saved successfully",
          status: "success",
          duration: 2000,
          isClosable: true,
        });

        refresh();
      }
    } catch (error) {
      toast({
        title: "An error occured",
        status: "error",
        description: error.data?.data?.message || error.message,
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Page
      title="Scripts"
      buttons={[
        {
          text: "Refresh",
          icon: IconRefresh,
          isLoading: isLoadingScripts,
          onClick: refresh,
        },
        {
          text: "Create Script",
          icon: IconPlus,
          onClick: onOpen,
        },
        {
          text: "Save Changes",
          icon: IconDeviceFloppy,
          isLoading: !script,
          colorScheme: "blue",
          onClick: handleSubmit,
        },
      ]}
      cardBodyPadding={0}
    >
      <CreateScript isOpen={isOpen} onClose={onClose} refresh={refresh} />
      <Stack
        direction={direction}
        h={isMobile ? "100%" : "calc(100vh - 217px)"}
        overflow="hidden"
      >
        <Box
          width={isMobile ? "100%" : "20%"}
          borderRight="1px"
          borderRightColor="gray.200"
        >
          <List spacing={3} p={4}>
            {scripts &&
              (scripts.length === 0 ? (
                <ListItem key={0}>
                  <Button width="100%">No scripts are available</Button>
                </ListItem>
              ) : (
                scripts.map((script, index) => (
                  <ListItem key={index}>
                    <Button onClick={() => setScript(script)} width="100%">
                      {script.name}
                    </Button>
                  </ListItem>
                ))
              ))}
          </List>
        </Box>
        <Box width={isMobile ? "100%" : "80%"} p={4} overflowY="auto">
          {script ? (
            <ScriptBody script={script} setScript={setScript} />
          ) : (
            <p>Select a script from the left panel to view its contents.</p>
          )}
        </Box>
      </Stack>
    </Page>
  );
};

export default Scripts;
