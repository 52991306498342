import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  HStack,
  Text,
  Box,
  Spacer,
} from "@chakra-ui/react";
import { ReactSortable } from "react-sortablejs";
import { IconDeviceFloppy, IconDragDrop } from "@tabler/icons-react";

const Sorting = ({ isOpen, onClose, data, setData, saveSorting }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottomWidth={"1px"}>Sorting</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={0}>
          <VStack>
            <ReactSortable
              style={{ width: "100%" }}
              animation={100}
              list={data}
              setList={setData}
            >
              {data &&
                data.map((item, index) => (
                  <HStack
                    spacing={4}
                    key={typeof item === "object" ? item._id : item}
                    p={4}
                    borderBottomWidth={index === data.length - 1 ? "" : "1px"}
                  >
                    <Text>{item.name ? item.name : item}</Text>
                    <Spacer />
                    <IconDragDrop className="icon icon-tabler" />
                  </HStack>
                ))}
            </ReactSortable>
          </VStack>
        </ModalBody>
        <ModalFooter borderTopWidth={"1px"}>
          <Button
            colorScheme="blue"
            onClick={saveSorting}
            leftIcon={<IconDeviceFloppy className="icon icon-tabler" />}
          >
            Save Changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { Sorting };
