import { useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import api from "../../../services/api";

const CreateTotalsGroup = ({ isOpen, onClose, refresh }) => {
  const [groupData, setGroupData] = useState({
    name: "",
    identifier: "",
    calculations: [],
  });

  const toast = useToast();

  const setValue = (el) => {
    setGroupData({ ...groupData, [el.target.name]: el.target.value });
  };

  const createGroup = async () => {
    try {
      const { status } = await api("/api/settings/totals-groups", "post", {
        body: groupData,
      });

      if (status === "success") {
        refresh();

        onClose();

        setGroupData({ name: "", identifier: "", calculations: [] });

        toast({
          status: "success",
          title: `${groupData.name} (${groupData.identifier}) created successfully`,
          isClosable: true,
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "An error occured",
        status: "error",
        description: error.data?.data?.message || error.message,
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Group</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="groupName" mb="4">
            <FormLabel>Name</FormLabel>
            <Input name="name" value={groupData.name} onChange={setValue} />
          </FormControl>
          <FormControl id="identifier" mb="4">
            <FormLabel>Identifier</FormLabel>
            <Input
              name="identifier"
              value={groupData.identifier}
              onChange={setValue}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <HStack alignItems={"center"} justifyContent={"center"}>
            <Spacer />
            <Button onClick={createGroup} colorScheme="blue">
              Create Group
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { CreateTotalsGroup };
