// TODO: Fix this, not working

import { useEffect, useState } from "react";
import api from "../../services/api";
import { useToast } from "@chakra-ui/react";

export default (ordersStartDate) => {
  const toast = useToast();
  const [lock, setLock] = useState();

  useEffect(() => {
    const fetchLockStatus = async () => {
      if (ordersStartDate)
        try {
          const {
            data: { locked },
          } = await api(
            `/api/daily-totals/lock-status/${ordersStartDate}`,
            "get"
          );

          setLock(locked);
        } catch (error) {
          toast({
            title: "An error occured",
            description: error.data?.data?.message || error.message,
            status: "error",
            duration: 2000,
            isClosable: true,
          });

          if (
            error.data?.data?.message ==
            "No daily totals were found for that date"
          )
            setLock(false);
        }
    };

    fetchLockStatus();
  }, [ordersStartDate]);

  const toggleLockStatus = async () => {
    try {
      const response = await api(
        `/api/daily-totals/toggle-lock/${ordersStartDate}`,
        "put",
        { body: { locked: !lock } }
      );

      if (response.status == "success") {
        toast({
          title: `${response.data?.locked ? "Locked" : "Unlocked"}`,
          description: response.data?.message,
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "An error occured",
        description: error.data?.data?.message || error.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return { lock, setLock, toggleLockStatus };
};
