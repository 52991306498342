import { Route, Routes } from "react-router-dom";
import { Profile } from "../pages/Settings/Profile";
import { Limits } from "../pages/Settings/Limits/List";
import { Holidays } from "../pages/Settings/Holidays";
import { OrderFlagsList } from "../pages/Settings/OrderFlags/List";
import { TotalsGroups } from "../pages/Settings/TotalsGroups/List";
import { EditLimit } from "../pages/Settings/Limits/Edit";
import EditTotalsGroup from "../pages/Settings/TotalsGroups/Edit";
import { EditOrderFlag } from "../pages/Settings/OrderFlags/Edit";

const Settings = () => {
  return (
    <Routes>
      <Route path="/" element={<Profile />} />
      <Route path="/limits" element={<Limits />} />
      <Route path="/limits/:id" element={<EditLimit />} />
      <Route path="/totals-groups" element={<TotalsGroups />} />
      <Route path="/totals-groups/:id" element={<EditTotalsGroup />} />
      <Route path="/order-flags" element={<OrderFlagsList />} />
      <Route path="/order-flags/:id" element={<EditOrderFlag />} />
      <Route path="/holidays" element={<Holidays />} />
    </Routes>
  );
};

export { Settings };
