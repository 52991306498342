import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Text,
  Grid,
  GridItem,
  useToast,
  Card,
  CardHeader,
  Heading,
  CardBody,
  Spacer,
  Button,
  HStack,
} from "@chakra-ui/react";
import { IconCodeDots, IconFileSpreadsheet } from "@tabler/icons-react";
import AttachmentItem from "./AttachmentItem";

const icons = [
  {
    extension: ["xlsx", "csv"],
    icon: IconFileSpreadsheet,
  },
  {
    extension: ["prg"],
    icon: IconCodeDots,
  },
];

const EmailBody = ({ data }) => {
  const toast = useToast();
  const [emailSubject, setEmailSubject] = useState("");
  const [dateReceived, setDateReceived] = useState("");
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    if (data) {
      try {
        setEmailSubject(data.subject);
        setDateReceived(DateTime.fromISO(data.time).toISODate());

        const attachmentsList = data.attachments.map((e) => {
          const parts = e.fileName.split(".");
          const fileType = parts[parts.length - 1].toLowerCase();

          const { icon } = icons.find((icon) =>
            icon.extension.includes(fileType)
          );

          return {
            ...e,
            icon,
          };
        });

        setAttachments(attachmentsList);
      } catch (error) {
        toast({
          title: "An error occured",
          status: "error",
          duration: 2000,
          isClosable: true,
          description: error.data?.data?.message || error.message,
        });
      }
    } else {
      setEmailSubject("");
      setDateReceived("");
      setAttachments([]);
    }
  }, [data]);

  const downloadAttachment = (attachment) => {
    return new Promise((resolve, reject) => {
      try {
        const content = new Blob([attachment.content], {
          type: attachment.contentType,
        });
        const url = URL.createObjectURL(content);
        const link = document.createElement("a");

        link.href = url;
        link.download = attachment.fileName;
        document.body.appendChild(link);

        link.click();

        setTimeout(() => {
          document.body.removeChild(link);

          window.URL.revokeObjectURL(url);

          resolve(true);
        }, 100);
      } catch (err) {
        reject(err);
      }
    });
  };

  return data ? (
    <Card>
      <CardHeader borderBottomWidth={"1px"}>
        <HStack>
          <Heading size="md">Details</Heading>
          <Spacer />
          <Button>View Logs</Button>
        </HStack>
      </CardHeader>
      <CardBody>
        <Grid templateColumns="repeat(2, 1fr)" gap={6} mb={3}>
          <GridItem>
            <FormControl id="emailSubject">
              <FormLabel>Subject</FormLabel>
              <Input type="text" value={emailSubject} isReadOnly />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="dateReceived">
              <FormLabel>Date Received</FormLabel>
              <Input type="text" value={dateReceived} isReadOnly />
            </FormControl>
          </GridItem>
        </Grid>
        <VStack spacing={2} align="stretch">
          {attachments.map((attachment) => (
            <AttachmentItem
              key={attachment.fileName}
              attachment={attachment}
              downloadAttachment={downloadAttachment}
            />
          ))}
        </VStack>
      </CardBody>
    </Card>
  ) : (
    <Box p={4}>
      <Text>No email selected</Text>
    </Box>
  );
};

export default EmailBody;
