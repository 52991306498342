import { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { IconPlus } from "@tabler/icons-react";

const CreateCSVHeaders = ({
  isOpen,
  onClose,
  exporter,
  csvHeaders,
  onSubmit,
}) => {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const toast = useToast();
  const [headers, setHeaders] = useState([{ name: "", defaultValue: "" }]);
  const [csv, setCsv] = useState("");

  const addHeader = () => {
    setHeaders([...headers, { name: "", defaultValue: "" }]);
  };

  const handleCsvChange = (e) => {
    const csvHeaders = e.target.value.split(",");
    setHeaders(
      csvHeaders.map((header) => ({ name: header, defaultValue: "" }))
    );
  };

  useEffect(() => {
    if (csvHeaders) setHeaders(csvHeaders);
    else setHeaders([{ name: "", defaultValue: "" }]);
  }, [csvHeaders]);

  useEffect(() => {
    setCsv(headers.map((header) => header.name).join(","));
  }, [headers]);

  const handleCreate = async () => {
    try {
      await onSubmit(headers);

      onClose();
    } catch (error) {
      toast({
        title: "An error occured",
        description: error.data?.data?.message || error.message,
        status: "error",
        isClosable: true,
        duration: 5000,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottomWidth={"1px"}>
          Create CSV Generator
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {headers.map((header, index) => (
            <InputGroup mb={2} key={index}>
              <InputLeftAddon>
                {header.name} ({chars.at(index)})
              </InputLeftAddon>
              <Input
                borderRadius={0}
                borderRight={0}
                value={header.name}
                placeholder="Header Name"
                onChange={(e) => {
                  const newHeaders = [...headers];
                  newHeaders[index].name = e.target.value;
                  setHeaders(newHeaders);
                }}
              />
              <Input
                value={header.defaultValue}
                placeholder="Default Value (optional)"
                onChange={(e) => {
                  const newHeaders = [...headers];
                  newHeaders[index].defaultValue = e.target.value;
                  setHeaders(newHeaders);
                }}
              />
            </InputGroup>
          ))}
          <Button
            colorScheme="blue"
            onClick={addHeader}
            w="100%"
            leftIcon={<IconPlus />}
            mb={2}
          >
            Add Header
          </Button>
          <FormControl mb={2}>
            <FormLabel>CSV</FormLabel>
            <Textarea rows={3} value={csv} onChange={handleCsvChange} />
          </FormControl>
        </ModalBody>
        <ModalFooter borderTopWidth={"1px"}>
          <Button onClick={onClose}>Cancel</Button>
          <Button colorScheme="blue" onClick={handleCreate}>
            {`${exporter?.csvGenerator ? "Update" : "Create"} CSV Headers`}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateCSVHeaders;
