import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  Spinner,
} from "@chakra-ui/react";
import { useState } from "react";
import api from "../../../services/api";

export const RequestError = ({ isOpen, onClose, event }) => {
  const [reprocessResult, setReprocessResult] = useState(null);
  const [isReprocessing, setIsReprocessing] = useState(false);

  const handleReprocess = async () => {
    setIsReprocessing(true);
    setReprocessResult(null);

    try {
      const response = await api(
        `/api/event-log/re-process-request/${event._id}`,
        "GET"
      );

      setReprocessResult(response);
    } catch (error) {
      setReprocessResult({ status: "error", data: { message: error.message } });
    } finally {
      setIsReprocessing(false);
    }
  };

  return (
    <Modal size="5xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottomWidth={"1px"}>
          <Flex alignItems="center" justifyContent="space-between">
            Error Event
            <ModalCloseButton onClick={onClose} />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Grid templateColumns={["1fr", "repeat(3, 1fr)"]} gap={2} mb={4}>
            <GridItem>
              <FormControl>
                <FormLabel>Status</FormLabel>
                <Input readOnly value={event.status} size="sm" />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>Endpoint</FormLabel>
                <Input readOnly value={event.endpoint} size="sm" />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>Method</FormLabel>
                <Input readOnly value={event.method} size="sm" />
              </FormControl>
            </GridItem>
          </Grid>
          <Box>
            <FormControl mb={2}>
              <FormLabel>Request Body</FormLabel>
              <Textarea
                readOnly
                value={JSON.stringify(event.requestData.body, null, 2)}
                height="100px"
                size="sm"
              />
            </FormControl>
            <FormControl mb={2}>
              <FormLabel>Request Params</FormLabel>
              <Textarea
                readOnly
                value={JSON.stringify(event.requestData.params, null, 2)}
                height="60px"
                size="sm"
              />
            </FormControl>
            <FormControl mb={2}>
              <FormLabel>Request Query</FormLabel>
              <Textarea
                readOnly
                value={JSON.stringify(event.requestData.query, null, 2)}
                height="60px"
                size="sm"
              />
            </FormControl>
          </Box>
          {event.error && (
            <Box mt={2}>
              <FormControl mb={2}>
                <FormLabel>Error Message</FormLabel>
                <Input readOnly value={event.error} size="sm" />
              </FormControl>
              {event.errorStack && (
                <FormControl mb={2}>
                  <FormLabel>Error Stack</FormLabel>
                  <Textarea
                    readOnly
                    value={event.errorStack}
                    height="80px"
                    size="sm"
                  />
                </FormControl>
              )}
              {event.errorDetails && (
                <FormControl mb={2}>
                  <FormLabel>Error Details</FormLabel>
                  <Textarea
                    readOnly
                    value={event.errorDetails}
                    height="80px"
                    size="sm"
                  />
                </FormControl>
              )}
            </Box>
          )}
          {isReprocessing && (
            <Box mt={2} textAlign="center">
              <Spinner />
              <Box>Re-processing request...</Box>
            </Box>
          )}
          {reprocessResult && (
            <Box mt={2}>
              <FormControl mb={2}>
                <FormLabel>Re-process Result</FormLabel>
                <Textarea
                  readOnly
                  value={JSON.stringify(reprocessResult, null, 2)}
                  height="120px"
                  size="sm"
                />
              </FormControl>
            </Box>
          )}
        </ModalBody>
        <ModalFooter borderTopWidth={"1px"}>
          <Button
            onClick={handleReprocess}
            isLoading={isReprocessing}
            size="sm"
          >
            Re-process Request
          </Button>
          <Button colorScheme="blue" onClick={onClose} ml={2} size="sm">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
