import { Route, Routes } from "react-router-dom";
import { Overview } from "../pages/Administration/Overview";
import { UsersList } from "../pages/Administration/Users/List";
import { EditUser } from "../pages/Administration/Users/Edit";
import { GroupsList } from "../pages/Administration/Groups/List";
import { EditGroup } from "../pages/Administration/Groups/Edit";
import { ListAPITokens } from "../pages/Administration/APITokens/List";
import { EditAPIToken } from "../pages/Administration/APITokens/Edit";
import { RegenerateTotals } from "../pages/Administration/RegenerateTotals";
import { DeleteOrders } from "../pages/Administration/DeleteOrder";
import { ExportData } from "../pages/Administration/ExportData";
import { Integrations } from "../pages/Administration/Integrations";

const Administration = () => {
  return (
    <Routes>
      <Route path="/overview" element={<Overview />} />
      <Route path="/users" element={<UsersList />} />
      <Route path="/users/:id" element={<EditUser />} />
      <Route path="/groups" element={<GroupsList />} />
      <Route path="/groups/:id" element={<EditGroup />} />
      <Route path="/integrations" element={<Integrations />} />
      <Route path="/api-tokens" element={<ListAPITokens />} />
      <Route path="/api-tokens/:id" element={<EditAPIToken />} />
      <Route path="/regenerate-totals" element={<RegenerateTotals />} />
      <Route path="/delete-order" element={<DeleteOrders />} />
      <Route path="/export-data" element={<ExportData />} />
    </Routes>
  );
};

export { Administration };
