import { Page } from "../../components/Shared/Page";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useAPI } from "../../hooks/useAPI";
import {
  Button,
  HStack,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  VStack,
  Box,
  Text,
  Collapse,
  IconButton,
  useDisclosure,
  Progress,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import api from "../../services/api";
import { SaveAttributeModal } from "../../components/Allmoxy/Modals/SaveAttribute";

const operatorSymbols = {
  eq: "=",
  ne: "≠",
  gt: ">",
  lt: "<",
};

const AllmoxyProducts = () => {
  useDocumentTitle("Products | Allmoxy");

  const [productID, setProductID] = useState("");
  const [selectedAttribute, setSelectedAttribute] = useState({});
  const { isOpen: isSumsOpen, onToggle: onSumsToggle } = useDisclosure();
  const { isOpen: isValidationsOpen, onToggle: onValidationsToggle } =
    useDisclosure();
  const { isOpen: isAttributesOpen, onToggle: onAttributesToggle } =
    useDisclosure();
  const {
    isOpen: isSaveAttributeOpen,
    onClose: onSaveAttributeClose,
    onOpen: onSaveAttributeOpen,
  } = useDisclosure();

  const { data, isLoading: isLoadingProducts } = useAPI(
    "/api/allmoxy/products/"
  );

  const { data: product, isLoading: isLoadingProduct } = useAPI(
    `/api/allmoxy/products/${productID}`,
    "GET",
    "",
    productID !== ""
  );

  const { data: attributes, isLoading: isLoadingAttributes } = useAPI(
    `/api/allmoxy/products/attributes/${productID}`,
    "GET",
    "",
    productID !== ""
  );

  const {
    data: savedAttributes,
    isLoading: isLoadingSavedAttributes,
    refresh: refreshSavedAttributes,
  } = useAPI(
    `/api/allmoxy/products/attributes/saved/${productID}`,
    "GET",
    "",
    productID !== ""
  );

  const handleSavedAttributeClick = (attr) => {
    const existingAttribute = savedAttributes.find(
      (att) => att.attributeId === attr.attribute_id
    );

    if (existingAttribute) {
      setSelectedAttribute({
        ...attr,
        _id: existingAttribute._id,
        displayName: existingAttribute.displayName,
        displayOn: existingAttribute.displayOn,
      });
    } else {
      setSelectedAttribute(attr);
    }

    onSaveAttributeOpen();
  };

  return (
    <Page
      title="Products"
      progress={{
        show: true,
        isLoading:
          productID !== "" &&
          (isLoadingProduct || isLoadingAttributes || isLoadingSavedAttributes),
      }}
    >
      <SaveAttributeModal
        onClose={onSaveAttributeClose}
        isOpen={isSaveAttributeOpen}
        attribute={selectedAttribute}
        refresh={refreshSavedAttributes}
      />
      <VStack spacing={4} align="stretch">
        <HStack direction={{ base: "column", sm: "row" }}>
          <Select
            placeholder="Select option"
            onChange={(e) => setProductID(e.target.value)}
          >
            {data
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
          </Select>
        </HStack>

        {product && product.name && (
          <Box borderWidth="1px" borderRadius="lg" p={4}>
            <Text fontWeight="bold" fontSize="xl">
              Product Information
            </Text>
            <Text>Name: {product.name}</Text>
            <Text>Product ID: {product.product_id}</Text>
            <Text>Line ID: {product.line_id}</Text>
            <Text>Uses Groups: {product.uses_groups}</Text>
            <Text>Price Precision: {product.price_precision}</Text>
            <Text>Minimum Price: {product.minimum_price || "N/A"}</Text>
            <Text>Default Quantity: {product.default_qty || "N/A"}</Text>
            <Text>Quantity Label: {product.qty_label}</Text>
            <Text>Status: {product.status}</Text>
            <Text>Timestamp: {product.timestamp}</Text>
            <Text>Created By: {product.createdby}</Text>
            <Text>Updated By: {product.updatedby}</Text>
          </Box>
        )}

        {product?.sums && product.sums.length > 0 && (
          <Box borderWidth="1px" borderRadius="lg" p={4}>
            <HStack
              justify="space-between"
              onClick={onSumsToggle}
              cursor="pointer"
            >
              <Text fontWeight="bold" fontSize="lg">
                Sums
              </Text>
              <IconButton
                icon={isSumsOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                variant="outline"
                size="sm"
              />
            </HStack>
            <Collapse in={isSumsOpen} animateOpacity>
              <Box mt={4}>
                {product.sums.map((sum, index) => (
                  <Box
                    key={index}
                    borderBottom="1px"
                    borderColor="gray.200"
                    pb={2}
                    mb={2}
                  >
                    <Text fontWeight="bold">{sum.name}</Text>
                    <Text>Order: {sum.sorder}</Text>
                    <Text>Parts: {sum.parts.join(", ")}</Text>
                    <Text>Groups: {sum.groups.join(", ")}</Text>
                    <Text>Lists: {sum.lists.join(", ")}</Text>
                    <Text>Formula: {sum.formula}</Text>
                  </Box>
                ))}
              </Box>
            </Collapse>
          </Box>
        )}

        {product?.validations && product.validations.length > 0 && (
          <Box borderWidth="1px" borderRadius="lg" p={4}>
            <HStack
              justify="space-between"
              onClick={onValidationsToggle}
              cursor="pointer"
            >
              <Text fontWeight="bold" fontSize="lg">
                Validations
              </Text>
              <IconButton
                icon={
                  isValidationsOpen ? <ChevronUpIcon /> : <ChevronDownIcon />
                }
                variant="outline"
                size="sm"
              />
            </HStack>
            <Collapse in={isValidationsOpen} animateOpacity>
              <Box mt={4}>
                {product.validations.map((validation, index) => (
                  <Box
                    key={index}
                    borderBottom="1px"
                    borderColor="gray.200"
                    pb={2}
                    mb={2}
                  >
                    <Text fontWeight="bold">{validation.name}</Text>
                    <Text>Is Active: {validation.is_active}</Text>
                    {Object.entries(validation.groups).map(
                      ([groupKey, groupValue]) => (
                        <Box key={groupKey} pl={4}>
                          {Object.entries(groupValue).map(([key, value]) => (
                            <Text key={key}>
                              {value.attribute}{" "}
                              {operatorSymbols[value.operator] ||
                                value.operator}{" "}
                              {value.value}
                            </Text>
                          ))}
                        </Box>
                      )
                    )}
                    <Text>Actions:</Text>
                    {validation.actions.map((action, idx) => (
                      <Text key={idx} pl={4}>
                        Action Type: {action.action_type}, Value: {action.value}
                      </Text>
                    ))}
                  </Box>
                ))}
              </Box>
            </Collapse>
          </Box>
        )}

        {attributes?.length > 0 && (
          <Box borderWidth="1px" borderRadius="lg" p={4}>
            <HStack
              justify="space-between"
              onClick={onAttributesToggle}
              cursor="pointer"
            >
              <Text fontWeight="bold" fontSize="lg">
                Attributes
              </Text>
              <IconButton
                icon={
                  isAttributesOpen ? <ChevronUpIcon /> : <ChevronDownIcon />
                }
                variant="outline"
                size="sm"
              />
            </HStack>
            <Collapse in={isAttributesOpen} animateOpacity>
              <Box mt={4}>
                <Table size="sm" colorScheme="gray">
                  <Thead>
                    <Tr>
                      <Th>Attribute Name</Th>
                      <Th>Display Name</Th>
                      <Th>Type</Th>
                      <Th>Attribute ID</Th>
                      <Th>Data</Th>
                      <Th />
                    </Tr>
                  </Thead>
                  <Tbody>
                    {attributes.map((attr, index) => (
                      <Tr key={index}>
                        <Td>{attr.attribute_name}</Td>
                        <Td>{attr.product_atribute_display_name}</Td>
                        <Td>{attr.product_atribute_type}</Td>
                        <Td>{attr.attribute_id}</Td>
                        <Td>
                          {attr.data
                            ? Object.entries(attr.data).map(([key, value]) => (
                                <div key={key}>
                                  {key}: {value}
                                </div>
                              ))
                            : "N/A"}
                        </Td>
                        <Td>
                          {savedAttributes.find(
                            (att) => att.attributeId === attr.attribute_id
                          ) ? (
                            <Button
                              size={"xs"}
                              onClick={() => handleSavedAttributeClick(attr)}
                            >
                              Edit Attribute
                            </Button>
                          ) : (
                            <Button
                              size={"xs"}
                              onClick={() => handleSavedAttributeClick(attr)}
                            >
                              Save to Order
                            </Button>
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </Collapse>
          </Box>
        )}
      </VStack>
    </Page>
  );
};

export { AllmoxyProducts };
