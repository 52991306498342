import { useState } from "react";
import api from "../../../services/api";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { TagInput } from "../TagInput";

const CreateOrderFlagsGroup = ({ isOpen, onClose, refresh }) => {
  const [groupData, setGroupData] = useState({
    name: "",
    highlightColor: "",
    tags: [],
    products: [],
  });

  const toast = useToast();

  const setValue = (el) =>
    setGroupData({ ...groupData, [el.target.name]: el.target.value });

  const createGroup = async () => {
    try {
      const { status } = await api("/api/settings/order-flags", "post", {
        body: groupData,
      });

      if (status === "success") {
        refresh();

        onClose();

        setGroupData({
          name: "",
          highlightColor: "",
          tags: [],
          products: [],
        });

        toast({
          status: "success",
          title: `${groupData.name} created successfully`,
          isClosable: true,
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "An error occured",
        description: error.data?.data?.message || error.message,
        status: "error",
        isClosable: true,
        duration: 5000,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Group</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid templateColumns="repeat(5, 1fr)" gap={4} mb={4}>
            <GridItem colSpan={3}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input name="name" value={groupData.name} onChange={setValue} />
              </FormControl>
            </GridItem>
            <GridItem colSpan={2}>
              <FormControl>
                <FormLabel>Highlight Color</FormLabel>
                <Input
                  name="highlightColor"
                  type="color"
                  value={groupData.highlightColor}
                  onChange={setValue}
                />
              </FormControl>
            </GridItem>
          </Grid>
          <FormControl mb={4}>
            <TagInput
              name="Products"
              initialTags={[]}
              setter={(products) => setGroupData({ ...groupData, products })}
            />
          </FormControl>
          <FormControl mb={4}>
            <TagInput
              name="Tags"
              initialTags={[]}
              setter={(tags) => setGroupData({ ...groupData, tags })}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button onClick={createGroup} colorScheme="blue">
            Create Group
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { CreateOrderFlagsGroup };
