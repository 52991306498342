import { Page } from "../../../components/Shared/Page";
import { useAPI } from "../../../hooks/useAPI";
import { useParams } from "react-router-dom";
import { PermissionsAccordion } from "../../../components/Administration/PermissionsAccordion";
import api from "../../../services/api";
import {
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { IconDeviceFloppy } from "@tabler/icons-react";

const EditUser = () => {
  const { id } = useParams();

  const {
    data: user,
    setData: setUser,
    isLoading,
    refresh,
  } = useAPI(`/api/users/${id}`);

  const { data: groups } = useAPI(`/api/administration/groups`);

  const toast = useToast();

  const setValue = (e) => setUser({ ...user, [e.target.name]: e.target.value });

  const setGroups = (group) => {
    if (user.groups.includes(group)) {
      setUser({ ...user, groups: user.groups.filter((g) => g !== group) });
    } else {
      setUser({ ...user, groups: [...user.groups, group] });
    }
  };

  const setPermissions = (permissions) => setUser({ ...user, permissions });

  const saveUser = async () => {
    try {
      const { status } = await api(`/api/users/${id}`, "put", { body: user });

      if (status === "success") {
        refresh();

        toast({
          status: "success",
          title: `${user.firstName} ${user.lastName} updated successfully`,
          isClosable: true,
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "An error occured",
        status: "error",
        description: error.data?.data?.message || error.message,
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Page
      title="Users"
      buttons={[
        {
          text: "Save User",
          onClick: saveUser,
          colorScheme: "blue",
          icon: IconDeviceFloppy,
        },
      ]}
      backButton={true}
    >
      <Stack mb={2} direction={{ base: "column", lg: "row" }}>
        <FormControl>
          <FormLabel>First Name</FormLabel>
          <Input
            value={user?.firstName || ""}
            onChange={setValue}
            name="firstName"
          />
        </FormControl>
        <FormControl>
          <FormLabel>Last Name</FormLabel>
          <Input
            value={user?.lastName || ""}
            onChange={setValue}
            name="lastName"
          />
        </FormControl>
        <FormControl>
          <FormLabel>Email Address</FormLabel>
          <Input value={user?.email || ""} onChange={setValue} name="email" />
        </FormControl>
        <FormControl>
          <FormLabel>Password</FormLabel>
          <Input
            type="password"
            placeholder="Leave blank to avoid changing"
            onChange={setValue}
            name="password"
          />
        </FormControl>
      </Stack>
      <FormLabel>Groups</FormLabel>
      <Stack spacing={[1, 5]} mb={2} direction={{ base: "column", md: "row" }}>
        {groups?.map((group) => (
          <Checkbox
            isChecked={user?.groups?.includes(group._id)}
            onChange={() => setGroups(group._id)}
            id={group._id}
            key={group._id}
          >
            {group.name}
          </Checkbox>
        ))}
      </Stack>
      <FormLabel>Permissions</FormLabel>
      <PermissionsAccordion
        permissions={user?.permissions}
        setPermissions={setPermissions}
        isLoading={isLoading}
      />
    </Page>
  );
};

export { EditUser };
