import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useEffect, useState } from "react";
import api from "../../../services/api";

const SaveAttributeModal = ({
  isOpen,
  onClose,
  attribute,
  refresh,
}: {
  isOpen: boolean,
  onClose: () => void,
  attribute: any,
}) => {
  const [attr, setAttr] = useState({
    _id: null,
    attributeName: "",
    attributeId: "",
    productId: "",
    displayName: "",
    displayOn: [],
  });

  const displayOnOptions = [
    { label: "Daily Starts", value: "dailyStarts" },
    { label: "Scheduling", value: "scheduling" },
  ];

  const toast = useToast();

  useEffect(() => {
    if (attribute) {
      setAttr({
        attributeId: attribute.attribute_id,
        attributeName: attribute.attribute_name,
        productId: attribute.product_id,
        displayName:
          attribute.displayName || attribute.product_atribute_display_name,
        displayOn: attribute.displayOn || [],
        _id: attribute._id || null,
      });
    }
  }, [attribute]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAttr((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectChange = (selectedOptions) => {
    const values = selectedOptions.map((option) => option.value);

    setAttr((prev) => ({
      ...prev,
      displayOn: values,
    }));
  };

  const onSave = async () => {
    try {
      const method = attr._id ? "PUT" : "POST";
      const url = attr._id
        ? `/api/allmoxy/products/attributes/saved/${attr._id}`
        : `/api/allmoxy/products/attributes/saved`;

      const attrToSave = { ...attr };

      if (attrToSave._id === null) {
        delete attrToSave._id;
      }

      const response = await api(url, method, {
        body: attrToSave,
      });

      if (response.status === "success") {
        refresh();

        toast({
          title: "Attribute saved successfully",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "An error occurred",
        status: "error",
        description: error.data?.data?.message || error.message,
        duration: 2000,
        isClosable: true,
      });
    } finally {
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Save Attribute to Order</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Display Name</FormLabel>
            <Input
              value={attr.displayName}
              name="displayName"
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Display On</FormLabel>
            <Select
              onChange={handleSelectChange}
              options={displayOnOptions}
              isMulti
              value={displayOnOptions.filter((option) =>
                attr.displayOn.includes(option.value)
              )}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onSave}>
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { SaveAttributeModal };
