import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Center,
  Progress,
  Input,
  IconButton,
  Flex,
  Text,
} from "@chakra-ui/react";
import { IconRefresh } from "@tabler/icons-react";

export const ChangeOrderStartDate = ({
  order,
  setOrder,
  isLoadingOrder,
  shipSelection,
  setIsLoading,
}) => {
  const handleDateChange = (index, newStartDate) => {
    const updatedOrder = {
      ...order,
      dateEntries: order.dateEntries.map((dateEntry, idx) => {
        if (idx === index) {
          return { ...dateEntry, startDate: newStartDate };
        }
        return dateEntry;
      }),
    };

    setOrder(updatedOrder);
  };

  const handleShipDateChange = (shipDate) =>
    setOrder((oldOrder) => ({ ...oldOrder, shipDate }));

  const syncStartDate = () => {
    const syncDate = order.dateEntries[0].startDate;

    const updatedOrder = {
      ...order,
      dateEntries: order.dateEntries.map((dateEntry) => ({
        ...dateEntry,
        startDate: syncDate,
      })),
    };

    setOrder(updatedOrder);
  };

  return (
    <Table
      variant="striped"
      colorScheme="gray"
      size="sm"
      mt={!isLoadingOrder && 1}
    >
      <Thead>
        {isLoadingOrder && (
          <Tr p={0}>
            <Th colSpan={3} p={0}>
              <Progress isIndeterminate size="xs" />
            </Th>
          </Tr>
        )}
        <Tr>
          <Th>Type</Th>
          <Th>Quantity</Th>
          <Th w="124px">
            <Flex
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Text>Start Date</Text>
              <IconButton
                size="xs"
                variant="ghost"
                icon={<IconRefresh width={16} />}
                aria-label="Sync Start Dates"
                onClick={syncStartDate}
              />
            </Flex>
          </Th>
          {shipSelection !== "dontAdjust" && <Th w="124px">Ship Date</Th>}
        </Tr>
      </Thead>
      <Tbody>
        {!isLoadingOrder ? (
          order?.dateEntries.map((dateEntry, index) => (
            <Tr key={index}>
              <Td>{dateEntry.type}</Td>
              <Td>{dateEntry.quantity}</Td>
              <Td>
                <Input
                  size="xs"
                  type="date"
                  value={dateEntry.startDate}
                  onChange={(e) => handleDateChange(index, e.target.value)}
                />
              </Td>
              {shipSelection !== "dontAdjust" && (
                <Td>
                  <Input
                    type="date"
                    value={order.shipDate.split("T")[0]}
                    onChange={(e) => handleShipDateChange(e.target.value)}
                    size="xs"
                  />
                </Td>
              )}
            </Tr>
          ))
        ) : (
          <Tr>
            <Td colSpan={3}>
              <Center>Loading...</Center>
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
};
