import swal from "sweetalert";
import api from "../../../services/api";
import { Page } from "../../../components/Shared/Page";
import { IconPlus, IconRefresh, IconTrash } from "@tabler/icons-react";
import { SettingsList } from "../../../components/Settings/SettingsList";
import { useAPI } from "../../../hooks/useAPI";
import { CreateAPIToken } from "../../../components/Administration/Modals/CreateAPIToken";
import { Grid, useDisclosure, useToast } from "@chakra-ui/react";

const ListAPITokens = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    data: tokens,
    isLoading,
    refresh,
  } = useAPI("/api/administration/api-tokens");

  const toast = useToast();

  const deleteToken = async ({ _id }) => {
    try {
      const confirmDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this group",
        icon: "error",
        buttons: true,
      });

      if (!confirmDelete) return swal({ title: "Cancelled", icon: "success" });

      const { status } = await api(
        `/api/administration/api-tokens/${_id}`,
        "delete"
      );

      if (status === "success") {
        refresh();

        toast({
          status: "success",
          title: `API Token deleted successfully`,
          isClosable: true,
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "An error occured",
        status: "error",
        description: error.data?.data?.message || error.message,
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Page
      title="API Tokens"
      buttons={[
        {
          text: "Create Token",
          icon: IconPlus,
          onClick: onOpen,
        },
        {
          text: "Refresh",
          icon: IconRefresh,
          onClick: refresh,
          isLoading,
          colorScheme: "blue",
        },
      ]}
    >
      <CreateAPIToken isOpen={isOpen} onClose={onClose} refresh={refresh} />
      <Grid
        templateColumns={{
          sm: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(4, 1fr)",
        }}
        gap={4}
      >
        {tokens && tokens.length > 0 && (
          <SettingsList
            data={tokens}
            actions={[
              { Icon: IconTrash, onClick: deleteToken, colorScheme: "red" },
            ]}
            titleKey="name"
            footer={[
              {
                position: "left",
                text: "Edit Token",
                url: ({ _id }) => `/administration/api-tokens/${_id}`,
              },
            ]}
          />
        )}
      </Grid>
    </Page>
  );
};

export { ListAPITokens };
