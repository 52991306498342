import { Page } from "../../components/Shared/Page";
import { useState } from "react";
import Overview from "../../components/Utilities/Exporters/Overview";
import CSVGenerator from "../../components/Utilities/Exporters/CSVGenerator";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";

const Exporters = () => {
  useDocumentTitle("Exporters");

  const [tab, setTab] = useState("overview");

  return (
    <Page title="Exporters" cardBodyPadding={0}>
      <Tabs>
        <TabList>
          <Tab>Overview</Tab>
          <Tab>CSV Generator</Tab>
          <Tab>Exporter Tester</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Overview />
          </TabPanel>
          <TabPanel p={0}>
            <CSVGenerator />
          </TabPanel>
          <TabPanel>
            Coming soon! Will generate a random email address for a one-time
            test of an exporter. Will ensure that headers line up with the CSV
            provided.
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Page>
  );
};

export default Exporters;
