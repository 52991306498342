import {
  Box,
  Button,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";

const ResponsiveTable = ({ orders, onOpen, setOrder }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const loadOrderDetails = (order) => {
    setOrder(order);

    onOpen();
  };

  return isMobile ? (
    <Box p={4}>
      {orders.map((order) => (
        <Box key={order._id} borderWidth="1px" borderRadius="md" p={4} mb={4}>
          <VStack align="start" spacing={4}>
            <Text>
              <strong>Order #:</strong> {order._id}
            </Text>
            <Text>
              <strong>Job Name:</strong> {order.name}
            </Text>
            <Text>
              <strong>Company:</strong> {order.companyName}
            </Text>
            <Text>
              <strong>Total Pieces:</strong>{" "}
              {order.dateEntries.reduce((total, e) => total + e.quantity, 0)}
            </Text>
            <Text>
              <strong>Pieces Started:</strong>{" "}
              {order.dateEntries.reduce(
                (total, e) => (e.hasStarted ? total + e.quantity : total),
                0
              )}
            </Text>
            <Button w={"100%"} onClick={() => loadOrderDetails(order)}>
              View Details
            </Button>
          </VStack>
        </Box>
      ))}
    </Box>
  ) : (
    <TableContainer>
      <Table variant="striped" colorScheme="gray">
        <Thead>
          <Tr>
            <Th>Order #</Th>
            <Th>Job Name</Th>
            <Th>Company</Th>
            <Th>Total Pieces</Th>
            <Th>Pieces Started</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {orders.map((order) => (
            <Tr key={order._id}>
              <Td>{order._id}</Td>
              <Td>{order.name}</Td>
              <Td>{order.companyName}</Td>
              <Td>
                {order.dateEntries.reduce((total, e) => total + e.quantity, 0)}
              </Td>
              <Td>
                {order.dateEntries.reduce(
                  (total, e) => (e.hasStarted ? total + e.quantity : total),
                  0
                )}
              </Td>
              <Td>
                <Button
                  colorScheme="blue"
                  onClick={() => loadOrderDetails(order)}
                  variant="link"
                >
                  View Details
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export { ResponsiveTable };
