import { IconCopy, IconEdit, IconMenu } from "@tabler/icons-react";
import {
  Box,
  HStack,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import UpdateExporter from "./UpdateExporter";

const ExporterList = ({
  exporters,
  selectedExporter,
  setSelectedExporter,
  refresh,
}) => {
  const copyTextToClipboard = (text) => {
    const clipboardItem = new ClipboardItem({
      "text/plain": new Blob([text], { type: "text/plain" }),
    });

    navigator.clipboard.write([clipboardItem]);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const editExporter = (exporter) => {
    setSelectedExporter(exporter);

    onOpen();
  };

  return exporters.map((exporter) => (
    <>
      <UpdateExporter
        selectedExporter={selectedExporter}
        setSelectedExporter={setSelectedExporter}
        onClose={onClose}
        isOpen={isOpen}
        refresh={refresh}
      />
      <Box
        key={exporter._id}
        cursor={"pointer"}
        borderRadius={8}
        padding={3}
        backgroundColor={"gray.100"}
        _hover={{ backgroundColor: "gray.200" }}
        // TODO: ignore click if it is NOT the link, stop trying to prevent everything else
        onClick={(e) =>
          e.target.tagName !== "svg" &&
          e.target.tagName !== "BUTTON" &&
          setSelectedExporter(exporter)
        }
      >
        <HStack>
          <Box>
            <Heading size={"sm"}>{exporter.exporterName}</Heading>
            <Text fontSize={"sm"} display={{ base: "none", xl: "block" }}>
              {exporter.emailAddress}
            </Text>
          </Box>
          <Spacer />
          <Menu>
            <MenuButton
              colorScheme="blue"
              as={IconButton}
              aria-label="Options"
              icon={<IconMenu />}
            />
            <MenuList>
              <MenuItem
                icon={<IconCopy />}
                onClick={() => copyTextToClipboard(exporter.emailAddress)}
              >
                Copy Email Address
              </MenuItem>
              <MenuItem
                icon={<IconEdit />}
                onClick={() => editExporter(exporter)}
              >
                Edit Exporter
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Box>
    </>
  ));
};

export { ExporterList };
