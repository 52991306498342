import { Page } from "../../components/Shared/Page";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import multiMonthPlugin from "@fullcalendar/multimonth";
import interactionPlugin from "@fullcalendar/interaction";
import { useEffect, useRef, useState } from "react";
import { useAPI } from "../../hooks/useAPI";
import { CreateHoliday } from "../../components/Settings/Modals/CreateHoliday";
import { DateTime } from "luxon";
import swal from "sweetalert";
import api from "../../services/api";
import {
  IconChevronLeft,
  IconChevronRight,
  IconPlus,
  IconRefresh,
} from "@tabler/icons-react";
import { IconCalendarEvent } from "@tabler/icons-react";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

const Holidays = () => {
  useDocumentTitle("Holidays");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [holidayData, setHolidayData] = useState({ title: "", date: "" });
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [calendar, setCalendar] = useState(null);
  const calendarRef = useRef(null);

  const toast = useToast();

  useEffect(() => {
    if (calendarRef.current) {
      calendarRef.current.getApi().on("datesSet", handleDatesSet);
    }

    return () => {
      if (calendarRef.current) {
        calendarRef.current.getApi().off("datesSet", handleDatesSet);
      }
    };
  }, []);

  useEffect(() => {
    setCalendar(calendarRef.current.getApi());
  }, [calendarRef]);

  const handleDatesSet = (info) => {
    const newDate = info.start;
    const newYear = newDate.getFullYear();
    setCurrentYear(newYear);
  };

  const {
    data: holidays,
    isLoading,
    refresh,
  } = useAPI("/api/settings/holidays");

  const setDate = (value) => {
    const date = new Date(value);

    setHolidayData({
      ...holidayData,
      date: DateTime.fromJSDate(date).toISODate(),
    });
  };

  const deleteHoliday = async (info) => {
    try {
      const confirmDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this holiday",
        icon: "error",
        buttons: true,
      });

      if (!confirmDelete) return swal({ title: "Cancelled", icon: "success" });

      const id = info.event._def.extendedProps._id;

      const response = await api(`/api/settings/holidays/${id}`, "delete");

      if (response.status == "success") {
        refresh();

        swal({
          title: "Deleted",
          text: "Holiday deleted successfully",
          icon: "success",
        });
      }
    } catch (error) {
      toast({
        title: "An error occured",
        status: "error",
        duration: 2000,
        isClosable: true,
        description: error.data?.data?.message || error.message,
      });
    }
  };

  return (
    <Page
      title={`Holidays - ${currentYear}`}
      buttons={[
        {
          text: "Today",
          icon: IconCalendarEvent,
          onClick: () => calendar.today(),
        },
        {
          text: "Create Holiday",
          icon: IconPlus,
          onClick: onOpen,
        },
        {
          text: "Previous Year",
          icon: IconChevronLeft,
          onClick: () => calendar.prev(),
        },
        {
          text: "Next Year",
          icon: IconChevronRight,
          onClick: () => calendar.next(),
        },
        {
          text: "Refresh",
          colorScheme: "blue",
          icon: IconRefresh,
          isLoading,
          onClick: refresh,
        },
      ]}
    >
      <CreateHoliday
        isOpen={isOpen}
        onClose={onClose}
        refresh={refresh}
        holidayData={holidayData}
        setHolidayData={setHolidayData}
      />
      <FullCalendar
        ref={calendarRef}
        plugins={[multiMonthPlugin, dayGridPlugin, interactionPlugin]}
        initialView="multiMonthYear"
        dateClick={(info) => {
          setDate(info.date);

          onOpen();
        }}
        eventClick={deleteHoliday}
        weekends={false}
        events={holidays}
        headerToolbar={{
          left: "",
          right: "",
        }}
      />
    </Page>
  );
};

export { Holidays };
